import { Route, Routes } from "react-router-dom"
import { OrgList } from "./Organizations"
import { OrgDetail } from "./OrgDetail"
import { EditOrg } from "./EditOrg"

export const Organizations = () => {
  return (
    <Routes>
      <Route path="/" element={<OrgList />} />
      <Route path="/:id" element={<OrgDetail />} />
      <Route path="/:id/edit" element={<EditOrg />} />
    </Routes>
  )
}