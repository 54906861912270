import numeral from "numeral";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { makeApiRequest } from "../../../utils/api";
import { MonthlyTotal, VendorReport } from "../../../types/Report";
import { useVendor } from "../../../providers/VendorContext";

import { Line } from "react-chartjs-2";
import { Button } from "../../../components/reusable/Button";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { saveAs } from "file-saver";


import { formatCentsToReadableString, formatIntToReadableString } from "../../../utils/currency";

export const SalesByMonth = ({ report }: { report: VendorReport }) => {
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "bottom" as const,
        onClick: function (
          event: unknown,
          legendItem: unknown,
          legend: unknown
        ) {
          console.log(`You clicked on ${legend}`);
          // Perform your action here...
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value: unknown) {
            return "$" + value;
          },
        },
      },
    },
  };

  const labels = report.monthly_totals.map((item: MonthlyTotal) => item.month);
  const { currentOrganization } = useVendor();

  const chartData = {
    labels,
    datasets: [
      {
        fill: {
          target: "origin",
          above: "rgba(76, 201, 240, 0.45)", // And blue below the origin
        },
        label: "Revenue",
        data: report.monthly_totals
          ? report.monthly_totals.map(
              (item: MonthlyTotal) => item.revenue / 100
            )
          : [],
        backgroundColor: "#486BFF",
      },
    ],
  };


  const downloadSalesByMonthReport = () => {
    makeApiRequest({
      path: `/vendor/organizations/${currentOrganization?.id}/reports.csv`,
      params: {
        organization_id: currentOrganization?.id
      },
    }).then((res) => {
      if (res.status === 200) {
        const CSV = res.data;
        const blob = new Blob([CSV], { type: "text/csv" });
        const filename = `sales-by-month-${currentOrganization?.name}.csv`;
        saveAs(blob, filename);
      }
    });
  }

  return (
    <>
      <div className="flex flex-col sm:flex-row gap-3 mt-5">
        <VendorCard className="sm:w-1/2 md:w-3/4">
          <div className="flex justify-between items-center">
            <span className="flex gap-1 items-center">
              <h3 className="text-lg font-bold">Sales by Month</h3>
              <span className="text-xs text-gray-400">{`(Last 12 Months)`}</span>
            </span>
            <span className="text-2xl font-bold">
              $
              {numeral(report.total_twelve_month_revenue / 100).format(
                "0,0.00"
              )}
            </span>
          </div>

          {/* Chart data */}
          <div className="mt-3">
            <Line data={chartData} options={chartOptions} />
          </div>
        </VendorCard>
        <div className="sm:w-1/2 lg:w-1/4 flex sm:flex-col gap-3">
          <VendorCard className="flex w-full flex-col items-center h-1/2 justify-center">
            <span className="text-3xl text-lightPurple font-bold">
              {formatIntToReadableString(report.total_twelve_month_orders)}
            </span>
            <span>Total Orders</span>
          </VendorCard>

          <VendorCard className="flex w-full flex-col items-center h-1/2 justify-center">
            <span className="text-3xl text-lightPurple font-bold">
              {formatIntToReadableString(
                report.total_twelve_month_tickets_sold
              )}
            </span>
            <span>Tickets Sold</span>
          </VendorCard>
        </div>
      </div>
      <VendorCard className="mt-3">
        <div className="flex">
          <Button onClick={downloadSalesByMonthReport} size="sm" className="mb-2 ml-auto" >
            <ArrowDownTrayIcon className="w-5 h-5" />
            Download CSV
          </Button>
        </div>
        <MonthlyRevenueTable monthlyTotals={report.monthly_totals} />
      </VendorCard>
    </>
  );
};


export const MonthlyRevenueTable = ({
  monthlyTotals,
}: {
  monthlyTotals: MonthlyTotal[];
}) => {
  return (
    <table className="min-w-full divide-y divide-gray-200 text-sm">
      <thead>
        <tr className="text-left">
          <th>Month</th>
          <th>Orders</th>
          <th>Tickets Sold</th>
          <th>Revenue</th>
          <th>Change</th>
        </tr>
      </thead>

      <tbody className="divide-y divide-gray-200">
        {monthlyTotals.map((item: MonthlyTotal) => (
          <tr className="h-11 text-gray-400" key={item.month}>
            <td className="py-1">{item.month}</td>
            <td className="py-1">{formatIntToReadableString(item.orders)}</td>
            <td className="py-1">
              {formatIntToReadableString(item.tickets_sold)}
            </td>
            <td className="py-1">
              {formatCentsToReadableString(item.revenue)}
            </td>
            <td className="py-1">
              <span
                className={
                  item.percentage_change > 0 ? "text-green-500" : "text-red-500"
                }
              >
                {`${item.percentage_change > 0 ? "+" : ""} ${
                  item.percentage_change
                }`}
                %
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};