import { Route, Routes } from "react-router-dom"
import { CustomerList } from "./CustomerList"
import { ViewCustomer } from "./ViewCustomer"

export const Customers = () => {
  return (
    <Routes>
      <Route path="/" element={<CustomerList />} />
      <Route path="/:customerId" element={<ViewCustomer />} />
    </Routes>
  )
}