import { useQuery } from "@tanstack/react-query";
import { useVendor } from "../../../providers/VendorContext";
import { makeApiRequest } from "../../../utils/api";
import { CategoryTotal, TaxReportData, TaxReportResponse } from "../../../types/Report";
import { formatCentsToReadableString } from "../../../utils/currency";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { Link } from "react-router-dom";

export const TaxReport = () => {
  const { currentOrganization } = useVendor();
  const { data } = useQuery({
    queryKey: ["taxReport", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/organizations/${currentOrganization?.id}/tax_report`,
      }),
    enabled: !!currentOrganization,
  });

  if (!data || !data.data) {
    return <div>Loading...</div>;
  }

  const taxReportResponse: TaxReportResponse = data.data;
  const taxReportData: TaxReportData[] =
    taxReportResponse?.tax_data_by_date ?? taxReportResponse?.tax_data ?? [];

  return (
    <VendorCard className="mt-3">
      <h2 className="text-lg font-bold pb-5">Tax Report</h2>
      <table className="min-w-full divide-y divide-gray-200 text-sm">
        <thead>
          <tr className="text-left">
            <th>Date</th>
            <th>Payment ID</th>
            <th>Order ID</th>
            <th>Taxable Amount</th>
            <th>Tax Rate</th>
            <th>Tax Collected</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {taxReportData.map((item) => (
            <tr className="h-11 text-gray-400" key={item.date}>
              <td className="py-1">{item.date}</td>
              <td className="py-1">
                <Link
                  className="text-lightPurple"
                  to={`/vendor/organizations/${currentOrganization?.id}/payments/${item.payment_id}`}
                >
                  #{item.payment_id}
                </Link>
              </td>
              <td className="py-1">
                <Link
                  className="text-lightPurple"
                  to={`/vendor/organizations/${currentOrganization?.id}/orders/${item.order_id}`}
                >
                  #{item.order_id}
                </Link>
              </td>
              <td className="py-1">
                {formatCentsToReadableString(item.taxable_amount)}
              </td>
              <td className="py-1">{item.rate}%</td>
              <td className="py-1">
                {formatCentsToReadableString(item.tax_collected)}
              </td>
            </tr>
          ))}
          <tr className="h-11 text-gray-400 font-bold">
            <td className="py-1">Total</td>
            <td className="py-1"></td>
            <td className="py-1"></td>
            <td className="py-1">
              {taxReportResponse
                ? formatCentsToReadableString(
                    taxReportResponse.total_taxable_amount
                  )
                : "N/A"}
            </td>
            <td className="py-1"></td>
            <td className="py-1">
              {taxReportResponse
                ? formatCentsToReadableString(
                    taxReportResponse.total_tax_collected
                  )
                : "N/A"}
            </td>
          </tr>
        </tbody>
      </table>
    </VendorCard>
  );
};


export const CategoryRevenueTable = ({
  categoryTotals,
}: {
  categoryTotals: CategoryTotal[];
}) => {
  return (
    <table className="min-w-full divide-y divide-gray-200 text-sm">
      <thead>
        <tr className="text-left">
          <th>Category</th>
          <th>Revenue</th>
          <th>Quantity Sold</th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {categoryTotals.map((item: CategoryTotal) => (
          <tr className="h-11 text-gray-400" key={item.product_category_id}>
            <td className="py-1">{item.product_category_name}</td>
            <td className="py-1">{formatCentsToReadableString(item.total_revenue_cents)}</td>
            <td className="py-1">{item.quantity_sold}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
