import { useState } from "react";
import { useAuth } from "../../providers/AuthContext";
import { useVendor } from "../../providers/VendorContext";
import { Modal } from "./Modal";
import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../utils/api";
import { Button } from "./Button";
import { User } from "../../types/User";
import { Field, FieldInputProps, Form, Formik, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
type OrgUserModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  handleSuccessfulAdd: () => void;
};

export const OrgUserModal = ({
  isOpen,
  onRequestClose,
  handleSuccessfulAdd,
}: OrgUserModalProps) => {
  const { currentOrganization, refetchOrganization } = useVendor();
  const handleSuccessfulAddAndRefetchOrganization = () => {
    handleSuccessfulAdd();
    refetchOrganization();
  }
  return (
    <Modal
      title={`Add User to ${currentOrganization?.name}`}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <>
        <AddUserModalContent onSuccessfulAdd={handleSuccessfulAddAndRefetchOrganization} />
      </>
    </Modal>
  );
};

export const AddUserModalContent = ({
  onSuccessfulAdd,
}: {
  onSuccessfulAdd: () => void;
}) => {
  const { user } = useAuth();
  const { currentOrganization } = useVendor();
  const [email, setEmail] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<{ email: string } | null>();
  const [level, setLevel] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { data, refetch, isFetching, isFetched } = useQuery({
    queryKey: ["users", searchQuery, currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/user_search`,
        params: {
          email: email,
          organization_id: currentOrganization?.id,
        },
      }),
    enabled: !!searchQuery && !!currentOrganization,
    retry: false,
  });

  const searchUsers = () => {
    setSearchQuery({ email });
  };
  const users = data?.data;
  const createOrgUser = ({ user_id }: { user_id: number }) => {
    if (!level) {
      setError("Please select a level.");
      return;
    }
    makeApiRequest({
      path: `/vendor/org_users`,
      method: "POST",
      params: {
        organization_id: currentOrganization?.id,
        org_user: {
          organization_id: currentOrganization?.id,
          user_id,
          level,
        },
      },
    })
      .then((res) => {
        if (res.status === 201) {
          onSuccessfulAdd();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="flex flex-col">
      <div className="flex gap-3 items-center">
        <input
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              searchUsers();
            }
          }}
          placeholder="Search by email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          type="text"
          className="rounded-lg border border-gray-300 p-2 w-1/2 focus:ring-purple focus:border-purple"
        />
        <Button variant="blue" onClick={searchUsers}>
          Search
        </Button>
        <span className="text-red-400 text-base ml-auto">{error}</span>
      </div>

      {users && users.length ? (
        <div className="flex flex-col mt-5 w-full">
          {users.map((user: User) => {
            return (
              <div key={user.id} className="flex justify-around items-center py-3 border-b text-gray-500">
                <span className="block w-1/2 text-sm font-bold">
                  {user.first_name} {user.last_name}
                  <br />
                  <span className="block text-xs font-normal">
                    {user.email}
                  </span>
                </span>
                <select
                  className="border-l-0 border-t-0 border-r-0 p-0 border-b border-b-gray-300 text-sm"
                  onChange={(e) => {
                    if (e.target.value !== "0") {
                      setLevel(e.target.value);
                    } else {
                      return;
                    }
                  }}
                >
                  <option value={"0"}>Select Level</option>
                  <option value="1">Scanner</option>
                  <option value="2">POS / Box Office</option>
                  <option value="10">Reader</option>
                  <option value="50">Org Admin</option>
                </select>

                <Button
                  size="sm"
                  variant={"success"}
                  onClick={() => createOrgUser({ user_id: user.id })}
                >
                  Add User
                </Button>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="mt-10">
          {isFetched ? (
            <>
              <span className="text-gray-500 block my-3">
                No users found with that email. Use the form below to create a
                new user for your organization.
              </span>
              <Formik
                onSubmit={(data) => {
                  console.log(data);
                  if (data.level === "0") {
                    setError("Please select a level.");
                    return;
                  }

                  const payload = {
                    user_id: user?.id,
                    organization_id: currentOrganization?.id,
                    add_user: {
                      ...data,
                    },
                  };

                  makeApiRequest({
                    path: `/vendor/users`,
                    method: "POST",
                    params: payload,
                  })
                    .then((res) => {
                      if (res.status === 201) {
                        onSuccessfulAdd();
                      }
                    })
                    .catch(({ response }) => {
                      const { data } = response;
                      setError(data.errors);
                    });
                }}
                initialValues={{
                  first_name: "",
                  last_name: "",
                  email: email || "",
                  dob: null,
                  level: "0",
                  gender: "m",
                  phone_number: "",
                }}
              >
                <NewUserFormContent />
              </Formik>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

type UserFormProps = {
  first_name: string;
  last_name: string;
  email: string;
  gender: string;
  phone_number: string;
  dob: Date;
  level: string;
};

const NewUserFormContent = () => {
  const formik = useFormikContext<UserFormProps>();
  return (
    <Form>
      <div className="flex flex-wrap gap-5">
        <Field name="first_name">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div>
              <input
                className="bg-gray-100 rounded-lg border-none"
                placeholder="First Name"
                type="text"
                {...field}
              />
            </div>
          )}
        </Field>

        <Field name="last_name">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div>
              <input
                className="bg-gray-100 rounded-lg border-none"
                placeholder="Last Name"
                type="text"
                {...field}
              />
            </div>
          )}
        </Field>

        <Field name="email">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div>
              <input
                className="bg-gray-100 rounded-lg border-none"
                placeholder="Email"
                type="email"
                {...field}
              />
            </div>
          )}
        </Field>

        <Field name="phone_number">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div>
              <input
                className="bg-gray-100 rounded-lg border-none"
                placeholder="Phone Number"
                type="text"
                {...field}
              />
            </div>
          )}
        </Field>

        <Field name="gender">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div>
              <select
                onChange={(e) => formik.setFieldValue("gender", e.target.value)}
                className="bg-gray-100 rounded-lg border-none"
                name="gender"
              >
                <option value="m">Male</option>
                <option value="f">Female</option>
              </select>
            </div>
          )}
        </Field>

        <Field name="dob">
          {({ field }: { field: FieldInputProps<never> }) => (
            <DatePicker
              maxDate={new Date()}
              minDate={new Date("01/01/1940")}
              showYearDropdown={true}
              placeholderText="DOB"
              selected={formik.values.dob}
              onChange={(date: Date | null) =>
                formik.setFieldValue("dob", date as Date)
              }
              className="focus:ring-0 focus:outline-blue w-full cursor-pointer text-gray-500 bg-gray-100 h-10 px-3 rounded-lg border-none"
            />
          )}
        </Field>

        <Field name="level">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div>
              <select
                onChange={(e) => {
                  if (e.target.value !== "0") {
                    formik.setFieldValue("level", e.target.value);
                  } else {
                    return;
                  }
                }}
                className="bg-gray-100 rounded-lg border-none"
                name="level"
              >
                <option value="0">Select a Level</option>
                <option value="1">Scanner</option>
                <option value="2">POS / Box Office</option>
                <option value="10">Reader</option>
                <option value="50">Org Admin</option>
              </select>
            </div>
          )}
        </Field>

        <Button variant="success" type="submit">
          Add User to Organization
        </Button>
      </div>
    </Form>
  );
};
