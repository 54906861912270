import { Link, useParams } from "react-router-dom";
import { makeApiRequest } from "../../../utils/api";
import { useQuery } from "@tanstack/react-query";
import { Order, OrderItem } from "../../../types/Order";
import { format } from "date-fns";
import { formatCentsToDollars } from "../../../utils/currency";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { HappyEvent } from "../../../types/Event";
import { useVendor } from "../../../providers/VendorContext";
import { CurrencyDollarIcon, LinkIcon } from "@heroicons/react/24/solid";
import { useAuth } from "../../../providers/AuthContext";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { Button } from "../../../components/reusable/Button";
import { useState } from "react";
import { Modal } from "../../../components/reusable/Modal";
import { useNotification } from "../../../providers/NotificationContext";
import { classNames } from "../../../utils/styles";
import { Payment } from "../../../types/Payment";
import {
  ArrowTopRightOnSquareIcon,
  ChevronDoubleLeftIcon,
} from "@heroicons/react/24/outline";
import { User } from "../../../types/User";
import { Spinner } from "../../../components/reusable/Spinner";
export const ShowOrder = () => {
  const { showSuccess, showError } = useNotification();
  const [refundAmount, setRefundAmount] = useState<number>(0);
  const [refundNotes, setRefundNotes] = useState<string>("");
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [refundLoading, setRefundLoading] = useState(false);
  const { user } = useAuth();
  const { currentOrganization } = useVendor();
  const params = useParams();
  const { data, refetch: refetchOrders } = useQuery({
    queryKey: ["orders", params.id, currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: `/vendor/orders/${params.id}`,
        params: { organization_id: currentOrganization?.id },
      });
    },
    enabled: !!(params && params.id) && !!currentOrganization,
  });

  const order: Order = data?.data;
  const submitRefund = () => {
    setRefundLoading(true);
    const refundAmountInCents = Math.round(refundAmount * 100);
    makeApiRequest({
      path: `/vendor/orders/${order.id}/refund`,
      method: "POST",
      params: {
        organization_id: currentOrganization?.id,
        refund: {
          amount: refundAmountInCents,
          notes: refundNotes,
        },
      },
    })
      .then((res) => {
        if (res.status === 200) {
          refetchOrders();
          setShowRefundModal(false);
          showSuccess("Refund submitted successfully");
          setRefundAmount(0);
          setRefundNotes("");
          setRefundLoading(false);
        }
      })
      .catch((err) => {
        const errorMessage = err.response.data.message;
        showError(errorMessage);
        setRefundLoading(false);
      });
  };

  const handleToggleFullRefund = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const totalInDollars = Math.abs(order.price_info.base / 100);
      setRefundAmount(totalInDollars);
    } else {
      setRefundAmount(0);
    }
  };

  if (!order) {
    return null;
  }
  return (
    <div>
      <Link
        className="flex items-center gap-2 text-lightPurple hover:brightness-125"
        to={`/vendor/organizations/${currentOrganization?.id}/orders`}
      >
        <ChevronDoubleLeftIcon className="w-5 h-5" />
        <span>Back to Orders</span>
      </Link>
      <div className="flex justify-between mt-5">
        <div className="w-full sm:w-3/4">
          <h1 className="text-2xl text-gray-500 font-bold">
            {order.user
              ? `${order.user?.first_name} ${order.user?.last_name} - Order #${order.id}`
              : `Order #${order.id}`}
          </h1>
          <span className="block">Created: {order.created_at}</span>
          {user?.admin && order.sale_payment ? (
            <span className="flex gap-1 items-center">
              <span>Stripe Payment:</span>
              <Link
                to={`${import.meta.env.VITE_STRIPE_PAYMENT_URL}/${
                  order.sale_payment?.stripe_payment_intent_id
                }`}
                target="_blank"
                className="text-lightPurple hover:brightness-125 flex items-center gap-1"
              >
                {order.sale_payment?.stripe_payment_intent_id}
                <LinkIcon className="w-4 h-4" />
              </Link>
            </span>
          ) : null}
        </div>
        <div className="sm:w-1/4">
          {order.price_info.total > 0 && new Date(order.event.end_date) >= new Date() && (
            <Button onClick={() => setShowRefundModal(true)}>
              Refund Order
            </Button>
          )}
        </div>
      </div>
      <VendorCard className="mt-5 text-sm">
        <div className="flex justify-between">
          <div>
            <h3 className="text-base text-gray-500 font-bold mb-2">
              User Details
            </h3>
            {order.user ? (
              <>
                <span className="block">
                  <span className="text-gray-400 font-bold text-xs">Name:</span>{" "}
                  <Link
                    className="text-lightPurple"
                    to={`/vendor/organizations/${currentOrganization?.id}/customers/${order.user.id}`}
                  >
                    {order.user.first_name} {order.user.last_name}
                  </Link>
                </span>
                <span className="block">
                  <span className="text-gray-400 font-bold text-xs">
                    User ID:
                  </span>{" "}
                  {order.user.id}
                </span>
                <span className="block">
                  <span className="text-gray-400 font-bold text-xs">
                    Email:
                  </span>{" "}
                  {order.user.email}
                </span>
                <span className="block">
                  <span className="text-gray-400 font-bold text-xs">
                    Phone:
                  </span>{" "}
                  {order.user.phone_number}
                </span>
              </>
            ) : (
              <span className="block text-gray-400 font-bold text-xs">
                No user attached
              </span>
            )}
          </div>
          <div className="w-1/3 items-center text-center flex flex-col gap-2">
            <span className="text-xl">Order Total</span>
            <span className="text-green-400 font-bold text-4xl">
              {formatCentsToDollars(order.price_info.total)}
            </span>
            {order.refunds.length > 0 ? (
              <>
                <span className="block mt-2 text-sm text-center font-bold">
                  Refund Total
                </span>
                <span className="block text-lg text-center text-red-400">
                  {formatCentsToDollars(order.price_info.refunded)}
                </span>
              </>
            ) : null}
          </div>
        </div>
        <div className="my-10 p-3 border-gray-200 rounded-lg border shadow-sm bg-gray-100">
          <span className="text-base block text-center text-gray-500 font-bold mb-2">
            Price Breakdown
          </span>
          <div className="flex gap-4 mt-2 w-full justify-center">
            <span>
              <span className="text-gray-400 font-bold text-xs">
                Base Price:
              </span>{" "}
              {formatCentsToDollars(order.price_info.base)}
            </span>
            <span>
              <span className="text-gray-400 font-bold text-xs">
                Processing Fee:
              </span>{" "}
              {formatCentsToDollars(order.price_info.processing_fee)}
            </span>
            <span>
              <span className="text-gray-400 font-bold text-xs">
                Happy Fee:
              </span>{" "}
              {formatCentsToDollars(order.price_info.happy_fee)}
            </span>
            <span>
              <span className="text-gray-400 font-bold text-xs">Tax:</span>{" "}
              {formatCentsToDollars(order.price_info.tax)}
            </span>
          </div>
        </div>

        <div className="mt-5">
          <h3 className="text-base font-bold mb-2">
            Items{" "}
            <span className="text-xs font-normal text-gray-400">
              ({order.order_items.length} items)
            </span>
          </h3>
          <OrderItemTable event={order.event} orderItems={order.order_items} />
        </div>

        {order.payments?.length > 0 ? (
          <>
            <h3 className="text-base font-bold mt-5 mb-2">Payments</h3>
            <PaymentTable payments={order.payments} />
          </>
        ) : null}
      </VendorCard>

      <Modal
        title={"Refund Order"}
        isOpen={showRefundModal}
        onRequestClose={() => setShowRefundModal(false)}
      >
        <div>
          <div className="flex flex-col gap-3">
            <div className="relative flex items-center gap-4">
              <CurrencyDollarIcon className="w-6 h-6 text-gray-400 absolute top-0 bottom-0 left-2 m-auto" />
              <input
                value={refundAmount}
                onChange={(e) => setRefundAmount(parseFloat(e.target.value))}
                placeholder="Refund Amount"
                type="number"
                min={0}
                max={order.price_info.base / 100}
                step={0.01}
                name="refund_amount"
                className="pl-10 h-10 border-gray-200 rounded-lg"
              />
              <div>
                <label
                  className={classNames(
                    "px-4 py-2 rounded-xl border-gray-300 border cursor-pointer text-sm",
                    refundAmount === order.price_info.total / 100
                      ? "bg-green-400 text-white border-none"
                      : "bg-white"
                  )}
                  htmlFor="full-refund"
                >
                  Full Refund
                </label>
                <input
                  onChange={handleToggleFullRefund}
                  id="full-refund"
                  type="checkbox"
                  name="full_refund"
                  className="hidden h-4 w-4 border-gray-300 cursor-pointer"
                />
              </div>
            </div>
            <textarea
              value={refundNotes}
              onChange={(e) => setRefundNotes(e.target.value)}
              placeholder="Refund notes..."
              rows={3}
              name="notes"
              className="rounded-lg border-gray-200"
            />
            <Button
              className="h-12 relative"
              disabled={refundLoading}
              onClick={submitRefund}
              type="submit"
            >
              <span>Submit Refund</span>
              {refundLoading && (
                <Spinner className="absolute right-4 top-0 bottom-0 left-0 m-auto" />
              )}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export const OrderItemTable = ({
  event,
  orderItems,
}: {
  event: HappyEvent;
  orderItems: OrderItem[];
}) => {
  const columnHelper = createColumnHelper<OrderItem>();
  const { currentOrganization } = useVendor();
  const columns = [
    columnHelper.accessor("id", {
      header: "Item ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("event", {
      header: "Event",
      cell: (info) => {
        return (
          <Link
            className="text-lightPurple hover:brightness-125"
            to={`/vendor/organizations/${currentOrganization?.id}/events/${event.id}`}
          >
            {event?.name}
          </Link>
        );
      },
    }),
    columnHelper.accessor("itemable_id", {
      header: "Item",
      cell: (info) => {
        const label = info.row.original.object_name;
        const isTicket = info.row.original.itemable_type === "Ticket";

        return isTicket ? (
          <Link
            className="text-lightPurple hover:brightness-125"
            to={`/vendor/organizations/${
              currentOrganization?.id
            }/tickets/${info.getValue()}`}
          >
            {label}
          </Link>
        ) : (
          <span className="text-gray-400">{label}</span>
        );
      },
    }),

    columnHelper.accessor("ticket_info", {
      header: "UID",
      cell: (info) => info.getValue()?.uid,
    }),
    columnHelper.accessor("price_cents", {
      header: "Price",
      cell: (info) => formatCentsToDollars(info.getValue()),
    }),
  ];

  const table = useReactTable({
    data: orderItems,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <>
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="text-gray-500 text-sm" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th className="text-left" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y divide-gray-200">
          {table?.getRowModel()?.rows?.map((row) => {
            return (
              <tr className="h-11 text-sm" key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td className="py-1 text-gray-400" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export const PaymentTable = ({ payments }: { payments: Payment[] }) => {
  const columnHelper = createColumnHelper<Payment>();
  const { currentOrganization } = useVendor();
  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => {
        return (
          <Link
            className="text-lightPurple hover:brightness-125"
            to={`/vendor/organizations/${
              currentOrganization?.id
            }/payments/${info.getValue()}`}
          >
            {info.getValue()}
          </Link>
        );
      },
    }),
    columnHelper.accessor("event", {
      header: "Event",
      cell: (info) => {
        const event: HappyEvent | undefined = info.renderValue() as
          | HappyEvent
          | undefined;
        return (
          <Link
            className="text-lightPurple hover:brightness-125"
            to={`/vendor/organizations/${currentOrganization?.id}/events/${event?.id}`}
          >
            {event?.name}
          </Link>
        );
      },
    }),
    columnHelper.accessor("order", {
      header: "Order",
      cell: (info) => {
        const order: Order | undefined = info.renderValue() as
          | Order
          | undefined;
        return (
          <Link
            className="text-lightPurple hover:brightness-125"
            to={`/vendor/organizations/${currentOrganization?.id}/orders/${order?.id}`}
          >
            #{order?.id}
          </Link>
        );
      },
    }),
    columnHelper.accessor("user", {
      header: "User",
      cell: (info) => {
        const user: User | undefined = info.renderValue() as User | undefined;
        return (
          <Link
            className="text-lightPurple hover:brightness-125"
            to={`/vendor/organizations/${currentOrganization?.id}/customers/${user?.id}`}
          >
            {user?.first_name} {user?.last_name}
          </Link>
        );
      },
    }),
    columnHelper.accessor("payment_type", {
      header: "Payment Type",
      cell: (info) => {
        return info.getValue();
      },
    }),
    columnHelper.accessor("amount_cents", {
      header: "Amount",
      cell: (info) => {
        console.log(info.row.original.payment_type);
        return (
          <span
            className={
              info.row.original.payment_type === "Sale"
                ? "text-green-400"
                : "text-red-400"
            }
          >
            ${((info.getValue() || 0) / 100).toFixed(2)}
          </span>
        );
      },
    }),
    columnHelper.accessor("created_at", {
      header: "Created",
      cell: (info) => info.getValue(),
    }),
  ];

  const table = useReactTable({
    data: payments,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <>
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="text-gray-500 text-sm" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th className="text-left" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y divide-gray-200">
          {table?.getRowModel()?.rows?.map((row) => {
            return (
              <tr className="h-11 text-sm" key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td className="py-1 text-gray-400" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
