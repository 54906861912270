import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { makeApiRequest } from "../../../utils/api";
import { useVendor } from "../../../providers/VendorContext";
import { User } from "../../../types/User";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { OrdersTable } from "../Events/EventDetails";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";

export const ViewCustomer = () => {
  const { customerId } = useParams();
  const { currentOrganization } = useVendor();
  const { data } = useQuery({
    queryKey: ["customer", customerId, currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/organizations/${currentOrganization?.id}/customers/${customerId}`,
      }),
    enabled: !!customerId && !!currentOrganization,
  });

  const customer: User = data?.data;

  if (customer) {
    return (
      <>
        <Link to={`/vendor/organizations/${currentOrganization?.id}/customers`}>
          <div className="flex items-center gap-2 text-lightPurple">
            <ChevronDoubleLeftIcon className="w-5 h-5" />
            <span>Back to Customers</span>
          </div>
        </Link>
        <div className="flex justify-between mt-5">
          <div>
            <h1 className="text-2xl font-bold">{customer.full_name}</h1>
            <span className="text-gray-400">{customer.email}</span>
          </div>
          <div>{/* buttons or soemthing */}</div>
        </div>
        <VendorCard className="mt-5">
          <div>
            <h2 className="text-lg font-bold">Details</h2>
            <div>
              <span className="text-gray-400">Phone:{' '}</span>
              <span>{customer.phone_number || 'N/A'}</span>
            </div>
            <div>
              <span className="text-gray-400">Gender:{' '}</span>
              <span>{customer.gender || 'N/A'}</span>
            </div>
          </div>

          <div className="my-3">
            <span className="font-bold text-lg">Orders</span>
            <OrdersTable showCsvDownload={false} orders={customer.orders} />
          </div>
        </VendorCard>
      </>
    );
  }

  return null;
};
