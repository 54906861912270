import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { formatCentsToDollars } from "../../../utils/currency";
import { makeApiRequest } from "../../../utils/api";
import { useVendor } from "../../../providers/VendorContext";
import { Product } from "../../../types/Product";
import { renderIcon } from "../../../utils/products";

export const ProductList = ({
  products,
  refetchProducts,
  onEditProduct,
}: {
  products: Product[];
  refetchProducts: () => void;
  onEditProduct: (product: Product) => void;
}) => {
  const { currentOrganization } = useVendor();

  const updateProduct = (id: number, data: unknown) => {
    makeApiRequest({
      path: `/vendor/products/${id}`,
      method: "PATCH",
      params: {
        organization_id: currentOrganization?.id,
        product: data,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          refetchProducts();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const deleteProduct = (id: number) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      makeApiRequest({
        path: `/vendor/products/${id}`,
        method: "DELETE",
        params: {
          organization_id: currentOrganization?.id,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            refetchProducts();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  if (!products) return null;

  if (products.length === 0) {
    return <div className="font-medium text-gray-600 text-center mt-4">No products available</div>;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {products.map((product) => (
        <div key={product.id} className="bg-white border border-gray-200 shadow-sm rounded-lg flex flex-col">
          <button
            onClick={() => updateProduct(product.id, { active: !product.active })}
            className={`px-4 py-2 rounded-t-lg text-xs font-semibold text-center ${
              product.active ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'
            }`}
          >
            {product.active ? 'Active' : 'Inactive'}
          </button>
          <div className="p-4 flex-1 flex flex-col justify-between">
            <div>
              <div className="text-lg font-semibold mb-2">{product.name}</div>
              <div className="flex gap-2 items-center bg-blue-100 text-blue-600 rounded py-1">
                {product.product_categories.map((category) => renderIcon(category.icon))}
              </div>
            </div>
            <div className="mt-4 flex justify-between items-center">
              <span className="text-green-600 font-semibold">
                {formatCentsToDollars(product.price_cents)}
              </span>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => onEditProduct(product)}
                  className="text-blue-500 hover:text-blue-700"
                  title="Edit"
                >
                  <PencilIcon className="w-5 h-5" />
                </button>
                <button
                  onClick={() => deleteProduct(product.id)}
                  className="text-red-500 hover:text-red-700"
                  title="Delete"
                >
                  <TrashIcon className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
