import { Fragment, useState } from "react";
import {
  Transition,
  Menu,
  MenuItems,
  MenuItem,
  MenuButton,
} from "@headlessui/react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { SalesByTier } from "./SalesByTier";
import { SalesBySection } from "./SalesBySection";
import { SalesByPriceLevel } from "./SalesByPriceLevel";
import { EventSellThrough } from "./EventSellThrough";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { ScanReport } from "./ScanReport";
import { SalesByPaymentMethod } from "./SalesByPaymentMethod";
export type Option = {
  label: string;
  href: string;
};
const Reports = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<Option | null>({
    label: "Sales By Tier",
    href: "sales-by-tier",
  });

  const options = [
    { label: "Sales By Tier", href: "sales-by-tier" },
    { label: "Sales by Section", href: "sales-by-section" },
    { label: "Sales by Payment Method", href: "sales-by-payment-method" },
    { label: "Sales by Price Level", href: "sales-by-price-level" },
    { label: "Event Sell Through", href: "event-sell-through" },
    { label: "Scanning Report", href: "scanning-report" },
  ];

  const handleOptionSelect = (option: Option) => {
    setSelectedOption(option);
    navigate(option.href);
  };

  return (
    <div className="w-full flex flex-col items-center py-6">
      <div className="flex items-center justify-between w-full">
        <span className="text-2xl font-medium text-gray-500">Reports</span>
        <Menu as="div" className="inline-block text-left">
          <MenuButton className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
            {selectedOption ? selectedOption.label : "Select a Report"}
            <ChevronDownIcon
              className="w-5 h-5 ml-2 -mr-1 text-gray-400 hover:text-violet-100"
              aria-hidden="true"
            />
          </MenuButton>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems anchor="bottom end"  className="w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-1 py-1">
                {options.map((option) => (
                  <MenuItem key={option.label}>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        onClick={() => handleOptionSelect(option)}
                      >
                        {option.label}
                      </button>
                    )}
                  </MenuItem>
                ))}
              </div>
            </MenuItems>
          </Transition>
        </Menu>
      </div>

      <Routes>
        <Route path="sales-by-tier" element={<SalesByTier />} />
        <Route path="sales-by-section" element={<SalesBySection />} />
        <Route path="sales-by-price-level" element={<SalesByPriceLevel />} />
        <Route
          path="sales-by-payment-method"
          element={<SalesByPaymentMethod />}
        />
        <Route path="event-sell-through" element={<EventSellThrough />} />
        <Route path="scanning-report" element={<ScanReport />} />
      </Routes>
    </div>
  );
};

export default Reports;
