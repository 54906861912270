import { Link, useNavigate, useParams } from "react-router-dom";
import { makeApiRequest } from "../../../utils/api";
import { useQuery } from "@tanstack/react-query";
import { Ticket } from "../../../types/Ticket";
import QrCode from "react-qr-code";
import { format } from "date-fns";
import { formatCentsToDollars } from "../../../utils/currency";
import { Button } from "../../../components/reusable/Button";
import { useVendor } from "../../../providers/VendorContext";
import { TicketTransfer, TicketUIDChange } from "../../../types/TicketTransfer";
import { ArrowsRightLeftIcon, ChevronDoubleLeftIcon, QrCodeIcon } from "@heroicons/react/24/outline";
export const ShowTicket = () => {
  const navigate = useNavigate();

  const { currentOrganization } = useVendor();
  const params = useParams();
  const { data, refetch } = useQuery({
    queryKey: ["tickets", params.id, currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: `/vendor/tickets/${params.id}`,
        params: { organization_id: currentOrganization?.id },
      });
    },
    enabled: !!(params && params.id) && !!currentOrganization,
  });

  const ticket: Ticket = data?.data;
  const updateTicket = ({ suspended }: { suspended: boolean }) => {
    makeApiRequest({
      path: `/vendor/tickets/${ticket.id}`,
      method: "PUT",
      params: {
        organization_id: currentOrganization?.id,
        ticket: {
          suspended,
        },
      },
    }).then((res) => {
      if (res.status === 200) {
        refetch();
      }
    });
  };
  if (!ticket) {
    return null;
  }
  return (
    <div>
      <span role="link" onClick={() => navigate(-1)}>
        <div className="flex items-center gap-2 text-lightPurple text-sm mb-5">
          <ChevronDoubleLeftIcon className="h-5 w-5" />
          <span>Go Back</span>
        </div>
      </span>
      <div className="flex justify-between">
        <h1 className="text-2xl text-gray-500 font-bold flex gap-2 items-center">
          Ticket #{ticket.id}
          {ticket.suspended && (
            <div className="text-xs bg-red-500 px-2 py-1 text-white rounded">
              Suspended
            </div>
          )}
        </h1>
        {!ticket.suspended ? (
          <Button
            onClick={() => {
              if (
                window.confirm("Are you sure you want to suspend this ticket?")
              ) {
                updateTicket({ suspended: true });
              }
            }}
            size="sm"
            variant="error"
          >
            Suspend
          </Button>
        ) : (
          <Button
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to cancel the suspension?"
                )
              ) {
                updateTicket({ suspended: false });
              }
            }}
            size="sm"
            variant="success"
          >
            Cancel Suspension
          </Button>
        )}
      </div>

      <div className="rounded-lg bg-white shadow-sm mt-5 p-4 text-sm text-gray-500">
        <div>
          <h3 className="text-base text-gray-500 font-bold mb-2">
            Ticket Details
          </h3>
          <div className="flex flex-col sm:flex-row gap-5">
            <div className="flex w-1/2 sm:w-auto">
              <div
                style={{
                  borderColor: ticket.color,
                }}
                className={`text-center border-2 pb-6 rounded-tr-lg rounded-tl-lg`}
              >
                <div
                  style={{
                    backgroundColor: ticket.color,
                  }}
                  className={`w-full py-4 text-white flex gap-2 items-center justify-center rounded-tr-lg rounded-tl-lg -mt-1`}
                >
                  <img
                    src="//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-white.svg"
                    alt="happy white"
                    className="w-8"
                  />
                  <span className="font-milkshake text-xl">
                    Happy Ticketing
                  </span>
                </div>
                <span className="my-3 text-lg block font-bold">
                  {ticket.event?.name}
                </span>
                <span>
                  {format(
                    new Date(ticket.event?.start_date),
                    "E, MMM do, Y @ h:mm a"
                  )}
                </span>
                <span className="block">{ticket.venue_name}</span>

                <div className="flex justify-between my-2 items-center">
                  <div className="w-1/3 flex flex-col text-sm">
                    <span>Name</span>
                    <span className="font-bold">{ticket.user?.first_name} {ticket.user?.last_name}</span>
                  </div>
                  <div className="w-1/3 flex flex-col text-sm">
                    <span>Tier</span>
                    <span className="font-bold">{ticket.tier.name}</span>
                  </div>
                  <div className="w-1/3 flex flex-col text-sm">
                    <span>Section</span>
                    <span className="font-bold">{ticket.section.name}</span>
                  </div>
                </div>
                <div className="mt-5 flex flex-col items-center">
                  <div className="px-12 flex items-center justify-center">
                    <QrCode value={ticket.uid} className="lg:w-full h-auto" />
                  </div>
                  <span className="block mt-3 text-sm">{ticket.uid}</span>

                  {ticket.sponsor_logo_url && (
                    <>
                      <div className="flex justify-center mt-3">
                        <img
                          src={ticket.sponsor_logo_url}
                          alt="sponsor logo"
                          className="w-16 h-auto"
                        />
                      </div>
                    </>
                  )}
                </div>

              </div>
            </div>

            <div className="flex gap-3 w-1/2 sm:w-auto">
              <div className="flex gap-2 flex-col">
                <span>
                  <b>Ticket UID:</b> {ticket.uid}
                </span>
                <span>
                  <b>Event:</b>{" "}
                  <Link
                    className="text-lightPurple"
                    to={`/vendor/organizations/${currentOrganization?.id}/events/${ticket.event.id}`}
                  >
                    {ticket.event.name}
                  </Link>
                </span>
                <span>
                  <b>Tier:</b> {ticket.tier.name}
                </span>
                <span>
                  <b>Section:</b> {ticket.section.name}
                </span>
                <span>
                  <b>Price:</b>{" "}
                  {ticket.price_level ? (
                    <span>
                      {ticket.price_level.name}{" "}
                      {`(${formatCentsToDollars(
                        ticket.price_level.price_cents
                      )})`}
                    </span>
                  ) : null}
                </span>
                {ticket.user ? (
                  <span>
                    <b>User:</b>{" "}
                    <Link
                      className="text-lightPurple"
                      to={`/vendor/organizations/${currentOrganization?.id}/customers/${ticket.user.id}`}
                    >
                      {`${ticket.user?.first_name} ${ticket.user?.last_name}`}
                    </Link>
                  </span>
                ) : null}
                <span>
                  <b>Sold At:</b> {ticket.sold_at || "N/A"}
                </span>
                {ticket.order_id ? (
                  <span>
                    <b>Order ID:</b>{" "}
                    <Link
                      className="text-lightPurple"
                      to={`/vendor/organizations/${currentOrganization?.id}/orders/${ticket.order_id}`}
                    >
                      {ticket.order_id}
                    </Link>
                  </span>
                ) : null}
                {ticket.payment_id ? (
                  <span>
                    <b>Payment ID:</b>{" "}
                    <Link
                      className="text-lightPurple"
                      to={`/vendor/organizations/${currentOrganization?.id}/payments/${ticket.payment_id}`}
                    >
                      {ticket.payment_id}
                    </Link>
                  </span>
                ) : null}
                <span>
                  <b>Status:</b> {ticket.status}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <h3 className="text-base text-gray-500 font-bold mb-2">Scans</h3>
          {ticket.scan_events.length ? (
            <div>
              {ticket.scan_events.map((scan) => {
                return (
                  <div>
                    <span className="flex items-center gap-2 py-2 border-b border-gray-300">
                      <QrCodeIcon className="h-5 w-5 inline-block" />
                      {`Scanned by User #${scan.user_id} on ${format(
                        new Date(scan.created_at),
                        "MM/dd/yyyy @ h:mm:ss a"
                      )}`}
                    </span>
                  </div>
                );
              })}
            </div>
          )
             : <span>No Scans</span>}
        </div>

        <div className="mt-5">
          <h3 className="text-base text-gray-500 font-bold mb-2">Transfers</h3>
          {ticket.ticket_transfers.length ? (
            <div>
              {ticket.ticket_transfers.map((transfer: TicketTransfer) => {
                return (
                  <div>
                    <span className="block py-2 border-b border-gray-300">
                      <ArrowsRightLeftIcon className="h-5 w-5 inline-block mr-2" />
                      {/* {`Transferred from ${transfer.sending_user.email} to ${transfer.email} on ${format(new Date(transfer.status_updated_at), "MM/dd/yyyy")}`} */}
                      Transferred from{" "}
                      <span className="text-blue">
                        {transfer.sending_user.email}
                      </span>{" "}
                      to{" "}
                      <span className="text-green-400">{transfer.email}</span>{" "}
                      on{" "}
                      {format(
                        new Date(transfer.status_updated_at),
                        "MM/dd/yyyy"
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
          ) : (
            <span>No Transfers</span>
          )}
        </div>

        <div className="mt-5">
          <h3 className="text-base text-gray-500 font-bold mb-2">
            UID Changes
          </h3>
          {ticket.ticket_transfers.length ? (
            <div>
              {ticket.ticket_uid_changes.map((change: TicketUIDChange) => {
                return (
                  <div>
                    <span className="block py-2 border-b border-gray-300">
                      <QrCodeIcon className="h-5 w-5 inline-block mr-2" />
                      {/* {`Transferred from ${transfer.sending_user.email} to ${transfer.email} on ${format(new Date(transfer.status_updated_at), "MM/dd/yyyy")}`} */}
                      From {change.old_uid} to {change.new_uid}{" "}
                      <strong className="inline-block pl-4">Reason: </strong>
                      <span className="text-blue">{change.reason}</span>{" "}
                    </span>
                  </div>
                );
              })}
            </div>
          ) : (
            <span>No UID Changes</span>
          )}
        </div>
      </div>
    </div>
  );
};
