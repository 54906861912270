import Select, { MultiValue } from "react-select";
import makeAnimated from "react-select/animated";
import { Field, FieldInputProps, Form, Formik, useFormikContext } from "formik";
import { makeApiRequest } from "../../../utils/api";
import { TextAreaInput, TextInput } from "../../../components/reusable/Form";
import { Button } from "../../../components/reusable/Button";
import { useVendor } from "../../../providers/VendorContext";
import { useNotification } from "../../../providers/NotificationContext";
import { Product, ProductCategory } from "../../../types/Product";
const animatedComponents = makeAnimated();

export const ProductForm = ({
  productCategories,
  onSuccessfulCreate,
  editingProduct
}: {
  productCategories: ProductCategory[];
  onSuccessfulCreate?: () => void;
  editingProduct: Product | null;
}) => {
  const { currentOrganization } = useVendor();
  const { showError } = useNotification();
  const initialValues = editingProduct ? {
    name: editingProduct.name,
    description: editingProduct.description,
    price_dollars: (editingProduct.price_cents / 100).toString(),
    active: editingProduct.active,
    product_category_ids: editingProduct.product_categories.map((category) => ({
      label: category.name,
      value: category.id,
    })),
  } : {
    name: "",
    description: "",
    price_dollars: "",
    active: true,
    product_category_ids: [],
  };
  console.log(editingProduct, initialValues);
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(data) => {
        const payload = {
          ...data,
          price_cents: parseFloat(data.price_dollars) * 100,
          organization_id: currentOrganization?.id,
          product_category_ids: data.product_category_ids.map(
            (category: SelectOption) => category.value
          ),
        };

        const apiPath = editingProduct ? `/vendor/products/${editingProduct.id}` : "/vendor/products";
        const apiMethod = editingProduct ? "PUT" : "POST";
        makeApiRequest({
          path: apiPath,
          method: apiMethod,
          params: {
            organization_id: currentOrganization?.id,
            product: payload,
          },
        })
          .then((res) => {
            if (res.status === 201 || res.status === 200) {
              onSuccessfulCreate && onSuccessfulCreate();
            }
          })
          .catch((err) => {
            const msg =
              err.response?.data?.message ||
              "Something went wrong. Please try again later.";
            showError(msg);
          });
      }}
    >
      <Form className="space-y-4">
        <div>
          <Field name="name">
            {({ field }: { field: FieldInputProps<never> }) => (
              <TextInput label="Product Name" {...field} />
            )}
          </Field>
        </div>

        <div>
          <Field name="description">
            {({ field }: { field: FieldInputProps<never> }) => (
              <TextAreaInput label="Description" {...field} />
            )}
          </Field>
        </div>

        <div>
          <Field name="price_dollars">
            {({ field }: { field: FieldInputProps<never> }) => (
              <TextInput label="Price" {...field} type="number" />
            )}
          </Field>
        </div>

        <div>
          <CategoryMultiSelect productCategories={productCategories} />
        </div>
        
        <div>
          <ActiveSelect />
        </div>

        <Button
          size="lg"
          type="submit"
          variant="success"
          className="mt-6 w-full"
        >
          Save
        </Button>
      </Form>
    </Formik>

  );
};


type SelectOption = {
  label: string;
  value: number;
};

export const ActiveSelect = () => {
  const formik = useFormikContext();

  return (
    <Field name="active">
      {({ field }: { field: FieldInputProps<string> }) => (
        <div className="flex flex-col gap-2 w-full mt-5">
          <label>Active</label>
          <select
            className="h-10 rounded bg-white border border-gray-300"
            value={field.value}
            onChange={(e) =>
              // field.(e.target.value === "true")
              formik.setFieldValue(field.name, e.target.value === "true")
            }
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
      )}
    </Field>
  );
};

export const CategoryMultiSelect = ({
  productCategories,
}: {
  productCategories: ProductCategory[];
}) => {
  const formik = useFormikContext();
  const options: SelectOption[] = productCategories.map((category) => ({
    label: category.name,
    value: category.id,
  }));
  return (
    <Field name="product_category_ids">
      {({ field }: { field: FieldInputProps<never> }) => (
        <div>
          <label className="my-2 block">Select Categories</label>
          <Select
            {...field}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: "40px",
                border: "1px solid #d2d5db",
              }),
              multiValueLabel: (labelStyles) => ({
                ...labelStyles,
                textOverflow: "",
              }),
            }}
            onChange={(selectedOptions) => {
              formik.setFieldValue("product_category_ids", selectedOptions);
            }}
            closeMenuOnSelect={false}
            components={animatedComponents}
            defaultValue={null}
            isMulti
            options={options}
          />
        </div>
      )}
    </Field>
  );
};