import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { useVendor } from "../../../providers/VendorContext";
import { makeApiRequest } from "../../../utils/api";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { Bento, GridItemType } from "../../../components/reusable/Bento";
import { Payment } from "../../../types/Payment";
import { format } from "date-fns";
import { formatCentsToDollars } from "../../../utils/currency";
import { classNames } from "../../../utils/styles";
import { useAuth } from "../../../providers/AuthContext";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";

export const ShowPayment = () => {
  const params = useParams();
  const paymentId = params.id;
  const { user } = useAuth();
  const { currentOrganization } = useVendor();
  const { data } = useQuery({
    queryKey: ["payment", currentOrganization, paymentId],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/payments/${paymentId}`,
        params: {
          organization_id: currentOrganization?.id,
        },
      }),
    enabled: !!currentOrganization,
  });
  const payment: Payment = data?.data;
  const bentoItems = [
    {
      children: (
        <>
          <span className="font-bold text-gray-500 block">Payment Details</span>

          <div className="flex flex-col gap-1 mt-4">
            <span className="flex gap-2">
              <span className="text-gray-500">ID: </span>
              <span className="block text-gray-400">{payment?.id}</span>
            </span>

            <span className="flex gap-2">
              <span className="text-gray-500">Amount: </span>
              <span className="block text-gray-400">
                {formatCentsToDollars(payment?.amount_cents)}
              </span>
            </span>

            <span className="flex gap-2">
              <span className="text-gray-500">Created: </span>
              <span className="block text-gray-400">
                {payment
                  ? payment?.created_at
                  : null}
              </span>
            </span>
            {user?.admin ? (
              <span className="flex gap-2">
                <span className="text-gray-500">Stripe ID: </span>
                <span className="block text-gray-400">
                  {payment?.stripe_payment_intent_id}
                </span>
              </span>
            ) : null}

            <span className="flex gap-2">
              <span className="text-gray-500">Notes: </span>
              <span className="block text-gray-400">
                {payment?.notes}
              </span>
            </span>

            <span className="flex gap-2">
              {payment?.payment_card ? (
                <>
                  <span className="text-gray-500">Payment Card: </span>
                  <span className="block text-gray-400">
                    <span className="block text-gray-400">
                      {payment?.stripe_payment_intent_id}
                    </span>
                  </span>
                </>
              ) : null}
            </span>
          </div>
        </>
      ),
    },
    {
      children: (
        <>
          <span className="font-bold text-gray-500 block">User Details</span>

          <div className="flex flex-col gap-1 mt-4">
            <span className="flex gap-2">
              <span className="text-gray-500">Name: </span>
              <span className="block">
                <Link className="text-lightPurple" to={`/vendor/organizations/${currentOrganization?.id}/customers/${payment?.user?.id}`}>
                  {payment?.user?.first_name} {payment?.user?.last_name}
                </Link>
              </span>
            </span>

            <span className="flex gap-2">
              <span className="text-gray-500">Email: </span>
              <span className="block text-gray-400">
                {payment?.user?.email}
              </span>
            </span>

            <span className="flex gap-2">
              <span className="text-gray-500">Phone: </span>
              <span className="block text-gray-400">
                {payment?.user?.phone_number}
              </span>
            </span>
          </div>
        </>
      ),
    },
    {
      children: (
        <>
          <span className="font-bold text-gray-500 block">Event Details</span>

          <div className="flex flex-col gap-1 mt-4">
            <span className="flex gap-2">
              <span className="text-gray-500">Name: </span>
              <span className="block text-gray-400">
                <Link
                  className="text-lightPurple"
                  to={`/vendor/organizations/${currentOrganization?.id}/events/${payment?.event.id}`}
                >
                  {payment?.event?.name}
                </Link>
              </span>
            </span>

            <span className="flex gap-2">
              <span className="text-gray-500">Location: </span>
              <span className="block text-gray-400">
                {payment?.event.city}, {payment?.event.state}
              </span>
            </span>

            <span className="flex gap-2">
              <span className="text-gray-500">Start: </span>
              <span className="block text-gray-400">
                {payment ? payment?.event_start : null}
              </span>
            </span>

            <span className="flex gap-2">
              <span className="text-gray-500">End: </span>
              <span className="block text-gray-400">
                {payment ? payment?.event_end : null}
              </span>
            </span>
          </div>
        </>
      ),
    },
    {
      children: (
        <>
          <span className="font-bold text-gray-500 block">Order Details</span>

          <div className="flex flex-col gap-1 mt-4">
            <span className="flex gap-2">
              <span className="text-gray-500">ID: </span>
              <span className="block text-gray-400">
                <Link
                  className="text-lightPurple"
                  to={`/vendor/organizations/${currentOrganization?.id}/orders/${payment?.order?.id}`}
                >
                  {payment?.order?.id}
                </Link>
              </span>
            </span>

            {/* <span className="flex gap-2">
              <span className="text-gray-500">Location: </span>
              <span className="block text-gray-400">
                {payment?.or.city}, {payment?.event.state}
              </span>
            </span> */}

            <span className="flex gap-2">
              <span className="text-gray-500">Created: </span>
              <span className="block text-gray-400">
                {payment ? payment?.order_created_at  : null}
              </span>
            </span>

            <span className="flex gap-2">
              <span className="text-gray-500">Order Total: </span>
              <span className="block text-gray-400">
                {formatCentsToDollars(payment?.order.total_price_cents)}
              </span>
            </span>
          </div>
        </>
      ),
    },
  ];
  if (payment) {
    return (
      <>
        <Link
          to={`/vendor/organizations/${currentOrganization?.id}/payments`}
          className="text-lightPurple flex items-center gap-2"
        >
          <ChevronDoubleLeftIcon className="h-5 w-5" />
          <span>Back to Payments</span>
        </Link>
        <div className="flex justify-between my-6">
          <div className="flex gap-3 items-center">
            <h1 className="text-2xl font-bold text-gray-500 block">
              Payment ID: #{payment.id}
            </h1>
            {payment.payment_type === "Refund" ? (
              <span className="px-4 py-1 font-bold text-sm text-red-400 flex items-center justify-center bg-red-400/40 rounded-lg">
                Refund
              </span>
            ) : (
              <span className="px-4 py-1 font-bold text-sm text-green-400 flex items-center justify-center bg-green-400/30 rounded-lg">
                Sale
              </span>
            )}
          </div>
          <span
            className={classNames(
              "text-2xl font-bold",
              payment.payment_type === "Refund"
                ? "text-red-400"
                : "text-green-400"
            )}
          >
            {formatCentsToDollars(payment.amount_cents)}
          </span>
        </div>

        <div className="flex flex-wrap justify-between gap-y-10">
          {bentoItems.map((item, index) => (
            <VendorCard className="w-[48%]" key={index}>{item.children}</VendorCard>
          ))}
        </div>
      </>
    );
  }

  return null;
};
