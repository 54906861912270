import { ShowModalType } from ".";
import { Modal } from "../../../components/reusable/Modal";
import { useNotification } from "../../../providers/NotificationContext";
import { Product, ProductCategory } from "../../../types/Product";
import { ProductCategoryForm } from "./ProductCategoryForm";
import { ProductForm } from "./ProductForm";

export const ProductModal = ({
  showModal,
  onRequestClose,
  productCategories,
  refetchProductCategories,
  refetchProducts,
  modalContent,
  editingProduct,
  editingProductCategory,
}: {
  showModal: boolean;
  onRequestClose: () => void;
  productCategories: ProductCategory[];
  refetchProductCategories: () => void;
  refetchProducts: () => void;
  modalContent: ShowModalType;
  editingProduct: Product | null;
  editingProductCategory: ProductCategory | null;
}) => {
  const { showSuccess } = useNotification();
  const onSuccessfulProductCreate = () => {
    const message = editingProduct ? "Product updated successfully" : "Product created successfully";
    onRequestClose();
    showSuccess(message);
    refetchProducts();
  };

  const onSuccessfulProductCategoryCreate = () => {
    const message = editingProductCategory ? "Product category updated successfully" : "Product category created successfully";
    onRequestClose();
    showSuccess(message);
    refetchProductCategories();
  };

  return (
    <Modal isOpen={!!showModal} onRequestClose={onRequestClose}>
      {modalContent === "product" && (
        <ProductForm
          editingProduct={editingProduct}
          onSuccessfulCreate={onSuccessfulProductCreate}
          productCategories={productCategories}
        />
      )}
      {modalContent === "productCategory" && (
        <ProductCategoryForm
          editingProductCategory={editingProductCategory}
          onSuccessfulCreate={onSuccessfulProductCategoryCreate}
        />
      )}
    </Modal>
  );
};
