import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/20/solid"
import { Fragment } from "react"


type ModalProps = {
  variant?: React.ReactNode
  children: React.ReactNode
  isOpen: boolean
  onRequestClose: () => void
} & React.HTMLAttributes<HTMLDivElement>

const modalVariantColors = {
  default: 'gray-300',
  success: '',
  error: '',
  warning: ''
}

export const Modal = ({children, variant = modalVariantColors['default'], title, isOpen, onRequestClose}: ModalProps) => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={onRequestClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="sm:w-3/4 lg:w-1/2 transform overflow-visible rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all relative">
                  <XMarkIcon className="w-6 absolute top-3 right-3" role="button" onClick={onRequestClose} />
                  {title ? (
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 capitalize"
                    >
                      {title}
                    </Dialog.Title>
                  ) : null}
                  <div className="mt-2">
                    {children}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}