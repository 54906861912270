import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { makeApiRequest } from "../../../utils/api";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Menu, Transition } from "@headlessui/react";
import {
  CheckIcon,
  Cog6ToothIcon,
  XMarkIcon,
  TicketIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/20/solid";
import { Fragment, useState } from "react";
import { HappyEvent, Section, Tier } from "../../../types/Event";
import { format } from "date-fns";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { Modal } from "../../../components/reusable/Modal";
import { Field, FieldInputProps, Form, Formik, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import { CirclePicker } from "react-color";
import { Button } from "../../../components/reusable/Button";
import { useNotification } from "../../../providers/NotificationContext";
import { useVendor } from "../../../providers/VendorContext";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import {
  EyeIcon,
  EyeSlashIcon,
  PlusCircleIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import { SquaresPlusIcon } from "@heroicons/react/24/solid";
type ModalFormState = {
  action: "create" | "edit";
  section: Section | null;
};
export const Sections = ({ event }: { event: HappyEvent }) => {
  const params = useParams();
  const [sections, setSections] = useState<Section[]>([]);
  const { currentOrganization } = useVendor();
  const columnHelper = createColumnHelper<Section>();
  const [modalForm, setModalForm] = useState<ModalFormState | null>();
  // const [editingSection, setEditingSection] = useState<Section | null>(null);
  const { dispatch } = useNotification();
  const { data: sectionData, refetch: refetchSections } = useQuery({
    queryKey: ["sections", params.id, currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: "/vendor/sections",
        params: {
          event_id: params.id,
          organization_id: currentOrganization?.id,
        },
      }).then((res) => {
        if (res.status === 200) {
          setSections(res.data);
          return res.data;
        }
        return [];
      });
    },
    enabled: !!params.id && !!currentOrganization,
    retry: false,
  });

  const handleDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const sourceSection = sections[source.index];
    const newSections = Array.from(sections);
    newSections.splice(source.index, 1);
    newSections.splice(destination.index, 0, sourceSection);

    setSections(newSections);
    makeApiRequest({
      path: `/vendor/sections/${sourceSection.id}`,
      method: "PUT",
      params: {
        organization_id: currentOrganization?.id,
        section: { sort_order: destination.index },
      },
    });
  };

  const handleToggleVisibility = (sectionId: number, visible: boolean) => {
    makeApiRequest({
      path: `/vendor/sections/${sectionId}`,
      method: "PUT",
      params: {
        organization_id: currentOrganization?.id,
        section: { visible },
      },
    }).then((res) => {
      if (res.status === 200) {
        refetchSections();
      }
    });
  };

  const deleteSection = (id: number) => {
    makeApiRequest({
      path: `/vendor/sections/${id}`,
      params: { organization_id: currentOrganization?.id },
      method: "DELETE",
    }).then((res) => {
      if (res.status === 200) {
        refetchSections();
        dispatch({
          type: "open",
          payload: {
            level: "success",
            message: "Successfully deleted section.",
          },
        });
      }
    });
  };
  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => {
        return info.getValue();
      },
    }),
    columnHelper.accessor("name", {
      header: "Name",
      cell: (info) => {
        return (
          <div
            className="text-lightPurple cursor-pointer"
            onClick={() =>
              setModalForm({
                section: info.row.original as Section,
                action: "edit",
              })
            }
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper.accessor("date_available_start", {
      header: "Start",
      cell: (info) => {
        if (!info.getValue()) {
          return <span>N/A</span>;
        }
        return (
          <span>{format(new Date(info.getValue()) as Date, "MM/dd/yyyy")}</span>
        );
      },
    }),
    columnHelper.accessor("date_available_end", {
      header: "End",
      cell: (info) => {
        if (!info.getValue()) {
          return <span>N/A</span>;
        }
        return (
          <span>{format(new Date(info.getValue()) as Date, "MM/dd/yyyy")}</span>
        );
      },
    }),
    columnHelper.accessor("tier", {
      header: "Tier",
      cell: (info) => {
        const tier: Tier | undefined = info.renderValue() as Tier | undefined;
        return <>{tier?.name}</>;
      },
    }),
    columnHelper.accessor("create_scan_after_pos_checkout", {
      header: "Scan After POS",
      cell: (info) => {
        const scanAfterPos = info.getValue();
        return (
          <span>
            {scanAfterPos ? (
              <CheckIcon className="h-5 w-5 text-green-500" />
            ) : (
              <XMarkIcon className="h-5 w-5 text-red-500" />
            )}
          </span>
        )
      },
    }),
    columnHelper.accessor("ticket_color", {
      header: "Ticket Color",
      cell: (info) => {
        if (!info.getValue()) {
          return <span>N/A</span>;
        }
        return (
          <span
            style={{ backgroundColor: info.getValue() }}
            className="flex items-center justify-center p-3 w-5 h-5"
          />
        );
      },
    }),
    columnHelper.accessor("visible", {
      header: "Visible",
      cell: (info) => {
        return (
          <div>
            {info.getValue() ? (
              <div className="flex items-center gap-1">
                <CheckIcon className="h-5 w-5 text-green-500" />
                <button
                  onClick={() =>
                    handleToggleVisibility(info.row.original.id, false)
                  }
                >
                  <EyeSlashIcon className="h-5 w-5" />
                </button>
              </div>
            ) : (
              <div className="flex items-center gap-1">
                <XMarkIcon className="h-5 w-5 text-red-500" />
                <button
                  onClick={() =>
                    handleToggleVisibility(info.row.original.id, true)
                  }
                >
                  <EyeIcon className="h-5 w-5" />
                </button>
              </div>
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor("ticket_count", {
      header: "Tickets",
      cell: (info) => {
        const allTickets = info.getValue();
        const availableTickets = info.row.original.available_ticket_count
        let color = "text-green-400"
        const lessThanQuarter = availableTickets < allTickets / 4
        const lessThanTenPercent = availableTickets < allTickets / 10
        if (lessThanQuarter) {
          color = "text-orange-500"
        } else if (lessThanTenPercent) {
          color = "text-red-500"
        }
        return (
          <div className="flex items-center gap-1">
            <span className={color}>{availableTickets}</span>
            <span className="text-gray-400">/</span>
            <span>{allTickets}</span>
          </div>
        )
      },
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        return (
          <div className="flex">
            <Menu as="div" className={"relative inline-block"}>
              <Menu.Button className="bg-transparent flex items-center justify-center">
                <Cog6ToothIcon className="h-6 text-gray-300" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="w-40 right-0 absolute bg-white shadow-md border-gray-200 border z-10 rounded-md">
                  <Menu.Item
                    as="div"
                    className="px-3 py-2 hover:bg-white/10 cursor-pointer border-gray-200 border-b"
                    onClick={() =>
                      setModalForm({
                        section: props.row.original as Section,
                        action: "edit",
                      })
                    }
                  >
                    <span className="text-gray-500 block">Edit</span>
                  </Menu.Item>
                  <Menu.Item
                    as="div"
                    className="px-3 py-2 hover:bg-white/10 cursor-pointer"
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you want to delete this section?"
                        )
                      ) {
                        deleteSection(props.row.original.id);
                      }
                    }}
                  >
                    <span className="text-pink/60 block">Delete</span>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        );
      },
    }),
  ];
  const table = useReactTable({
    data: sections,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleSuccessfulUpdate = () => {
    dispatch({
      type: "open",
      payload: {
        level: "success",
        message: "Successfully updated section!",
      },
    });
    setModalForm(null);
    refetchSections();
  };

  return (
    <>
      <VendorCard className="mt-5">
        {sections && sections.length ? (
          <>
            <div className="flex justify-between items-center">
              <h3 className="font-bold text-gray-500">Sections</h3>
              <Button
                onClick={() => {
                  setModalForm({ action: "create", section: null });
                }}
                size="sm"
                variant="blue"
              >
                <PlusIcon className="w-4" />
                Add Section
              </Button>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
              <table className="min-w-full divide-y divide-gray-200 mt-5">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr className="text-gray-500 text-sm" key={headerGroup.id}>
                      <th></th>
                      {headerGroup.headers.map((header) => (
                        <th className="text-left" key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <tbody
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="divide-y divide-gray-200"
                    >
                      {table?.getRowModel()?.rows?.map((row, index) => {
                        return (
                          <Draggable
                            index={index}
                            key={index}
                            draggableId={`${index}`}
                          >
                            {(provided) => (
                              <tr
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                                key={row.original.id}
                                className="h-11 text-sm"
                              >
                                <td>
                                  <div {...provided.dragHandleProps}>
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-300" />
                                  </div>
                                </td>
                                {row.getVisibleCells().map((cell) => {
                                  return (
                                    <td
                                      className="py-1 text-gray-400"
                                      key={cell.id}
                                    >
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </table>
            </DragDropContext>
          </>
        ) : (
          <div className="w-ful flex items-center justify-center">
            <div className="max-w-lg py-5 px-16 flex flex-col gap-2 justify-center items-center">
              <div className="relative">
                <SquaresPlusIcon className="h-10 text-gray-300" />
              </div>
              <span className="text-xs font-bold">No Sections</span>
              <span className="text-xs"> Get started by adding a section!</span>
              <Button
                onClick={() => {
                  setModalForm({ action: "create", section: null });
                }}
                variant="pink"
                className="flex items-center gap-1 mt-5"
              >
                <PlusIcon className="w-4" /> Add Section
              </Button>
            </div>
          </div>
        )}
      </VendorCard>

      <Modal
        title={modalForm?.section ? "Edit Section" : "Create Section"}
        onRequestClose={() => setModalForm(null)}
        isOpen={!!modalForm}
      >
        <>
          <SectionForm
            onSuccessfulUpdate={handleSuccessfulUpdate}
            section={modalForm?.section}
            event={event}
          />
        </>
      </Modal>
    </>
  );
};

export const SectionForm = ({
  section,
  event,
  onSuccessfulUpdate,
}: {
  section?: Section | null;
  event: HappyEvent;
  onSuccessfulUpdate: () => void;
}) => {
  const { currentOrganization } = useVendor();
  const { showError } = useNotification();
  const { data: tiers, refetch: refetchTiers } = useQuery({
    queryKey: ["tiers", event.id, currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: "/vendor/tiers",
        params: {
          event_id: event.id,
          organization_id: currentOrganization?.id,
        },
      }).then((res) => {
        if (res.status === 200) {
          return res.data;
        }
        return [];
      });
    },
    enabled: !!event && !!currentOrganization?.id,
    retry: false,
  });

  return (
    <Formik
      initialValues={
        {
          name: section?.name ?? "",
          tier_id: section?.tier.id ?? "",
          date_available_start: section?.date_available_start
            ? new Date(section.date_available_start)
            : null,
          date_available_end: section?.date_available_end
            ? new Date(section.date_available_end)
            : null,
          create_scan_after_pos_checkout: section?.create_scan_after_pos_checkout ?? true,
          ticket_color: section?.ticket_color ?? "",
          visible: section?.visible ?? true,
        } as SectionFormProps
      }
      onSubmit={(data) => {
        console.log(data);
        const payload = {
          ...data,
          visible: !!data.visible,
          event_id: event.id,
        };
        const apiPath = section
          ? `/vendor/sections/${section?.id}`
          : "/vendor/sections";
        makeApiRequest({
          path: apiPath,
          method: section ? "PUT" : "POST",
          params: {
            section: payload,
            organization_id: currentOrganization?.id,
          },
        })
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              onSuccessfulUpdate();
            }
          })
          .catch((err) => {
            showError(err.response.data);
          });
      }}
    >
      <SectionFormContent event={event} tiers={tiers} />
    </Formik>
  );
};
type SectionFormProps = {
  name: string;
  tier_id: string;
  date_available_start: Date;
  date_available_end: Date;
  ticket_color: string;
  visible: boolean;
};
export const SectionFormContent = ({
  event,
  tiers,
}: {
  event: HappyEvent;
  tiers: Tier[];
}) => {
  const formik = useFormikContext<SectionFormProps>();
  return (
    <Form className="flex flex-col gap-4 py-5">
      <div className="flex justify-between gap-2">
        <Field name="name">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div className="flex flex-col gap-2 w-1/2">
              <label>Name</label>
              <input
                placeholder="Section Name"
                className="h-10 border-none rounded-lg bg-gray-100"
                {...field}
                type="text"
              />
            </div>
          )}
        </Field>

        <Field name="tier_id">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div className="flex flex-col gap-2 w-1/2">
              <label>Tier</label>
              <select
                className="h-10 border-none rounded-lg bg-gray-100"
                {...field}
              >
                <option value="">Select Tier</option>
                {tiers?.map((tier) => (
                  <option key={tier.id} value={tier.id}>
                    {tier.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </Field>
      </div>

      <div className="flex justify-between gap-2">
        <Field name="date_available_start">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div className="flex flex-col gap-2 w-1/2">
              <label>Available Start</label>
              <DatePicker
                placeholderText="Select Date"
                selected={
                  formik.values.date_available_start
                    ? new Date(formik.values.date_available_start)
                    : null
                }
                onChange={(date: Date | null) =>
                  formik.setFieldValue("date_available_start", date)
                }
                className="focus:ring-0 focus:outline-blue w-full cursor-pointer text-gray-500 bg-gray-100 h-10 px-3 rounded-lg border-none"
              />
            </div>
          )}
        </Field>

        <Field name="date_available_end">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div className="flex flex-col gap-2 w-1/2">
              <label>Available End</label>
              <DatePicker
                placeholderText="Select Date"
                selected={
                  formik.values.date_available_end
                    ? new Date(formik.values.date_available_end)
                    : null
                }
                onChange={(date: Date | null) =>
                  formik.setFieldValue("date_available_end", date)
                }
                className="focus:ring-0 focus:outline-blue w-full cursor-pointer text-gray-500 bg-gray-100 h-10 px-3 rounded-lg border-none"
              />
            </div>
          )}
        </Field>
      </div>

      <div className="flex justify-between gap-2">
        <Field name="ticket-color">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div className="flex flex-col gap-2 w-1/2">
              <label className="flex gap-2 items-center">
                Choose Ticket Color{" "}
                <TicketIcon
                  className="w-6 h-6"
                  style={{ color: formik.values.ticket_color }}
                />
              </label>
              <CirclePicker
                color={formik.values.ticket_color}
                onChange={(color) => {
                  formik.setFieldValue("ticket_color", color.hex);
                }}
              />
            </div>
          )}
        </Field>
        <div className="w-1/2">
          <Field name="visible">
            {({ field }: { field: FieldInputProps<string> }) => (
              <div className="flex flex-col gap-2 w-full">
                <label>Visible</label>
                <select
                  className="h-10 border-none rounded-lg bg-gray-100"
                  value={field.value}
                  onChange={(e) =>
                    formik.setFieldValue(field.name, e.target.value === "true")
                  }
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            )}
          </Field>

          <Field name="create_scan_after_pos_checkout">
            {({ field }: { field: FieldInputProps<string> }) => (
              <div className="flex flex-col gap-2 w-full mt-4">
                <label>Create Scan After POS Checkout?</label>
                <select
                  className="h-10 border-none rounded-lg bg-gray-100"
                  value={field.value}
                  onChange={(e) =>
                    formik.setFieldValue(field.name, e.target.value === "true")
                  }
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            )}
          </Field>
        </div>
      </div>

      <div>
        <Button variant="success" type="submit" className="w-full">
          Save Section
        </Button>
      </div>
    </Form>
  );
};
