import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";

export const HappyTooltip = ({
  children,
  content,
  anchor,
}: {
  children: React.ReactNode;
  content: string;
  anchor?: "top" | "bottom" | "left" | "right";
}) => {
  return (
    <Popover className="relative">
      <PopoverButton className={'focus-visible:outline-none focus-visible:shadow-none'}>{children}</PopoverButton>
      <PopoverPanel
        transition
        anchor={anchor || "bottom"}
        className="flex flex-col bg-white rounded border border-gray-200 p-1 text-xs transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
      >
        {content}
      </PopoverPanel>
    </Popover>
  );
};
