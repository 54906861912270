import numeral from 'numeral';

export function formatCentsToDollars(cents: number) {
  const dollars = cents / 100;
  return numeral(dollars).format('$0,0.00');
}

export const formatCentsToReadableString = (amountInCents: number) => {
  return numeral(amountInCents / 100).format('$0.00a');
}

export const formatIntToReadableString = (int: number) => {
  return numeral(int).format('0a');
}