import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../../providers/AuthContext";
import { makeApiRequest } from "../../../utils/api";
import { VendorTable } from "../../../components/reusable/VendorTable";
import {
  createColumnHelper,
  getCoreRowModel,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import { PartnerRequest } from "../../../types/PartnerRequest";
import { Resources } from "../../../types/Resources";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";

export const PartnerRequests = () => {
  const { user } = useAuth();
  const { data, refetch } = useQuery({
    queryKey: ["partnerRequests", user],
    queryFn: async () => {
      const response = await makeApiRequest({
        path: "/admin/partner_requests",
      });

      return response;
    },
    enabled: !!user,
  });

  const handleDelete = async (id: number) => {
    const response = await makeApiRequest({
      path: `/admin/partner_requests/${id}`,
      method: "DELETE",
    });

    if (response.status === 200) {
      refetch();
    }
  };

  const handleApprove = async (id: number) => {
    const response = await makeApiRequest({
      path: `/admin/partner_requests/${id}`,
      method: "PUT",
      params: {
        partner_request: {
          approved: true,
        },
      },
    });

    if (response.status === 200) {
      refetch();
    }
  };

  const partnerRequests = data?.data;
  const columnHelper = createColumnHelper<PartnerRequest>();
  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("email", {
      header: "Email",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("full_name", {
      header: "Full Name",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("phone_number", {
      header: "Phone Number",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (info) => {
        const status = info.getValue()
        if (status === "approved") {
          return <CheckCircleIcon className="w-6 h-6 text-green-500" />
        }

        return <ExclamationCircleIcon className="w-6 h-6 text-orange-300" />
      },
    }),
    columnHelper.accessor("note", {
      header: "Note",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "actions",
      cell: (info) => {
        return (
          <div className="flex">
            <Menu>
              <MenuButton>
                <Cog6ToothIcon className="w-4 h-6 text-gray-400" />
              </MenuButton>
              <MenuItems
                className="w-44 rounded bg-gray-800"
                anchor="bottom end"
              >
                <MenuItem>
                  <button
                    className="hover:bg-gray-700 text-white text-left w-full p-2"
                    onClick={() => handleApprove(info.row.original.id)}
                  >
                    Approve
                  </button>
                </MenuItem>

                <MenuItem>
                  <button
                    className="hover:bg-gray-700 text-white text-left w-full p-2"
                    onClick={() => handleDelete(info.row.original.id)}
                  >
                    Delete
                  </button>
                </MenuItem>
                {/* <MenuItem>Reject</MenuItem> */}
              </MenuItems>
            </Menu>
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: partnerRequests || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  }) as Table<Resources>;
  return (
    <div className="">
      <div className="">
        <div className="">
          <h1 className="">Partner Requestssss</h1>
        </div>

        <VendorTable table={table} />
      </div>
    </div>
  );
};
