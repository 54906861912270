import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { useVendor } from "../../../providers/VendorContext";
import { OrgUser, User } from "../../../types/User";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { Cog6ToothIcon, PlusIcon } from "@heroicons/react/20/solid";
import { Fragment, useState } from "react";
import { makeApiRequest } from "../../../utils/api";
import { format } from "date-fns";
import { useNotification } from "../../../providers/NotificationContext";
import { Button } from "../../../components/reusable/Button";
import { OrgUserModal } from "../../../components/reusable/OrgUserModal";
import { useQuery } from "@tanstack/react-query";

export const Scanners = () => {
  const [showAddScannerModal, setShowAddScannerModal] =
    useState<boolean>(false);
  const { currentOrganization, refetchOrganization } = useVendor();
  const { dispatch } = useNotification();
  const columnHelper = createColumnHelper<OrgUser>();
  const scannerLevels = [1, 10, 50];
  const { data, refetch, isFetching, isFetched } = useQuery({
    queryKey: ["org_users", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: "/vendor/org_users",
        params: {
          organization_id: currentOrganization?.id,
        },
      }).then((res) => {
        if (res.status === 200) {
          const orgUsers = res.data;
          const filtered = orgUsers.filter((u: OrgUser) =>
            scannerLevels.includes(u.level)
          );
          return filtered;
        }
      }),
    enabled: !!currentOrganization,
    retry: false,
  });
  const scanners = data;
  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("user", {
      header: "Name",
      cell: (info) => {
        const user: User | undefined = info.renderValue() as User | undefined;
        return (
          <div className="flex flex-col">
            <span>
              {user?.first_name} {user?.last_name}
            </span>
            <span className="text-xs text-gray-500">{user?.email}</span>
          </div>
        );
      },
    }),

    columnHelper.accessor("user", {
      header: "Phone",
      cell: (info) => {
        const user: User | undefined = info.renderValue() as User | undefined;
        return <span>{user?.phone_number}</span>;
      },
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        return (
          <div className="flex">
            <Menu as="div" className={"relative inline-block"}>
              <Menu.Button className="bg-transparent flex items-center justify-center">
                <Cog6ToothIcon className="h-6 text-gray-500" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="w-40 right-0 absolute bg-gray-100 border-gray-200 border z-10 rounded-md">
                  <Menu.Item
                    as={"div"}
                    className={"px-3 py-2 hover:bg-white/10"}
                  >
                    <Link
                      className="text-gray-500 block"
                      to={`/admin/users/${props.row.original.id}`}
                    >
                      View
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    as={"div"}
                    className={
                      "px-3 py-2 hover:bg-white/10 border-gray-200 border-t"
                    }
                  >
                    <span
                      role="button"
                      className="text-pink/60 block"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to remove this user from the organization?"
                          )
                        ) {
                          removeOrgUser({
                            id: props.row.original.id,
                          });
                        }
                      }}
                    >
                      Remove from Org
                    </span>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: scanners,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const removeOrgUser = ({ id }: { id: number }) => {
    makeApiRequest({
      path: `/vendor/org_users/${id}`,
      params: { organization_id: currentOrganization?.id },
      method: "DELETE",
    }).then((res) => {
      dispatch({
        type: "open",
        payload: {
          level: "success",
          message: "User removed from org successfully.",
        },
      });
      refetchOrganization();
    });
  };

  const handleSuccessfulAdd = () => {
    refetchOrganization();
    setShowAddScannerModal(false);
    dispatch({
      type: "open",
      payload: {
        level: "success",
        message: "Scanner added successfully.",
      },
    });
  };

  return (
    <>
      <VendorCard className="mt-5">
        <div className="flex justify-between items-center">
          <h3 className="font-bold text-gray-500 mb-3">Scanners</h3>
          <Button
            variant="blue"
            size="sm"
            onClick={() => setShowAddScannerModal(true)}
          >
            <PlusIcon className="w-4" />
            Add Scanner
          </Button>
        </div>
        {scanners?.length ? (
          <table className="min-w-full divide-y divide-gray-200 mt-5">
            <thead>
              {table.getHeaderGroups().map((headerGroup, i) => (
                <tr className="text-gray-500" key={`${headerGroup.id}-${i}`}>
                  {headerGroup.headers.map((header, i) => (
                    <th className="text-left" key={`${header.id}-${i}`}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="divide-y divide-gray-200">
              {table?.getRowModel()?.rows?.map((row, i) => {
                return (
                  <tr className="h-11" key={`${row.id}-${i}`}>
                    {row.getVisibleCells().map((cell, i) => {
                      return (
                        <td
                          className="py-1 text-gray-500"
                          key={`${cell.id}-${i}`}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <span className="block text-center">
            No Scanners Yet
          </span>
        )}
      </VendorCard>
      <OrgUserModal
        handleSuccessfulAdd={handleSuccessfulAdd}
        isOpen={showAddScannerModal}
        onRequestClose={() => setShowAddScannerModal(false)}
      />
    </>
  );
};
