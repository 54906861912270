/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { User } from "../types/User";
import { useQuery } from "@tanstack/react-query";
import { RequestProps, makeApiRequest } from "../utils/api";
import { getUser, setUser, clearUser } from "../utils/user";

// import { Spinner } from 'components/loaders/Spinner'
import { AxiosResponse } from "axios";
import { useNotification } from "./NotificationContext";
type LoginProps = {
  email: string;
  password: string;
};

type RegisterProps = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
};

interface AuthContextType {
  user: User | null;
  login: ({ email, password }: LoginProps) => Promise<AxiosResponse<any, any>>;
  logout: () => Promise<any>;
  register: ({
    email,
    password,
  }: RegisterProps) => Promise<AxiosResponse<any, any>>;
  setCurrentUser: React.Dispatch<React.SetStateAction<User | null>>;
}

const AuthContext = React.createContext<AuthContextType>({
  user: null,
  login: () => new Promise(() => {}),
  logout: async () => null,
  register: () => new Promise(() => {}),
  setCurrentUser: () => null,
});

function AuthProvider(props: any) {
  // code for pre-loading the user's information if we have their token in
  // localStorage goes here

  // 🚨 this is the important bit.
  // Normally your provider components render the context provider with a value.
  // But we post-pone rendering any of the children until after we've determined
  // whether or not we have a user token and if we do, then we render a spinner
  // while we go retrieve that user's information.
  const userCookie = getUser();
  const { dispatch } = useNotification();
  const [currentUser, setCurrentUser] = React.useState<User | null>();
  const {showError} = useNotification();
  const { refetch, isFetching, isError } = useQuery({
    queryKey: ["user"],
    queryFn: () =>
      makeApiRequest({ path: `/profile` }).catch((err) => {
        if (err.response.status === 401) {
          clearUser();
          setCurrentUser(null);
          showError("Your session has expired. Please log in again."); 
        }
        return err
      }),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

  React.useEffect(() => {
    if (isError) {
      dispatch({
        type: "open",
        payload: {
          level: "error",
          message: "There was an error logging in. Please try again.",
        },
      });
    }
  }, [isError, dispatch]);

  React.useEffect(() => {
    if (userCookie && !currentUser) {
      // make api request and set user value
      refetch().then((res) => {
        setCurrentUser(res.data?.data.user);
      });
    }
  }, []);

  if (isFetching || (userCookie && !currentUser)) {
    //  we want to check if the api is fetching or if there *SHOULD* be a user being fetched.
    // if we hardcode a null user before the app refreshes user from API, we will render the unauthenticated app
    // before rerendering the authenticated app, causing funny redirects.

    return (
      <div className="w-screen h-screen flex justify-center items-center">
        {/* <Spinner className="w-24 h-24" /> */}
      </div>
    );
  }

  const login = async ({ email, password }: LoginProps) => {
    const SignInArgs: RequestProps = {
      method: "POST",
      params: {
        user: {
          email,
          password,
        },
      },
      path: "/login",
    };

    // make a login request
    return makeApiRequest(SignInArgs)
      .then((res) => {
        const user = res.data.user;
        const token = res.data.jwt;
        const userData = {
          id: user.id,
          jwt: token,
        };
        setUser(userData);
        setCurrentUser(user);
      })
      .catch((err) => {
        console.log('THIS IS THE ERRR', err)
        dispatch({
          type: "open",
          payload: { message: err.response.data.message, level: "error" },
        });
      });
  };

  const register = async ({
    first_name,
    last_name,
    email,
    password,
  }: RegisterProps) => {
    const RegisterArgs: RequestProps = {
      method: "POST",
      params: {
        user: {
          first_name,
          last_name,
          email,
          password,
          password_confirmation: password,
        },
      },
      path: "/users",
    };

    // make a sign up request
    return makeApiRequest(RegisterArgs)
      .then((res) => {
        const user = res.data.user;
        const jwt = res.data.jwt;
        const userData = {
          id: user.id,
          jwt: jwt,
        };
        setUser(userData);
        setCurrentUser(user);
      })
      .catch((err) => console.log(err));
  }; // register the user
  const logout = async () => {
    const LogoutArgs: RequestProps = {
      method: "DELETE",
      path: "/logout",
    };

    return makeApiRequest(LogoutArgs)
      .then(() => {
        clearUser();
        setCurrentUser(null);
        return Promise.resolve(true);
      })
      .catch((err) => console.log(err));
  }; // clear the token in localStorage and the user data

  return (
    <AuthContext.Provider
      value={{ user: currentUser, login, logout, register, setCurrentUser }}
      {...props}
    />
  );
}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
