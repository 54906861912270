import { useState } from "react";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { HappyEvent } from "../../../types/Event";
import { EventMessage } from "../../../types/EventMessage";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import { Modal } from "../../../components/reusable/Modal";
import RichTextEditor from "../../../components/RichTextEditor";
import { Button } from "../../../components/reusable/Button";
import { PaperAirplaneIcon, PlusIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { makeApiRequest } from "../../../utils/api";
import { useAuth } from "../../../providers/AuthContext";
import { useVendor } from "../../../providers/VendorContext";
import { useNotification } from "../../../providers/NotificationContext";
import { useQuery } from "@tanstack/react-query";
import { VendorTable } from "../../../components/reusable/VendorTable";
import { Resources } from "../../../types/Resources";
import { set } from "lodash";

export const EventMessages = ({ event }: { event: HappyEvent }) => {
  const { currentOrganization } = useVendor();
  const { user } = useAuth();
  const { showError, showSuccess } = useNotification();
  const [showingModal, setShowingModal] = useState(false);
  const [modalContent, setModalContent] = useState<"new" | EventMessage>();
  const [messageContent, setMessageContent] = useState<string>("");
  const [messageSubject, setMessageSubject] = useState<string>("");
  // fetch messages
  const showNewMessageModal = () => {
    // show message modal
    setModalContent("new");
    setShowingModal(true);
  };

  const showViewMessageModal = (message: EventMessage) => {
    // show message modal
    setModalContent(message);
    setShowingModal(true);
  }

  const sendMessage = () => {
    if (!messageSubject) {
      showError("Subject is required");
      return;
    }
    makeApiRequest({
      path: `/vendor/messages`,
      method: "POST",
      params: {
        organization_id: currentOrganization?.id,
        message: {
          subject: messageSubject,
          body: messageContent,
          event_id: event.id,
          user_id: user?.id,
        },
      },
    }).then((res) => {
      console.log(res);
      if (res.status === 201) {
        showSuccess("Message sent successfully");
        setShowingModal(false);
      }
    });
  };

  const handleViewMessageContent = (message: EventMessage) => {
    setModalContent(message);
    setShowingModal(true);
  }

  const { data } = useQuery({
    queryKey: ["messages", event.id],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/messages`,
        params: {
          organization_id: currentOrganization?.id,
        },
      }),
    enabled: !!currentOrganization,
  });

  const messages: EventMessage[] = data?.data || []

  return (
    <>
      <VendorCard className="mt-5">
        <div className="flex justify-between items-center">
          <h2 className="font-bold">Messages</h2>
          <Button variant="blue" size='sm' onClick={showNewMessageModal}>
            <PlusIcon className="h-4 w-4" />
            New Message
          </Button>
        </div>
        {messages.length === 0 ? (
          <>
            <div className="w-ful flex items-center justify-center">
              <div className="max-w-lg py-5 px-16 flex flex-col gap-2 justify-center items-center">
                <div className="relative">
                  <EnvelopeIcon className="h-10 text-gray-300" />
                </div>
                <span className="text-xs font-bold">No Messages Yet</span>
              </div>
            </div>
          </>
        ) : (
          <MessageTable handleViewMessageContent={handleViewMessageContent} messages={messages} />
        )}
      </VendorCard>

      <Modal
        title={modalContent === "new" ? "New Message" : "Message Content"}
        isOpen={showingModal}
        onRequestClose={() => setShowingModal(false)}
      >
        {modalContent === "new" && (
          <div className="min-h-[400px] flex flex-col">

            <label className="font-bold mt-3">Subject</label>
            <input
              onChange={(e) => setMessageSubject(e.target.value)}
              type="text"
              className="border border-gray-300 mt-1"
            />
            <label className="font-bold mt-4">Message</label>
            <RichTextEditor onChange={(value) => setMessageContent(value)} />
            <Button
              onClick={sendMessage}
              className="btn btn-primary mt-16 flex justify-center gap-3 items-center"
              variant="success"
            >
              Send Message
              <PaperAirplaneIcon className="h-4 w-4 mt-0.5" />
            </Button>
          </div>
        )}

        {typeof modalContent === 'object' && (
          <div className="min-h-[400px] flex flex-col gap-3">
            <div className="flex justify-between items-center">
              <h2 className="font-bold">
                Subject: {modalContent.subject}
              </h2>
            </div>
            <div>
              <div dangerouslySetInnerHTML={{ __html: modalContent.body }} />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export const MessageTable = ({
  messages,
  handleViewMessageContent
}: {
  messages: EventMessage[] | [];
  handleViewMessageContent: (message: EventMessage) => void;
}) => {
  const columnHelper = createColumnHelper<EventMessage>();

  const columns = [
    columnHelper.accessor("subject", {
      header: "Subject",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("from_email", {
      header: "From",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        const eventMessage = props.row.original;
        return (
          <div className="flex gap-3 justify-end">
            <Button onClick={() => handleViewMessageContent(eventMessage)} size="sm" variant="purple">View Content</Button>
          </div>
        )
      }
    })

  ];

  const table = useReactTable({
    data: messages,
    columns,
    getCoreRowModel: getCoreRowModel(),
  }) as Table<Resources>;

  return (
    <div>
      <VendorTable table={table} />
    </div>
  );
};
