import { VendorCard } from "../../../components/reusable/VendorCard";
import { Order } from "../../../types/Order";
import { OrdersTable } from "./EventDetails";

export const Orders = ({ orders }: { orders: Order[] }) => {
  return (
    <VendorCard className="mt-5">
      <div className="flex justify-between items-center">
        <h3 className="font-bold text-gray-500">Event Orders</h3>
      </div>
      <OrdersTable orders={orders} />
    </VendorCard>
  );
};
