import { useQuery } from "@tanstack/react-query";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { makeApiRequest } from "../../../utils/api";
import { useVendor } from "../../../providers/VendorContext";
import { Spinner } from "../../../components/reusable/Spinner";
import { Button } from "../../../components/reusable/Button";
import { BuildingStorefrontIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { ProductModal } from "./ProductModal";
import { ProductList } from "./ProductList";
import { ProductCategoryList } from "./ProductCategoryList";
import { Product, ProductCategory } from "../../../types/Product";

export type ShowModalType = "product" | "productCategory" | null;

export const Products = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ShowModalType>(null);
  const [editingProduct, setEditingProduct] = useState<Product | null>(null);
  const [editingProductCategory, setEditingProductCategory] =
    useState<ProductCategory | null>(null);
  const { currentOrganization } = useVendor();
  const {
    data: productCategoryData,
    isLoading: productCategoriesLoading,
    refetch: refetchProductCategories,
  } = useQuery({
    queryKey: ["product_categories"],
    queryFn: () =>
      makeApiRequest({
        path: "/vendor/product_categories",
        params: {
          organization_id: currentOrganization?.id,
        },
      }),
    enabled: !!currentOrganization,
  });

  const productCategories = productCategoryData?.data;

  const {
    data: productData,
    isLoading: productsLoading,
    refetch: refetchProducts,
  } = useQuery({
    queryKey: ["products"],
    queryFn: () =>
      makeApiRequest({
        path: "/vendor/products",
        params: {
          organization_id: currentOrganization?.id,
        },
      }),
    enabled: !!currentOrganization,
  });
  const products = productData?.data;

  const onEditProduct = (product: Product) => {
    setEditingProduct(product);
    setModalContent("product");
    setShowModal(true);
  };

  const onEditProductCategory = (productCategory: ProductCategory) => {
    setEditingProductCategory(productCategory);
    setModalContent("productCategory");
    setShowModal(true);
  };

  return (
    <>
      <h1 className="text-2xl font-semibold leading-6 text-gray-500 flex items-center gap-3">
        <BuildingStorefrontIcon className="h-7 w-7" />
        Products
      </h1>
      <div className="flex flex-col md:flex-row items-start gap-5 mt-5">
        <VendorCard className="w-full md:w-1/3">
          <div className="flex justify-between h-10">
            <span className="text-lg font-bold">Categories</span>
            <Button
              onClick={() => {
                setEditingProductCategory(null);
                setModalContent("productCategory");
                setShowModal(true);
              }}
              variant="blue"
              size="sm"
            >
              <PlusIcon className="h-5 w-5" />
              Add Category
            </Button>
          </div>
          {productCategoriesLoading ? (
            <Spinner />
          ) : (
            <>
              <ProductCategoryList
                onEditProductCategory={onEditProductCategory}
                refetchProductCategories={refetchProductCategories}
                productCategories={productCategories}
              />
            </>
          )}
        </VendorCard>

        <VendorCard className="w-full md:w-2/3">
          <div className="flex justify-between h-10">
            <span className="text-lg font-bold">Products</span>
            <Button
              onClick={() => {
                setEditingProduct(null);
                setModalContent("product");
                setShowModal(true);
              }}
              variant="blue"
              size="sm"
            >
              <PlusIcon className="h-5 w-5" />
              Add Product
            </Button>
          </div>
          {productsLoading ? (
            <Spinner />
          ) : (
            <>
              <ProductList
                onEditProduct={onEditProduct}
                refetchProducts={refetchProducts}
                products={products}
              />
            </>
          )}
        </VendorCard>
      </div>
      <ProductModal
        editingProduct={editingProduct}
        editingProductCategory={editingProductCategory}
        modalContent={modalContent}
        refetchProducts={refetchProducts}
        refetchProductCategories={refetchProductCategories}
        onRequestClose={() => setShowModal(false)}
        showModal={showModal}
        productCategories={productCategories}
      />
    </>
  );
};
