import { useEffect, useState } from "react";
import { useVendor } from "../../../providers/VendorContext";
import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../../utils/api";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { Line } from "react-chartjs-2";
import { Button } from "../../../components/reusable/Button";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { ChartData, ChartOptions, TooltipItem, ChartDataset } from "chart.js";
import { saveAs } from "file-saver";


// Interfaces for the data structure
interface GroupData {
  group_name: string;
  total_revenue_cents: number;
  quantity_sold: number;
}

interface EventData {
  event_id: number;
  event_name: string;
  group_data: { [group_id: number]: GroupData };
}

export const SeasonPassReport = () => {
  const { currentOrganization } = useVendor();
  const [searchParams, setSearchParams] = useState({
    fromDate: "",
    toDate: "",
  });
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [chartMetric, setChartMetric] = useState<"quantity_sold" | "total_revenue_cents">("quantity_sold");

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["season-pass-report", currentOrganization, searchParams],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/organizations/${currentOrganization?.id}/season_pass_report`,
        params:
          searchParams.fromDate || searchParams.toDate
            ? {
                search: {
                  from_date: searchParams.fromDate,
                  to_date: searchParams.toDate,
                },
              }
            : {},
      }),
    enabled: !!currentOrganization,
  });

  const handleSearch = () => {
    setSearchParams({ fromDate, toDate });
  };

  const clearSearch = () => {
    setSearchParams({ fromDate: "", toDate: "" });
    setFromDate("");
    setToDate("");
  };

  const toggleMetric = () => {
    setChartMetric((prevMetric) =>
      prevMetric === "quantity_sold" ? "total_revenue_cents" : "quantity_sold"
    );
  };

  useEffect(() => {
  }, [searchParams, refetch]);

  const downloadSeasonPassReport = () => {
    makeApiRequest({
      path: `/vendor/organizations/${currentOrganization?.id}/season_pass_report.csv`,
      params: {
        organization_id: currentOrganization?.id,
        params:
          searchParams.fromDate || searchParams.toDate
            ? {
                search: {
                  from_date: searchParams.fromDate,
                  to_date: searchParams.toDate,
                },
              }
            : {},
      },
    }).then((res) => {
      if (res.status === 200) {
        const CSV = res.data;
        const blob = new Blob([CSV], { type: "text/csv" });
        const filename = `sales-by-product-${currentOrganization?.name}.csv`;
        saveAs(blob, filename);
      }
    });
  };

  const totalValue =
    chartMetric === "quantity_sold"
      ? data?.data.quantity_sold_total
      : data?.data.total_revenue_cents / 100;

  const chartData: ChartData<"line"> = data
    ? transformDataForChart(data.data, chartMetric)
    : { labels: [], datasets: [] };

  const chartOptions: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: TooltipItem<"line">) {
            const value = tooltipItem.raw as number;
            return chartMetric === "quantity_sold"
              ? `Quantity: ${value}`
              : `Revenue: $${value.toFixed(2)}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: false,
        },
      },
      y: {
        min: 0, // Prevent negative numbers on the y-axis
        title: {
          display: true,
          text: chartMetric === "quantity_sold" ? "Quantity Sold" : "Revenue (in dollars)",
        },
        ticks: {
          callback: function (value: string | number) {
            if (typeof value === "number") {
              return chartMetric === "quantity_sold"
                ? Math.floor(value)
                : `$${value}`;
            }
            return value;
          },
          stepSize: chartMetric === "quantity_sold" ? 1 : undefined, // Ensure increments of 1 for quantity
        },
      },
    },
  };

  return (
    <div className="mt-3">
      <VendorCard className="mt-3 pb-10 mb-5">
        
        <div className="flex items-end relative">
            { (fromDate || toDate) && (
            <span
                className="absolute text-sm text-pink top-full mt-2 z-10"
                onClick={clearSearch}
                role="button"
            >
                Clear Search
            </span>
            )}
            <div className="flex flex-col">
            <label htmlFor="fromDate" className="mr-2">
                From
            </label>
            <input
                type="date"
                id="fromDate"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                className="mr-4 rounded border-gray-400 h-10"
            />
            </div>
            <div className="flex flex-col">
            <label htmlFor="toDate" className="mr-2">
                To
            </label>
            <input
                type="date"
                id="toDate"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                className="mr-4 rounded border-gray-400 h-10"
            />
            </div>

            <Button onClick={handleSearch} variant="purple">
            Search
            </Button>
        </div>
      </VendorCard>

      {isLoading ? (
        <VendorCard className="mt-3">
          <span>Loading...</span>
        </VendorCard>
      ) : (
        <>
          <VendorCard>
            <div className="flex justify-between">
              <h2 className="text-lg font-bold pb-5">Season Pass Report</h2>
              <h2 className="text-lg font-bold pb-5 text-green-500">
                Total: {chartMetric === "quantity_sold" ? totalValue : `$${totalValue?.toFixed(2)}`}
              </h2>
            </div>
            <div className="w-full h-96 mx-auto">
              <Line data={chartData} options={chartOptions} />
            </div>
            <Button onClick={toggleMetric} variant="default" className="mt-5">
              Toggle to {chartMetric === "quantity_sold" ? "Revenue" : "Quantity"}
            </Button>
          </VendorCard>
          <VendorCard className="mt-3">
            <div className="flex">
              <Button
                onClick={downloadSeasonPassReport}
                size="sm"
                className="mb-2 ml-auto"
              >
                <ArrowDownTrayIcon className="w-5 h-5" />
                Download CSV
              </Button>
            </div>
            <SeasonPassReportTable eventData={data?.data.event_data} />
          </VendorCard>
        </>
      )}
    </div>
  );
};

const transformDataForChart = (data: { event_data: EventData[] }, metric: "quantity_sold" | "total_revenue_cents"): ChartData<"line"> => {
  const eventNames = data.event_data.map((event) => event.event_name);

  const groupNames = Array.from(
    new Set(
      data.event_data.flatMap((event) =>
        Object.values(event.group_data).map((group) => group.group_name)
      )
    )
  );

  const predefinedColors = [
    "#7209B7", // First color
    "#486BFF", // Second color
    "#F72685", // Third color
    "#E4C4FA", // Fourth color
    "#9966FF", // Fifth color
    "#FF9F40", // Sixth color
  ];

  const datasets: Partial<ChartDataset<"line">>[] = groupNames.map((groupName, index) => {
    const dataPoints = data.event_data.map((event) => {
      const group = Object.values(event.group_data).find(
        (group) => group.group_name === groupName
      );
      return group
        ? metric === "total_revenue_cents"
          ? group[metric] / 100
          : group[metric]
        : 0;
    });

    const color =
      index < predefinedColors.length
        ? predefinedColors[index]
        : generateUniqueColor(groupName);

    return {
      label: groupName,
      data: dataPoints,
      borderColor: color,
      fill: false,
    };
  });

  const totalDataPoints = data.event_data.map((event) => {
    return Object.values(event.group_data).reduce(
      (acc, group) =>
        acc +
        (metric === "total_revenue_cents" ? group[metric] / 100 : group[metric]),
      0
    );
  });

  datasets.push({
    label: "Total",
    data: totalDataPoints,
    borderColor: "#21C55D",
    fill: false,
    borderDash: [5, 5], // Makes the total line dashed
  });

  return { labels: eventNames, datasets: datasets as ChartDataset<"line">[] };
};

const generateUniqueColor = (groupName: string) => {
  const hash = groupName
    .split("")
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const hue = hash % 360;
  return `hsl(${hue}, 70%, 50%)`;
};

// SeasonPassReportTable Component
const SeasonPassReportTable = ({ eventData }: { eventData: EventData[] }) => {
  const groupNames = Array.from(
    new Set(
      eventData.flatMap((event) =>
        Object.values(event.group_data).map((group) => group.group_name)
      )
    )
  );

  const totalRevenuePerGroup = groupNames.map((groupName) =>
    eventData.reduce((acc, event) => {
      const group = Object.values(event.group_data).find((g) => g.group_name === groupName);
      return acc + (group ? group.total_revenue_cents : 0);
    }, 0)
  );

  const totalQuantityPerGroup = groupNames.map((groupName) =>
    eventData.reduce((acc, event) => {
      const group = Object.values(event.group_data).find((g) => g.group_name === groupName);
      return acc + (group ? group.quantity_sold : 0);
    }, 0)
  );

  const grandTotalRevenue =
    totalRevenuePerGroup.reduce((acc, revenue) => acc + revenue, 0) / 100;

  return (
    <table className="min-w-full divide-y divide-gray-200 text-sm">
      <thead>
        <tr className="text-left">
          <th>Event</th>
          {groupNames.map((groupName) => (
            <th key={groupName}>{groupName}</th>
          ))}
          <th>Total</th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {eventData.map((event) => {
          const totalRevenue =
            Object.values(event.group_data).reduce(
              (acc, group) => acc + group.total_revenue_cents,
              0
            ) / 100;
          return (
            <tr key={event.event_id} className="h-11 text-gray-400">
              <td className="py-1">{event.event_name}</td>
              {groupNames.map((groupName) => {
                const group = Object.values(event.group_data).find(
                  (group) => group.group_name === groupName
                );
                return (
                  <td key={groupName}>
                    {group
                      ? `$${(group.total_revenue_cents / 100).toFixed(2)} (${
                          group.quantity_sold
                        })`
                      : "$0.00 (0)"}
                  </td>
                );
              })}
              <td className="py-1">${totalRevenue.toFixed(2)}</td>
            </tr>
          );
        })}
        <tr className="h-11 text-gray-400 font-bold">
          <td className="py-1">Total</td>
          {groupNames.map((groupName, index) => (
            <td key={groupName}>
              ${((totalRevenuePerGroup[index] || 0) / 100).toFixed(2)} (
              {totalQuantityPerGroup[index] || 0})
            </td>
          ))}
          <td className="py-1">${grandTotalRevenue.toFixed(2)}</td>
        </tr>
      </tbody>
    </table>
  );
};
