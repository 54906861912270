import { Route, Routes } from "react-router-dom"
import { EventList } from "./EventList"
import { ShowEvent } from "./ShowEvent"
import { EventBuilder } from "./EventBuilder"
import { EventBuilderProvider } from "../../../providers/EventBuilderContext"

export const Events = () => {
  return (
    <Routes>
      <Route path="/" element={<EventList />} />
      <Route path="/builder/*" element={
        <EventBuilderProvider>
          <EventBuilder />
        </EventBuilderProvider>
      } />
      <Route path="/:id/*" element={<ShowEvent />} />
    </Routes>
  )
}