import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { makeApiRequest } from "../../../utils/api";
import { Ticket } from "../../../types/Ticket";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
  Table,
} from "@tanstack/react-table";
import { HappyEvent, PriceLevel, Section, Tier } from "../../../types/Event";
import { User } from "../../../types/User";
import { Menu, Transition } from "@headlessui/react";
import { TicketIcon } from "@heroicons/react/24/solid";
import { Fragment, useState } from "react";
import { Button } from "../../../components/reusable/Button";
import { saveAs } from "file-saver";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { Modal } from "../../../components/reusable/Modal";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Spinner } from "../../../components/reusable/Spinner";
import { useVendor } from "../../../providers/VendorContext";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { PlusIcon, ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { Resources } from "../../../types/Resources";
export const Tickets = ({ event }: { event: HappyEvent }) => {
  const { id: eventId } = useParams();
  const { currentOrganization } = useVendor();
  const params = useParams<{ id: string }>();
  const [showAddTicketsModal, setShowAddTicketsModal] =
    useState<boolean>(false);
  const [numberOfTickets, setNumberOfTickets] = useState<number>(0);
  const [selectedSection, setSelectedSection] = useState<Section | null>(null);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [action, setAction] = useState<"add" | "remove">();
  const [loading, setLoading] = useState<boolean>(false);
  const [ticketId, setTicketId] = useState<string>("");
  const [saleStatus, setSaleStatus] = useState<string>("all");
  const [userName, setUserName] = useState<string>("");
  const [priceLevelId, setPriceLevelId] = useState<string>("");
  const [tierId, setTierId] = useState<string>("");
  const [sectionId, setSectionId] = useState<string>("");

  const [searchParams, setSearchParams] = useState({
    ticket_id: "",
    sale_status: "all",
    user_name: "",
    price_level_id: "",
    tier_id: "",
    section_id: "",
  });

  const { data: sectionData, refetch: refetchSections } = useQuery({
    queryKey: ["sections", params.id, currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: "/vendor/sections",
        params: {
          event_id: params.id,
          organization_id: currentOrganization?.id,
        },
      });
    },
    enabled: !!params.id && !!currentOrganization,
    retry: false,
  });

  const sections = sectionData?.data;

  const {
    data: ticketData,
    refetch: refetchTickets,
    isFetching,
  } = useQuery({
    queryKey: ["tickets", params.id, page, searchParams],
    queryFn: () => {
      return makeApiRequest({
        path: "/vendor/tickets",
        params: {
          event_id: params.id,
          page,
          per_page: 50,
          organization_id: currentOrganization?.id,
          search: {
            ticket_id: searchParams.ticket_id,
            sale_status: searchParams.sale_status !== "all" ? searchParams.sale_status : undefined,
            user_name: searchParams.user_name,
            price_level_id: searchParams.price_level_id,
            tier_id: searchParams.tier_id,
            section_id: searchParams.section_id,
          },
        },
      });
    },
    enabled: !!params.id && !!currentOrganization && !!searchParams,
    retry: false,
  });

  const tickets = ticketData?.data.tickets;
  const pagination = ticketData?.data.pagination;

  const handlePageChange = ({ direction }: { direction: "next" | "prev" }) => {
    if (!pagination) return;
    const nextPage =
      direction === "next" ? pagination.next_page : pagination.prev_page;
    setPage(nextPage);
  };

  const openAddTicketModal = (action: "add" | "remove") => {
    setShowAddTicketsModal(true);
    setAction(action);
  };
  const closeModal = () => {
    setShowAddTicketsModal(false);
    refetchTickets();
    refetchSections();
    setShowSuccess(false);
    setNumberOfTickets(0);
    setSelectedSection(null);
  };

  const submitRequest = () => {
    setLoading(true);
    if (!selectedSection || !numberOfTickets) {
      setError("Please select a section and number of tickets");
      setLoading(false);
      return;
    }
    const payload = {
      organization_id: currentOrganization?.id,
      vendor_id: event.organization_id,
      section_id: selectedSection?.id,
      number_of_tickets: numberOfTickets,
      event_id: event.id,
      service_action: action,
    };

    makeApiRequest({
      path: `/vendor/tickets/add`,
      method: "POST",
      params: payload,
    })
      .then((res) => {
        if (res.status === 200) {
          setError("");
          setShowSuccess(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadAttendeeList = () => {
    makeApiRequest({
      path: `/vendor/events/${eventId}/event_ticket_list.csv`,
      params: {
        organization_id: currentOrganization?.id,
      },
    }).then((res) => {
      if (res.status === 200) {
        const CSV = res.data;
        const blob = new Blob([CSV], { type: "text/csv" });
        const filename = `attendees-event-${eventId}.csv`;
        saveAs(blob, filename);
      }
    });
  };

  const downloadCSV = () => {
    makeApiRequest({
      path: `/vendor/tickets.csv`,
      params: {
        organization_id: currentOrganization?.id,
        event_id: eventId,
      },
    }).then((res) => {
      if (res.status === 200) {
        const CSV = res.data;
        const blob = new Blob([CSV], { type: "text/csv" });
        const filename = `tickets-event-${eventId}.csv`;
        saveAs(blob, filename);
      }
    });
  };

  const handleSearch = () => {
    setSearchParams({
      ticket_id: ticketId,
      sale_status: saleStatus,
      user_name: userName,
      price_level_id: priceLevelId,
      tier_id: tierId,
      section_id: sectionId,
    });
  };

  const clearSearch = () => {
    setTicketId("");
    setSaleStatus("all");
    setUserName("");
    setPriceLevelId("");
    setTierId("");
    setSectionId("");
    setSearchParams({
      ticket_id: "",
      sale_status: "all",
      user_name: "",
      price_level_id: "",
      tier_id: "",
      section_id: "",
    });
    refetchTickets();
  };

  if (isFetching) {
    return (
      <div>
        <VendorCard className="mt-5">
          <div className="flex justify-center gap-3">
            <Spinner />
            <span>Fetching Tickets</span>
          </div>
        </VendorCard>
      </div>
    );
  }
  return (
    <div>
      <VendorCard className="mt-3">
        <div className="p-1">
          <div className="flex flex-col">
            <div className="flex gap-4 items-end flex-wrap">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Ticket ID"
                  value={ticketId}
                  style={{ width: "140px" }}
                  onChange={(e) => setTicketId(e.target.value)}
                  className="bg-white text-gray-500 placeholder:text-gray-400 border-gray-300 rounded-lg px-3 py-2"
                  onKeyDown={(e) => e.key === 'Enter' && handleSearch()} 
                />
                {searchParams.ticket_id ||
                searchParams.sale_status !== "all" ||
                searchParams.user_name ||
                searchParams.price_level_id ||
                searchParams.tier_id ||
                searchParams.section_id ? (
                  <span
                    onClick={clearSearch}
                    className="absolute left-0 text-red-500 cursor-pointer mt-10 text-sm"
                  >
                    Clear Search
                  </span>
                ) : null}
              </div>
              <select
                value={saleStatus}
                onChange={(e) => setSaleStatus(e.target.value)}
                className="bg-white text-gray-500 placeholder:text-gray-400 border-gray-300 rounded-lg px-3 py-2"
                onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
              >
                <option value="all">All</option>
                <option value="sold">Sold</option>
                <option value="available">Available</option>
              </select>
              <input
                type="text"
                placeholder="User Name"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                className="bg-white text-gray-500 placeholder:text-gray-400 border-gray-300 rounded-lg px-3 py-2"
                onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
              />
              <select
                value={tierId}
                onChange={(e) => setTierId(e.target.value)}
                className="bg-white text-gray-500 placeholder:text-gray-400 border-gray-300 rounded-lg px-3 py-2"
                onKeyDown={(e) => e.key === 'Enter' && handleSearch()} 
              >
                <option value="">Tier</option>
                {event?.tiers?.map((tier: Tier) => (
                  <option key={tier.id} value={tier.id}>
                    {tier.name}
                  </option>
                ))}
              </select>
              <select
                value={sectionId}
                onChange={(e) => setSectionId(e.target.value)}
                className="bg-white text-gray-500 placeholder:text-gray-400 border-gray-300 rounded-lg px-3 py-2"
                onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
              >
                <option value="">Section</option>
                {event?.sections?.map((section: Section) => (
                  <option key={section.id} value={section.id}>
                    {section.name}
                  </option>
                ))}
              </select>
              <select
                value={priceLevelId}
                onChange={(e) => setPriceLevelId(e.target.value)}
                className="bg-white text-gray-500 placeholder:text-gray-400 border-gray-300 rounded-lg py-2"
                style={{ width: "140px" }}
                onKeyDown={(e) => e.key === 'Enter' && handleSearch()} 
              >
                <option value="">Price Level</option>
                {event?.price_levels?.map((priceLevel: PriceLevel) => (
                  <option key={priceLevel.id} value={priceLevel.id}>
                    {priceLevel.name}
                  </option>
                ))}
              </select>
              <Button onClick={handleSearch} variant="purple" className="px-4">
                Search
              </Button>
            </div>
          </div>
        </div>
      </VendorCard>

      <VendorCard className="mt-3">
        {tickets?.length > 0 ? (
          <>
            <div className="flex justify-between items-center">
              <h1 className="text-gray-500 font-bold my-3 flex gap-2">
                Tickets
                <span className="text-gray-400 text-xs pt-1">
                  {`(${pagination?.start_range} - ${pagination?.end_range} of ${pagination?.total_count} total tickets)`}
                </span>
              </h1>

              <div className="relative mb-2">
                <Menu>
                  <Menu.Button className="flex items-center gap-1 bg-blue text-white px-3 py-1 rounded-md">
                    Actions
                    <ChevronDownIcon className="h-5 w-5" />
                  </Menu.Button>
                  <Menu.Items className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-md py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? 'bg-gray-100' : ''
                          } flex w-full px-4 py-2 text-sm text-gray-700`}
                          onClick={() => openAddTicketModal("add")}
                        >
                          <PlusIcon className="h-4 mr-2" />
                          Add Tickets
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? 'bg-gray-100' : ''
                          } flex w-full px-4 py-2 text-sm text-gray-700`}
                          onClick={() => openAddTicketModal("remove")}
                        >
                          <TrashIcon className="h-4 mr-2" />
                          Remove Tickets
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? 'bg-gray-100' : ''
                          } flex w-full px-4 py-2 text-sm text-gray-700`}
                          onClick={downloadCSV}
                        >
                          <ArrowDownTrayIcon className="h-4 mr-2" />
                          Download CSV
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? 'bg-gray-100' : ''
                          } flex w-full px-4 py-2 text-sm text-gray-700`}
                          onClick={downloadAttendeeList}
                        >
                          <ArrowDownTrayIcon className="h-4 mr-2" />
            

                          Download Attendees
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
              </div>
            </div>

            <TicketTable tickets={tickets} />

            <div className="flex justify-between items-center mt-4">
              <span className="flex items-center gap-2">
                <ChevronDoubleLeftIcon
                  className={`h-5 text-gray-400 cursor-pointer ${
                    pagination?.current_page === 1 ? "opacity-50" : ""
                  }`}
                  onClick={() => handlePageChange({ direction: "prev" })}
                />
                <span className="text-sm">Page {pagination?.current_page}</span>
                <ChevronDoubleRightIcon
                  className={`h-5 text-gray-400 cursor-pointer ${
                    pagination?.current_page === pagination?.total_pages
                      ? "opacity-50"
                      : ""
                  }`}
                  onClick={() => handlePageChange({ direction: "next" })}
                />
              </span>
            </div>
          </>
        ) : (
          <div className="w-full flex items-center justify-center">
            <div className="max-w-lg py-5 px-16 flex flex-col gap-2 justify-center items-center">
              <div className="relative">
                <TicketIcon className="h-10 text-gray-300" />
                <PlusCircleIcon className="h-5 text-gray-500 absolute right-0 bottom-0" />
              </div>
              <span className="text-xs font-bold">No Tickets</span>
              <span className="text-xs"> Get started by adding tickets!</span>
              <Button
                onClick={() => openAddTicketModal("add")}
                variant="pink"
                className="flex items-center gap-1 mt-5"
              >
                <PlusIcon className="w-4" /> Add Tickets
              </Button>
            </div>
          </div>
        )}
      </VendorCard>
      <Modal
        title={`${action} Tickets`}
        onRequestClose={closeModal}
        isOpen={showAddTicketsModal}
      >
        <div className="flex flex-col items-center gap-4">
          {showSuccess ? (
            <div className="flex flex-col items-center">
              <h1 className="text-green-400 text-center text-xl font-bold">
                Success!
              </h1>
              <p className="text-gray-500 text-center py-4">
                You have successfully {action === "add" ? "added" : "removed"}{" "}
                {numberOfTickets} tickets to {selectedSection?.name}.
              </p>
              <Button variant="link" className="mx-auto" onClick={closeModal}>
                Close
              </Button>
            </div>
          ) : (
            <>
              <Menu as="div" className={"relative w-full"}>
                <Menu.Button
                  className={
                    "w-full flex justify-between rounded-lg bg-gray-100 border-none h-10 items-center px-4"
                  }
                >
                  {selectedSection ? (
                    <span>{`${selectedSection.tier.name} - ${selectedSection?.name} (${selectedSection?.ticket_count} tickets)`}</span>
                  ) : (
                    "Section"
                  )}
                  <ChevronDownIcon className="w-4" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="flex flex-col absolute right-0 z-50 mt-2.5 w-full origin-top-right rounded-md bg-gray-50 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    {sections?.map((section: Section) => (
                      <Menu.Item key={section.id}>
                        {({ focus }) => (
                          <button
                            onClick={() => setSelectedSection(section)}
                            className={`flex gap-2 w-full px-4 py-2 text-sm leading-5 text-left hover:bg-gray-100`}
                          >
                            {section.tier.name} - {section.name}
                            <span className="text-xs text-gray-400">{`(${section.ticket_count} tickets)`}</span>
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
              <input
                onChange={(e) => setNumberOfTickets(parseInt(e.target.value))}
                type="number"
                className="rounded-lg bg-gray-100 h-10 px-3 w-full border-none"
                placeholder="Number of tickets"
              />
              <Button disabled={loading} onClick={submitRequest} variant="blue" className="w-44">
                {loading ? (
                  <Spinner />
                ) : (
                  <span className="capitalize">{action} Tickets</span>
                )}
              </Button>
              <span className="text-sm text-red-500">{error}</span>
            </>
          )}
        </div>
      </Modal>

    </div>
  );
};

export const TicketTable = ({ tickets }: { tickets: Ticket[] }) => {
  const { currentOrganization } = useVendor();
  const columnHelper = createColumnHelper<Ticket>();
  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => (
        <Link
          className="text-lightPurple"
          to={`/vendor/organizations/${currentOrganization?.id}/tickets/${info.getValue()}`}
        >
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor("event", {
      header: "Event",
      cell: (info) => {
        const event: HappyEvent | undefined = info.renderValue() as
          | HappyEvent
          | undefined;
        return (
          <Link
            className="text-lightPurple hover:brightness-125"
            to={`/vendor/organizations/${currentOrganization?.id}/events/${event?.id}`}
          >
            {event?.name}
          </Link>
        );
      },
    }),
    columnHelper.accessor("tier", {
      header: "Tier",
      cell: (info) => {
        const tier: Tier | undefined = info.renderValue() as Tier | undefined;
        return tier?.name;
      },
    }),
    columnHelper.accessor("section", {
      header: "Section",
      cell: (info) => {
        const section: Section | undefined = info.renderValue() as
          | Section
          | undefined;
        return section?.name;
      },
    }),
    columnHelper.accessor("price_level", {
      header: "Price",
      cell: (info) => {
        const priceLevel: PriceLevel | undefined = info.renderValue() as
          | PriceLevel
          | undefined;
        if (priceLevel) {
          return (
            <span>${((priceLevel?.price_cents || 0) / 100).toFixed(2)}</span>
          );
        }
        return null;
      },
    }),
    columnHelper.accessor("sold_at", {
      header: "Sold At",
      cell: (info) => {
        const date = info.getValue();
        if (date) {
          return date;
        }
        return null;
      },
    }),
    columnHelper.accessor("user", {
      header: "Sold To",
      cell: (info) => {
        const user: User | undefined = info.renderValue() as User | undefined;
        return (
          <Link
            className="text-lightPurple hover:brightness-125"
            to={`/vendor/organizations/${currentOrganization?.id}/users/${user?.id}`}
          >
            {user?.first_name} {user?.last_name}
          </Link>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: tickets,
    columns,
    getCoreRowModel: getCoreRowModel(),
  }) as Table<Resources>;

  return (
    <>
      {tickets?.length ? (
        <table className="min-w-full divide-y divide-gray-200 mt-5">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr className="text-gray-500 text-sm" key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th className="text-left" key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y divide-gray-200">
            {table?.getRowModel()?.rows?.map((row) => {
              return (
                <tr className="h-11 text-sm" key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td className="py-1 text-gray-400" key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null}
    </>
  );
};
