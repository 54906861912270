import { flexRender, Table } from "@tanstack/react-table";
import { Resources } from "../../types/Resources";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../utils/styles";

export const VendorTable = ({ table, admin = false }: { table: Table<Resources>, admin?: boolean }) => {
  return (
    <table className={classNames(
      "min-w-full divide-y mt-5",
      admin ? "divide-borderAdmin" : "divide-gray-200"
      )}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr className="text-gray-500 text-sm" key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th className="text-left" key={header.id}>
                <div
                  {...{
                    className: header.column.getCanSort()
                      ? "cursor-pointer select-none"
                      : "",
                    onClick: header.column.getToggleSortingHandler(),
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  <span className="px-2">
                    {header.column.getIsSorted() === "asc" && (
                      <ArrowUpIcon className="h-3 w-3 inline" />
                    )}
                    {header.column.getIsSorted() === "desc" && (
                      <ArrowDownIcon className="h-3 w-3 inline" />
                    )}
                  </span>

                  {/* {{
                          asc: " 🔼",
                          desc: " 🔽",
                        }[header.column.getIsSorted() as string] ?? null} */}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className={admin ? "divide-y divide-borderAdmin" : "divide-y divide-gray-200"}>
        {table?.getRowModel()?.rows?.map((row) => {
          return (
            <tr className="h-11 text-sm" key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <td className="py-1 text-gray-400" key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

