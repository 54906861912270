import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { makeApiRequest } from "../../../utils/api";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Menu, Transition } from "@headlessui/react";
import {
  CheckIcon,
  Cog6ToothIcon,
  XMarkIcon,
  TicketIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/20/solid";
import { Fragment, useState } from "react";
import { HappyEvent, Tier } from "../../../types/Event";
import { format } from "date-fns";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { Modal } from "../../../components/reusable/Modal";
import { Field, FieldInputProps, Form, Formik, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import { CirclePicker } from "react-color";
import { Button } from "../../../components/reusable/Button";
import { useNotification } from "../../../providers/NotificationContext";
import { set } from "lodash";
import { useVendor } from "../../../providers/VendorContext";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { EyeIcon, EyeSlashIcon, PlusIcon } from "@heroicons/react/24/outline";
import {
  PlusCircleIcon,
  Square3Stack3DIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/solid";
type ModalFormState = {
  action: "create" | "edit";
  tier: Tier | null;
};
export const Tiers = ({ event }: { event: HappyEvent }) => {
  const { currentOrganization } = useVendor();
  const [tiers, setTiers] = useState<Tier[]>([]);
  const params = useParams();
  const columnHelper = createColumnHelper<Tier>();
  const [modalForm, setModalForm] = useState<ModalFormState | null>();
  // const [editingTier, setEditingTier] = useState<Tier | null>(null);
  const { dispatch } = useNotification();
  const { data: tierData, refetch: refetchTiers } = useQuery({
    queryKey: ["tiers", params.id, currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: "/vendor/tiers",
        params: {
          event_id: params.id,
          organization_id: currentOrganization?.id,
        },
      }).then((res) => {
        if (res.status === 200) {
          setTiers(res.data);
          return res.data;
        }
        return [];
      });
    },
    enabled: !!params.id && !!currentOrganization?.id,
    retry: false,
  });

  const deleteTier = (id: number) => {
    makeApiRequest({
      path: `/vendor/tiers/${id}`,
      params: { organization_id: currentOrganization?.id },
      method: "DELETE",
    }).then((res) => {
      if (res.status === 200) {
        refetchTiers();
        dispatch({
          type: "open",
          payload: {
            level: "success",
            message: "Successfully deleted tier.",
          },
        });
      }
    });
  };

  const handleToggleVisibility = (tierId: number, visible: boolean) => {
    makeApiRequest({
      path: `/vendor/tiers/${tierId}`,
      method: "PUT",
      params: {
        organization_id: currentOrganization?.id,
        tier: { visible },
      },
    }).then((res) => {
      if (res.status === 200) {
        refetchTiers();
      }
    });
  };
  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => {
        return info.getValue();
      },
    }),
    columnHelper.accessor("name", {
      header: "Name",
      cell: (info) => {
        return (
          <span
            className="text-lightPurple cursor-pointer"
            onClick={() =>
              setModalForm({
                tier: info.row.original as Tier,
                action: "edit",
              })
            }
          >
            {info.getValue()}
          </span>
        );
      },
    }),
    columnHelper.accessor("date_available_start", {
      header: "Available Starting",
      cell: (info) => {
        if (!info.getValue()) {
          return <span>N/A</span>;
        }
        return (
          <span>{format(new Date(info.getValue()) as Date, "MM/dd/yyyy")}</span>
        );
      },
    }),
    columnHelper.accessor("date_available_end", {
      header: "Available Until",
      cell: (info) => {
        if (!info.getValue()) {
          return <span>N/A</span>;
        }
        return (
          <span>{format(new Date(info.getValue()) as Date, "MM/dd/yyyy")}</span>
        );
      },
    }),
    columnHelper.accessor("ticket_color", {
      header: "Ticket Color",
      cell: (info) => {
        if (!info.getValue()) {
          return <span>N/A</span>;
        }
        return (
          <span
            style={{ backgroundColor: info.getValue() }}
            className="flex items-center justify-center p-3 w-5 h-5"
          />
        );
      },
    }),
    columnHelper.accessor("visible", {
      header: "Visible",
      cell: (info) => {
        return (
          <div>
            {info.getValue() ? (
              <div className="flex items-center gap-1">
                <CheckIcon className="h-5 w-5 text-green-500" />
                <button
                  onClick={() =>
                    handleToggleVisibility(info.row.original.id, false)
                  }
                >
                  <EyeSlashIcon className="h-5 w-5" />
                </button>
              </div>
            ) : (
              <div className="flex items-center gap-1">
                <XMarkIcon className="h-5 w-5 text-red-500" />
                <button
                  onClick={() =>
                    handleToggleVisibility(info.row.original.id, true)
                  }
                >
                  <EyeIcon className="h-5 w-5" />
                </button>
              </div>
            )}
          </div>
        );
      },
    }),

    columnHelper.accessor("ticket_count", {
      header: "Tickets",
      cell: (info) => {
        const allTickets = info.getValue();
        const availableTickets = info.row.original.available_ticket_count;
        let color = "text-green-400";
        const lessThanQuarter = availableTickets < allTickets / 4;
        const lessThanTenPercent = availableTickets < allTickets / 10;
        if (lessThanQuarter) {
          color = "text-orange-500";
        } else if (lessThanTenPercent) {
          color = "text-red-500";
        }
        return (
          <div className="flex items-center gap-1">
            <span className={color}>{availableTickets}</span>
            <span className="text-gray-400">/</span>
            <span>{allTickets}</span>
          </div>
        );
      },
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        return (
          <div className="flex">
            <Menu as="div" className={"relative inline-block"}>
              <Menu.Button className="bg-transparent flex items-center justify-center">
                <Cog6ToothIcon className="h-6 text-gray-300" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="w-40 right-0 absolute bg-white shadow-md border-gray-200 border z-10 rounded-md">
                  <Menu.Item
                    as={"div"}
                    className={"px-3 py-2 hover:bg-white/10 cursor-pointer "}
                    onClick={() =>
                      setModalForm({
                        tier: props.row.original as Tier,
                        action: "edit",
                      })
                    }
                  >
                    <button className="text-gray-500 block">Edit</button>
                  </Menu.Item>
                  <Menu.Item
                    as={"div"}
                    className={
                      "px-3 py-2 hover:bg-white/10 cursor-pointer border-gray-200 border-t"
                    }
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you want to delete this tier?"
                        )
                      ) {
                        deleteTier(props.row.original.id);
                      }
                    }}
                  >
                    <button className="text-pink/60 block">Delete</button>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        );
      },
    }),
  ];
  const table = useReactTable({
    data: tiers,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleSuccessfulUpdate = () => {
    dispatch({
      type: "open",
      payload: {
        level: "success",
        message: "Success!!",
      },
    });
    setModalForm(null);
    refetchTiers();
  };

  const handleDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const sourceTier = tiers[source.index];
    const newTiers = Array.from(tiers);
    newTiers.splice(source.index, 1);
    newTiers.splice(destination.index, 0, sourceTier);

    setTiers(newTiers);
    makeApiRequest({
      path: `/vendor/tiers/${sourceTier.id}`,
      method: "PUT",
      params: {
        organization_id: currentOrganization?.id,
        tier: { sort_order: destination.index },
      },
    });
  };

  return (
    <>
      <VendorCard className="mt-5">
        {tiers && tiers.length ? (
          <>
            <div className="flex justify-between items-center">
              <h3 className="font-bold text-gray-500">Tiers</h3>
              <Button
                onClick={() => {
                  setModalForm({ action: "create", tier: null });
                }}
                size="sm"
                variant="blue"
              >
                <PlusIcon className="w-4" />
                Add Tier
              </Button>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
              <table className="min-w-full divide-y divide-gray-200 mt-5">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr className="text-gray-500 text-sm" key={headerGroup.id}>
                      <th></th>
                      {headerGroup.headers.map((header) => (
                        <th className="text-left" key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <tbody
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="divide-y divide-gray-200"
                    >
                      {table?.getRowModel()?.rows?.map((row, index) => {
                        return (
                          <Draggable
                            key={index}
                            draggableId={`${index}`}
                            index={index}
                          >
                            {(provided) => (
                              <tr
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                                className="h-11 text-sm"
                                key={row.original.id}
                              >
                                <td>
                                  <div {...provided.dragHandleProps}>
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-300" />
                                  </div>
                                </td>
                                {row.getVisibleCells().map((cell) => {
                                  return (
                                    <td
                                      className="py-1 text-gray-400"
                                      key={cell.id}
                                    >
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </table>
            </DragDropContext>
          </>
        ) : (
          <div className="w-ful flex items-center justify-center">
            <div className="max-w-lg py-5 px-16 flex flex-col gap-2 justify-center items-center">
              <div className="relative">
                <Square3Stack3DIcon className="h-10 text-gray-300" />
                <PlusCircleIcon className="h-5 text-gray-500 absolute right-0 bottom-0" />
              </div>
              <span className="text-xs font-bold">No Tiers</span>
              <span className="text-xs"> Get started by adding a tier!</span>
              <Button
                onClick={() => {
                  setModalForm({ action: "create", tier: null });
                }}
                variant="pink"
                className="flex items-center gap-1 mt-5"
              >
                <PlusIcon className="w-4" /> Add Tier
              </Button>
            </div>
          </div>
        )}
      </VendorCard>

      <Modal
        title={modalForm?.tier ? "Editing Tier" : "Create a Tier"}
        onRequestClose={() => setModalForm(null)}
        isOpen={!!modalForm}
      >
        <>
          <TierForm
            onSuccessfulUpdate={handleSuccessfulUpdate}
            tier={modalForm?.tier}
            event={event}
          />
        </>
      </Modal>
    </>
  );
};

export const TierForm = ({
  tier,
  event,
  onSuccessfulUpdate,
}: {
  tier?: Tier | null;
  event: HappyEvent;
  onSuccessfulUpdate: () => void;
}) => {
  const { currentOrganization } = useVendor();
  const { showError } = useNotification();
  return (
    <Formik
      initialValues={
        {
          name: tier?.name ?? "",
          date_available_start: tier?.date_available_start ?? null,
          date_available_end: tier?.date_available_end ?? null,
          ticket_color: tier?.ticket_color ?? "",
          visible: tier?.visible ?? true,
          description: tier?.description ?? "",
        } as TierFormProps
      }
      onSubmit={(data) => {
        const payload = {
          ...data,
          visible: !!data.visible,
          event_id: event.id,
        };
        const apiPath = tier ? `/vendor/tiers/${tier?.id}` : "/vendor/tiers";
        makeApiRequest({
          path: apiPath,
          method: tier ? "PUT" : "POST",
          params: { tier: payload, organization_id: currentOrganization?.id },
        })
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              onSuccessfulUpdate();
            }
          })
          .catch((err) => {
            showError(err.response.data);
          });
      }}
    >
      <TierFormContent event={event} />
    </Formik>
  );
};
type TierFormProps = {
  name: string;
  date_available_start: Date | null;
  date_available_end: Date | null;
  ticket_color: string;
  visible: boolean;
  description: string;
};
export const TierFormContent = ({ event }: { event: HappyEvent }) => {
  const formik = useFormikContext<TierFormProps>();
  return (
    <Form className="flex flex-col gap-4 py-5">
      <div className="flex justify-between gap-2">
        <Field name="name">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div className="flex flex-col gap-2 w-1/2">
              <label>Tier Name</label>
              <input
                placeholder="Tier Name"
                className="h-10 border-none rounded-lg bg-gray-100"
                {...field}
                type="text"
              />
            </div>
          )}
        </Field>

        <Field name="visible">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div className="flex flex-col gap-2 w-1/2">
              <label>Visible</label>
              <select
                className="h-10 border-none rounded-lg bg-gray-100"
                {...field}
              >
                <option value={"yes"}>Yes</option>
                <option value={""}>No</option>
              </select>
            </div>
          )}
        </Field>
      </div>

      <div className="flex justify-between gap-2">
        <Field name="date_available_start">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div className="flex flex-col gap-2 w-1/2">
              <label>Available Start</label>
              <DatePicker
                placeholderText="Select Date"
                selected={
                  formik.values.date_available_start
                    ? new Date(formik.values.date_available_start)
                    : null
                }
                onChange={(date: Date | null) =>
                  formik.setFieldValue("date_available_start", date as Date)
                }
                className="focus:ring-0 focus:outline-blue w-full cursor-pointer text-gray-500 bg-gray-100 h-10 px-3 rounded-lg border-none"
              />
            </div>
          )}
        </Field>

        <Field name="date_available_end">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div className="flex flex-col gap-2 w-1/2">
              <label>Available End</label>
              <DatePicker
                placeholderText="Select Date"
                selected={
                  formik.values.date_available_end
                    ? new Date(formik.values.date_available_end)
                    : null
                }
                onChange={(date: Date | null) =>
                  formik.setFieldValue("date_available_end", date as Date)
                }
                className="focus:ring-0 focus:outline-blue w-full cursor-pointer text-gray-500 bg-gray-100 h-10 px-3 rounded-lg border-none"
              />
            </div>
          )}
        </Field>
      </div>

      <div className="w-full">
        <Field name="description">
          {({ field }: { field: FieldInputProps<string> }) => (
            <div className="flex flex-col gap-2">
              <label htmlFor="description">Description</label>
              <textarea
                rows={3}
                id="description"
                {...field}
                className="focus:ring-0 focus:outline-blue text-gray-500 bg-gray-100 px-3 rounded-lg border-none  w-full rounded-md py-2"
              />
            </div>
          )}
        </Field>
      </div>

      <div className="flex justify-between gap-2">
        <Field name="ticket-color">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div className="flex flex-col gap-2 w-1/2">
              <label className="flex gap-2 items-center">
                Choose Ticket Color{" "}
                <TicketIcon
                  className="w-6 h-6"
                  style={{ color: formik.values.ticket_color }}
                />
              </label>
              <CirclePicker
                color={formik.values.ticket_color}
                onChange={(color) => {
                  formik.setFieldValue("ticket_color", color.hex);
                }}
              />
            </div>
          )}
        </Field>
      </div>

      <div>
        <Button variant="success" type="submit" className="w-full">
          Save Tier
        </Button>
      </div>
    </Form>
  );
};
