import React, { InputHTMLAttributes } from 'react';
import { useEventBuilder } from "../../../providers/EventBuilderContext";

interface EventBuilderInputProps extends InputHTMLAttributes<HTMLInputElement> {
  payloadType: string;
}

export const TierWizardInput = ({payloadType, ...props}: EventBuilderInputProps) => {
  const { state, dispatch } = useEventBuilder();
  const { tierWizard } = state;
  let inputValue;

  if (payloadType === "newPriceLevelPrice") {
    inputValue = tierWizard.newPriceLevelPrice?.toFixed(2);
  }

  if (payloadType === "newPriceLevelName") {
    inputValue = tierWizard.newPriceLevelName;
  }

  if (payloadType === "newSectionName") {
    inputValue = tierWizard.newSectionName;
  }

  if (payloadType === "newTierName") {
    inputValue = tierWizard.newTierName;
  }

  return (
    <input
      {...props}
      value={inputValue}
      onChange={(e) => {
        dispatch({
          type: "updateTierWizard",
          payload: {
            [payloadType]: e.target.value,
          },
        });
      }}
    />
  )
}