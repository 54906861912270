import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { makeApiRequest } from "../../../../utils/api";
import { useVendor } from "../../../../providers/VendorContext";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import { useState } from "react";
import { Button } from "../../../../components/reusable/Button";
import { ArrowsRightLeftIcon } from "@heroicons/react/20/solid";
import { VendorCard } from "../../../../components/reusable/VendorCard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = (display: "revenue" | "count") => {
  return {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
        onClick: function (
          event: unknown,
          legendItem: unknown,
          legend: unknown
        ) {
          console.log(`You clicked on ${legend}`);
          // Perform your action here...
        },
      },
      title: {
        display: true,
        text: "Revenue By Price Level",
      },
    },
    scales: {
      y: {
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value: unknown) {
            if (display === "revenue") {
              return "$" + value;
            }
            return value as string;
          },
        },
      },
    },
  };
};

type DashboardDataObject = {
  price_level_id: number;
  price_level_name: string;
  event_id: number;
  event_name: string;
  count: number;
  revenue: number;
};

export const SalesByPriceLevel = () => {
  const { currentOrganization } = useVendor();
  const params = useParams();
  const [chartDisplay, setChartDisplay] = useState<"revenue" | "count">(
    "revenue"
  );

  const swapChartDisplay = () => {
    setChartDisplay(chartDisplay === "revenue" ? "count" : "revenue");
  };

  const { data } = useQuery({
    queryKey: ["event_sales_by_price_level", params, currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: `/vendor/events/${params.id}/sales_by_price_level`,
        params: { organization_id: currentOrganization?.id },
      });
    },
    enabled: !!params.id && !!currentOrganization,
  });
  const dashboardData: DashboardDataObject[] = data?.data;

  const labels = dashboardData
    ? dashboardData.map((item) => item.price_level_name)
    : [];
  const chartOptions = options(chartDisplay);
  const chartData = {
    labels,
    datasets: [
      {
        label: "Revenue",
        data: dashboardData
          ? dashboardData.map((item) =>
              chartDisplay === "revenue" ? item.revenue / 100 : item.count
            )
          : [],
        backgroundColor: "#4cc9f0",
      },
    ],
  };

  return (
    <>
      <VendorCard className="w-full mt-5">
        <div className="flex justify-end w-full">
          <Button className="flex gap-1" size="sm" onClick={swapChartDisplay}>
            <ArrowsRightLeftIcon className="w-4 h-4" />
            Show By {`${chartDisplay === "revenue" ? "Count" : "Revenue"}`}
          </Button>
        </div>
        <Bar data={chartData} options={chartOptions} />
      </VendorCard>
    </>
  );
};
