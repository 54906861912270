import { Route, Routes } from "react-router-dom"
import { EventList } from "./EventList"
import { NewEvent } from "./NewEvent"

export const Events = () => {
  return (
    <Routes>
      <Route path="/" element={<EventList />} />
      <Route path="/new" element={<NewEvent />} />
    </Routes>
  )
}