import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useVendor } from '../../../../providers/VendorContext';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { makeApiRequest } from '../../../../utils/api';
import { VendorCard } from '../../../../components/reusable/VendorCard';

ChartJS.register(ArcElement, Tooltip, Legend);


export const EventSellThrough = () => {
  const { currentOrganization } = useVendor();
  const params = useParams();
  const {data} = useQuery({
    queryKey: ["event_sales_by_tier", params, currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: `/vendor/events/${params.id}/event_sell_through`,
        params: { organization_id: currentOrganization?.id },
      });
    },
    enabled: !!params.id && !!currentOrganization,
  });

  const sellThroughData = data?.data;
  
  const pieData = {
    labels: ['Tickets Sold', 'Tickets Available'],
    datasets: [
      {
        label: '# of Tickets',
        data: [sellThroughData?.sold_tickets, sellThroughData?.available_tickets],
        backgroundColor: [
          '#f72585',
          '#4361ee',
        ]
      },
    ],
  };
  return (
    <div className='w-full flex justify-center gap-4 my-5 py-5'>
      <VendorCard className='w-1/2 p-24 pt-4'>
        <Pie data={pieData} />
      </VendorCard>
      <div className='flex flex-col w-1/2 gap-5'>
        <VendorCard className='w-full py-10 flex flex-col items-center justify-center grow'>
          <span className='text-3xl text-lightPurple font-bold'>
            {sellThroughData?.sold_tickets}
          </span>
          <span>
            Sold Tickets
          </span>
        </VendorCard>
        <VendorCard className='w-full py-10 flex flex-col items-center justify-center grow'>
          <span className='text-3xl text-lightPurple font-bold'>
            {sellThroughData?.available_tickets}
          </span>
          <span>
            Available Tickets
          </span>
        </VendorCard>
      </div>
    </div>
  )
}
