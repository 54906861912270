import { FormEvent, useState } from "react";
import { useAuth } from "../../providers/AuthContext";
import { Button } from "../../components/reusable/Button";
import HappyNotification from "../../components/reusable/Notification";
import { Spinner } from "../../components/reusable/Spinner";
import { TextInput } from "../../components/reusable/Form";

export default function Login() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { login } = useAuth();
  const handleLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    login({ email, password }).finally(() => {
      setLoading(false);
    });
  };
  return (
    <>
      <HappyNotification />
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src="//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-colored.svg"
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-500">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleLogin}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-500"
              >
                Email address
              </label>
              <div>
                <TextInput
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email Address"
                  id="email"
                  type="email"
                  autoComplete="email"
                  required
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-500"
                >
                  Password
                </label>
                <div className="text-sm">
                  <a
                    href={`${import.meta.env.VITE_CONSUMER_WEB_URL}/forgot-password`}
                    className="font-semibold text-yellow hover:brightness-90"
                  >
                    Forgot password?
                  </a>
                </div>
              </div>
              <div>
                <TextInput
                  name="password"
                  placeholder="Password"
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  autoComplete="current-password"
                  required
                />
              </div>
            </div>

            <div>
              <Button type="submit" className="w-44" variant="pink">
                {loading ? <Spinner /> : <span>Sign in</span>}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
