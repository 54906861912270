import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ProductCategory } from "../../../types/Product";
import { renderIcon } from "../../../utils/products";
import { makeApiRequest } from "../../../utils/api";
import { useVendor } from "../../../providers/VendorContext";

export const ProductCategoryList = ({
  productCategories,
  refetchProductCategories,
  onEditProductCategory,
}: {
  productCategories: ProductCategory[];
  refetchProductCategories: () => void;
  onEditProductCategory: (productCategory: ProductCategory) => void;
}) => {
  const { currentOrganization } = useVendor();

  const deleteProductCategory = (id: number) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      makeApiRequest({
        path: `/vendor/product_categories/${id}`,
        method: "DELETE",
        params: {
          organization_id: currentOrganization?.id,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            refetchProductCategories();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  if (!productCategories) return null;

  if (productCategories.length === 0) {
    return <div className="font-medium text-gray-600 text-center mt-4">No categories yet</div>;
  }

  return (
    <div className="space-y-4">
      {productCategories.map((category) => (
        <div key={category.id} className="bg-white border border-gray-200 shadow-sm rounded-lg p-4 flex justify-between items-center">
          <div className="flex items-center gap-2">
            {renderIcon(category.icon)}
            <span className="text-lg font-semibold">{category.name}</span>

            <span className="bg-pink/10 text-pink font-bold px-3 text-sm rounded-full flex items-center justify-center">
              {category.product_count}
            </span>
          </div>
          <div className="flex items-center gap-3">
            <button
              onClick={() => onEditProductCategory(category)}
              className="text-blue-500 hover:text-blue-700"
              title="Edit"
            >
              <PencilIcon className="w-5 h-5" />
            </button>
            <button
              onClick={() => deleteProductCategory(category.id)}
              className="text-red-500 hover:text-red-700"
              title="Delete"
            >
              <TrashIcon className="w-5 h-5" />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};
