import axios, {AxiosRequestConfig} from "axios";
import { makeAuthHeader } from "./auth";
// import { useAlertBanner } from "providers/alerts/AlertBannerProvider";

export type ApiError = {
  message: string
}

export interface RequestProps {
  method?: "GET" | "POST" | "PATCH" | "PUT" | "DELETE";
  path: string;
  params?: unknown;
  hasFormData?: boolean
  responseType?: 'json' | 'blob'
}

const baseUrl = import.meta.env.VITE_API_URL

export const makeApiRequest = async ({
  method = "GET",
  params,
  path,
  hasFormData = false,
  responseType = 'json', 
}: RequestProps) => {
 
  const _defaultHeaders = {
    "Content-Type": hasFormData ? "multipart/form-data" : "application/json",
    Accept: responseType === 'blob' ? '*/*' : 'application/json',
  };

  const defaultHeaders = Object.assign({}, _defaultHeaders, makeAuthHeader());
  const axiosParams = Object.assign({}, params);

  const options: AxiosRequestConfig = {
    method,
    url: `${baseUrl}${path}`,
    headers: defaultHeaders,
    timeout: 30000,
    responseType: responseType,
  };

  if (method.toUpperCase() === "GET") {
    options.params = axiosParams;
  } else {
    options.data = hasFormData ? params : axiosParams;
  }

  const response = await axios(options);
  
  return Promise.resolve(response);
};

