import { Button } from "../../../components/reusable/Button";
import { TextAreaInput, TextInput } from "../../../components/reusable/Form";

export const OneDayEventContent = () => {
  return (
    <>
      <div className="pt-5">
        <h1 className="text-xl font-bold">Create your One Day Event</h1>
        <TextInput name="name" placeholder="Event Name" label="Event Name" />
        <div className="flex items-start gap-3">
          <TextInput
            name="start-date"
            placeholder="Start Date"
            label="Start Date"
          />
          <TextInput name="end-date" placeholder="End Date" label="End Date" />
        </div>
        <TextAreaInput
          name="description"
          rows={4}
          placeholder="Description"
          label="Description"
        />

        <span className="text-lg font-bold block mt-5">Price Information <span className="text-sm text-gray-400">{'(can be changed later)'}</span></span>
        <div className="w-full relative">
          <TextInput
            name="price-level-name"
            placeholder="Price Level Name"
            label="Price Level Name"
          />
          <span className="text-xs text-gray-400 absolute -bottom-2">
            Ex: General Admission, VIP, Adult, Child, etc.
          </span>
        </div>

        <div className="flex items-start gap-3">
          <TextInput
            step={0.01}
            type="number"
            name="price"
            placeholder="Price"
            label="Price"
          />
          <TextInput
            step={1}
            type="number"
            name="number-of-tickets"
            placeholder="Number of Tickets"
            label="Number of Tickets"
          />
        </div>

        <Button size="lg" variant="blue" className="mt-5 w-full">
          Create Event
        </Button>
      </div>
    </>
  );
};
