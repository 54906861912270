import { Fragment, useEffect, useState } from "react";
import { Dialog, DialogPanel, Menu, MenuButton, MenuItem, MenuItems, Transition, TransitionChild } from "@headlessui/react";
import {
  Bars3Icon,
  BuildingOfficeIcon,
  CalendarIcon,
  ChartPieIcon,
  HomeIcon,
  RectangleGroupIcon,
  TicketIcon,
  UsersIcon,
  XMarkIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import { classNames } from "../utils/styles";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { SearchBar } from "../components/SearchBar";
import { Dashboard } from "../pages/admin/Dashboard";
import { Organizations } from "../pages/admin/Organizations";
import { useAuth } from "../providers/AuthContext";
import { Tickets } from "../pages/admin/Tickets";
import { Events } from "../pages/admin/Events";
import { Users } from "../pages/admin/Users";
import { Venues } from "../pages/admin/Venues";
import { Transfers } from "../pages/admin/Transfers";
import HappyNotification from "../components/reusable/Notification";
import { FaHandshake } from "react-icons/fa";
import { PartnerRequests } from "../pages/admin/PartnerRequests";

export default function AdminLayout() {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const desktopSidebarHeight = window.innerHeight - 72;
  const navigation = [
    {
      name: "Dashboard",
      href: "/admin/dashboard",
      icon: HomeIcon,
      current: location.pathname === "/admin/dashboard",
    },
    {
      name: "Partner Requests",
      href: "/admin/partner-requests",
      icon: FaHandshake,
      current: location.pathname.includes("/admin/partner-requests"),
    },
    {
      name: "Reports",
      href: "#",
      icon: ChartPieIcon,
      current: location.pathname === "/reports",
    },
    {
      name: "Organizations",
      href: "/admin/organizations",
      icon: RectangleGroupIcon,
      current: location.pathname.includes("/organizations"),
    },
    {
      name: "Events",
      href: "/admin/events",
      icon: CalendarIcon,
      current: location.pathname.includes("/events"),
    },
    {
      name: "Venues",
      href: "/admin/venues",
      icon: BuildingOfficeIcon,
      current: location.pathname.includes("/venues"),
    },
    {
      name: "Users",
      href: "/admin/users",
      icon: UsersIcon,
      current: location.pathname.includes("/users"),
    },
    {
      name: "Tickets",
      href: "/admin/tickets",
      icon: TicketIcon,
      current: location.pathname.includes("/tickets"),
    },
    {
      name: "Transfers",
      href: "/admin/transfers",
      icon: CurrencyDollarIcon,
      current: location.pathname.includes("/transfers"),
    }
  ];

  useEffect(() => {
    if (!user?.admin) {
      navigate(`/vendor`);
    }
  });
  return (
    <>
      <HappyNotification />

      <div className="flex flex-col bg-gray-900 min-h-screen">
        <Transition show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <TransitionChild
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </TransitionChild>

            <div className="fixed inset-0 flex">
              <TransitionChild
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <TransitionChild
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </TransitionChild>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-darkPurple px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-colored.svg"
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    item.current
                                      ? "text-lightPurple"
                                      : "text-white/30 hover:text-white/50 hover:bg-opacity-30 hover:bg-white",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current
                                        ? "text-lightPurple"
                                        : "text-white/30 group-hover:text-white",
                                      "h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </Dialog>
        </Transition>
        {/* Static sidebar for desktop */}
        <div
          style={{ height: `${desktopSidebarHeight}px` }}
          className="hidden lg:fixed lg:grow lg:inset-y-[4.5rem] lg:z-50 lg:flex lg:w-72 lg:flex-col"
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-950 border-borderAdmin border-r px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-8 w-auto"
                src="//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-colored.svg"
                alt="Your Company"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            item.current
                              ? "text-lightPurple"
                              : "text-white/30 hover:text-white/50 hover:bg-indigo-700",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-lightPurple"
                                : "text-indigo-200 group-hover:text-white/50",
                              "h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                {/* <li className="mt-auto">
                  <Link
                    to="/vendor/dashboard"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-yellow hover:bg-indigo-700 hover:text-white"
                  >
                    <BuildingOfficeIcon
                      className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                      aria-hidden="true"
                    />
                    Vendor View
                  </Link>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>

        <div>
          <div className="sticky border-b border-borderAdmin top-0 z-40 flex h-[4.5rem] shrink-0 items-center gap-x-4  bg-gray-950 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-white lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <div className="lg:w-72 flex items-center">
                <span className="text-pink font-milkshake text-2xl">
                  Happy Ticketing
                  <span className="font-sans text-xs ml-4 px-2 py-1 rounded-lg inline-flex bg-lightPurple/30">
                    <span className="text-lightPurple">Admin</span>
                  </span>
                </span>
              </div>
              <SearchBar />
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                {/* <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                {/* Separator */}
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                  aria-hidden="true"
                />

                {/* Profile dropdown */}
                <Menu as="div">
                  <MenuButton className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>

                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="ml-4 text-sm font-semibold leading-6 text-white/30"
                        aria-hidden="true"
                      >
                        {user?.first_name}
                      </span>
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </MenuButton>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <MenuItems anchor="bottom end" className="z-50 w-32 rounded-md bg-dropdown border-borderAdmin border py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <MenuItem>
                        {() => (
                          <span
                            onClick={logout}
                            role="button"
                            className="px-2 hover:text-gray-400 cursor-pointer"
                          >
                            Log Out
                          </span>
                        )}
                      </MenuItem>
                    </MenuItems>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="py-10 lg:pl-72">
            <div className="px-4 sm:px-6 lg:px-8">
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/organizations/*" element={<Organizations />} />
                <Route path="/events/*" element={<Events />} />
                <Route path="/venues/*" element={<Venues />} />
                <Route path="/tickets/*" element={<Tickets />} />
                <Route path="/users/*" element={<Users />} />
                <Route path="/transfers/*" element={<Transfers />} />
                <Route path="/partner-requests" element={<PartnerRequests />} />
                <Route path="*" element={<Navigate to="dashboard" replace />} />
              </Routes>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
