import { Route, Routes } from "react-router-dom";
import { useAuth } from "../../../providers/AuthContext";
import { useVendor } from "../../../providers/VendorContext";
import { SeasonPassList } from "./SeasonPassList";

export const SeasonPasses = () => {
  const { currentOrganization } = useVendor();
  const {user} = useAuth();

  return (
    <Routes>
      <Route path="/" element={<SeasonPassList />} />
      <Route path=":id" element={<div>Season Pass Details</div>} />
    </Routes>
  )
}