import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../../utils/api";
import { Organization } from "../../../types/Organization";
import { Link } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import DatePicker from "react-datepicker";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { Fragment, ReactNode, useState } from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/20/solid";
import { HappyEvent, PriceLevel, Section, Tier } from "../../../types/Event";
import { OrgUser, User } from "../../../types/User";
import { format } from "date-fns";
import { isEqual, set } from "lodash";
import { Spinner } from "../../../components/reusable/Spinner";
import { useVendor } from "../../../providers/VendorContext";
import HappyNotification from "../../../components/reusable/Notification";
import { Modal } from "../../../components/reusable/Modal";
import { Button } from "../../../components/reusable/Button";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FieldInputProps,
  useFormikContext,
} from "formik";
import { useAuth } from "../../../providers/AuthContext";
import { useNotification } from "../../../providers/NotificationContext";
import { OrgUserModal } from "../../../components/reusable/OrgUserModal";
import { UsersIcon } from "@heroicons/react/24/outline";
import { TextInput } from "../../../components/reusable/Form";
type SearchFormQuery = {
  email: string;
  name: string;
  level: number | null;
  gender: string | null;
};
type GenderDisplayMap = {
  [key: string]: string;
};

type LevelDisplayMap = {
  [key: string]: string;
};
export const EmployeeList = () => {
  // search values
  const { dispatch } = useNotification();
  const { currentOrganization } = useVendor();
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [gender, setGender] = useState<string | null>(null);
  const [level, setLevel] = useState<number | null>(null);

  const [error, setError] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<SearchFormQuery>();

  const columnHelper = createColumnHelper<OrgUser>();
  const { data, refetch, isFetching, isFetched } = useQuery({
    queryKey: ["org_users", searchQuery, currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: "/vendor/org_users",
        params: {
          organization_id: currentOrganization?.id,
          search: searchQuery,
        },
      }),
    enabled: !!currentOrganization,
    retry: false,
  });
  const removeOrgUser = ({ id }: { id: number }) => {
    makeApiRequest({
      path: `/vendor/org_users/${id}`,
      params: { organization_id: currentOrganization?.id },
      method: "DELETE",
    }).then((res) => {
      dispatch({
        type: "open",
        payload: {
          level: "success",
          message: "User removed from org successfully.",
        },
      });
      refetch();
    });
  };

  const updateOrgUser = ({ id, level }: { id: number; level: string }) => {
    makeApiRequest({
      path: `/vendor/org_users/${id}`,
      method: "PUT",
      params: {
        organization_id: currentOrganization?.id,
        org_user: {
          level,
        },
      },
    }).then((res) => {
      dispatch({
        type: "open",
        payload: {
          level: "success",
          message: "User updated successfully.",
        },
      });
      refetch();
    });
  };

  const users: OrgUser[] = data?.data;
  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("user", {
      header: "Name",
      cell: (info) => {
        const user: User | undefined = info.renderValue() as User | undefined;
        return (
          <div className="flex flex-col">
            <span>
              {user?.first_name} {user?.last_name}
            </span>
            <span className="text-xs text-gray-500">{user?.email}</span>
          </div>
        );
      },
    }),
    // columnHelper.accessor("user", {
    //   header: "Email",
    //   cell: (info) => {
    //     const user: User | undefined = info.renderValue() as User | undefined;
    //     return <span>{user?.email}</span>;
    //   },
    // }),
    columnHelper.accessor("level", {
      header: "Level",
      cell: (info) => {
        return (
          <select
            value={info.getValue()}
            onChange={(e) =>
              updateOrgUser({ id: info.row.original.id, level: e.target.value })
            }
            className="w-36 border-l-0 border-t-0 border-r-0 px-3 py-0 rounded-lg border-b border-b-gray-300 text-sm text-gray-500 bg-gray-100 h-8 flex"
          >
            <option value="1">Scanner</option>
            <option value="2">POS / Box Office</option>
            <option value="10">Reader</option>
            <option value="50">Org Admin</option>
          </select>
        );
      },
    }),
    columnHelper.accessor("user", {
      header: "Phone",
      cell: (info) => {
        const user: User | undefined = info.renderValue() as User | undefined;
        return <span>{user?.phone_number}</span>;
      },
    }),
    columnHelper.accessor("user", {
      header: "DOB",
      cell: (info) => {
        const user: User | undefined = info.renderValue() as User | undefined;
        return <span>{user?.dob}</span>;
      },
    }),
    columnHelper.accessor("user", {
      header: "Gender",
      cell: (info) => {
        const user: User | undefined = info.renderValue() as User | undefined;
        return <span>{user?.gender}</span>;
      },
    }),
    columnHelper.accessor("created_at", {
      header: "Added",
      cell: (info) => format(new Date(info.getValue()), "MM/dd/yyyy"),
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        return (
          <div className="flex">
            <Menu as="div" className={"relative inline-block"}>
              <MenuButton className="bg-transparent flex items-center justify-center">
                <Cog6ToothIcon className="h-6 text-gray-500" />
              </MenuButton>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems className="w-40 right-0 absolute bg-gray-100 border-gray-200 border z-10 rounded-md">
            
                  <MenuItem
                    as={"div"}
                    className={
                      "px-3 py-2 hover:bg-white/10 border-gray-200 border-t"
                    }
                  >
                    <span
                      role="button"
                      className="text-pink/60 block"
                      onClick={() =>{
                        if (
                          window.confirm(
                            "Are you sure you want to remove this user from your organization?"
                          )
                        ) {
                          removeOrgUser({
                            id: props.row.original.id,
                          })
                        }
                      }}
                    >
                      Remove from Org
                    </span>
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!userName && !userEmail && !level && !gender) {
      setError("Please enter a something.");
      return;
    }
    setError("");
    const payload: SearchFormQuery = {
      name: userName,
      email: userEmail,
      gender: gender,
      level: level,
    };
    if (isEqual(payload, searchQuery)) {
      refetch();
      return;
    }
    setSearchQuery(payload);
  };

  const handleClearSearch = () => {
    setError("");
    setUserName("");
    setUserEmail("");
    setGender(null);
    setLevel(null);
    setSearchQuery(undefined);
  };

  const genderDisplayMap: GenderDisplayMap = {
    m: "Male",
    f: "Female",
  };

  const levelDisplayMap: LevelDisplayMap = {
    "1": "Scanner",
    "2": "POS / Box Office",
    "10": "Reader",
    "50": "Org Admin",
  };

  const handleSuccessfulAdd = () => {
    setShowAddUserModal(false);
    dispatch({
      type: "open",
      payload: {
        level: "success",
        message: "User added to org successfully.",
      },
    });
    refetch();
  };

  return (
    <>
      <div className="">
        <div className="mx-auto">
          <div>
            <div>
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-semibold leading-6 text-gray-500 flex items-center gap-3">
                    <UsersIcon className="h-7 w-7" />
                    Employees
                  </h1>
                </div>
                <Button
                  onClick={() => setShowAddUserModal(true)}
                  type="button"
                  variant="blue"
                >
                  <PlusIcon className="h-5 mr-1" />
                  Add Employee
                </Button>
              </div>
            </div>
            <div className="mt-4 px-6 py-6 rounded-lg bg-white shadow-sm">
              <form onSubmit={handleSubmit}>
                <div className="flex items-center relative w-full gap-2">
                  {searchQuery ? (
                    <button
                      type="button"
                      className="text-pink hover:brightness-125 absolute left-0 text-xs -bottom-4"
                      onClick={handleClearSearch}
                    >
                      Clear Search
                    </button>
                  ) : null}
                  <div className="w-1/4">
                    <TextInput
                      name="name"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      placeholder="Name"
                      // className="w-44 focus:outline-purple focus:ring-0 text-gray-500 bg-gray-100 h-10 px-3 rounded-lg border-none grow"
                    />
                  </div>
                  <div className="w-1/4">
                    <TextInput
                      name="email"
                      value={userEmail}
                      onChange={(e) => setUserEmail(e.target.value)}
                      placeholder="Email"
                      // className="w-44 focus:outline-purple focus:ring-0 text-gray-500 bg-gray-100 h-10 px-3 rounded-lg border-none grow"
                    />
                  </div>
                  <div className="relative w-44">
                    <Menu
                      as="div"
                      className="relative inline-block text-left w-full"
                    >
                      <MenuButton className="h-10 px-4 w-full rounded bg-white text-gray-500 focus:ring-purple focus:border-purple border border-gray-300 shadow-sm placeholder:text-gray-400 flex items-center">
                        <span>{levelDisplayMap[`${level}`] || "Level"}</span>
                        <ChevronDownIcon className="h-5 ml-1" />
                      </MenuButton>
                      <MenuItems className="w-40 right-0 absolute bg-white border-gray-200 border z-10 rounded-md">
                        <MenuItem>
                          <span
                            role="button"
                            className="cursor-pointer hover:bg-gray-200 block px-3 py-2 text-gray-500"
                            onClick={() => setLevel(1)}
                          >
                            Scanner
                          </span>
                        </MenuItem>
                        <MenuItem>
                          <span
                            role="button"
                            className="cursor-pointer hover:bg-gray-200 block px-3 py-2 text-gray-500"
                            onClick={() => setLevel(2)}
                          >
                            POS / Box Office
                          </span>
                        </MenuItem>
                        <MenuItem>
                          <span
                            role="button"
                            className="cursor-pointer hover:bg-gray-200 block px-3 py-2 text-gray-500"
                            onClick={() => setLevel(10)}
                          >
                            Reader
                          </span>
                        </MenuItem>
                        <MenuItem>
                          <span
                            role="button"
                            className="cursor-pointer hover:bg-gray-200 block px-3 py-2 text-gray-500"
                            onClick={() => setLevel(50)}
                          >
                            Org Admin
                          </span>
                        </MenuItem>
                        {/* <MenuItem>Admin</MenuItem>
                        <MenuItem>Somethin Else</MenuItem> */}
                      </MenuItems>
                    </Menu>
                  </div>
                  <div className="relative w-32">
                    <Menu
                      as="div"
                      className="relative inline-block text-left w-full"
                    >
                      <MenuButton className="h-10 px-4 w-full rounded bg-white text-gray-500 focus:ring-purple focus:border-purple border border-gray-300 shadow-sm placeholder:text-gray-400 flex items-center">
                        <span>
                          {gender ? genderDisplayMap[`${gender}`] : "Gender"}
                        </span>
                        <ChevronDownIcon className="h-5 ml-1" />
                      </MenuButton>
                      <MenuItems className="w-40 right-0 absolute bg-white border-gray-200 border z-10 rounded-md">
                        <MenuItem>
                          <span
                            role="button"
                            className="px-3 py-2 text-gray-500 block cursor-pointer hover:bg-gray-200"
                            onClick={() => setGender("m")}
                          >
                            Male
                          </span>
                        </MenuItem>
                        <MenuItem>
                          <span
                            role="button"
                            className="block cursor-pointer hover:bg-gray-200 px-3 py-2 text-gray-500"
                            onClick={() => setGender("f")}
                          >
                            Female
                          </span>
                        </MenuItem>
                        {/* <MenuItem>Admin</MenuItem>
                        <MenuItem>Somethin Else</MenuItem> */}
                      </MenuItems>
                    </Menu>
                  </div>
                  <div className="relative over">
                    <button
                      type="submit"
                      className="h-10 bg-purple rounded-lg px-10 text-white"
                    >
                      {isFetching ? <Spinner /> : `Search`}
                    </button>
                  </div>
                  <span className="text-yellow absolute block text-center text-xs -bottom-6 m-auto">
                    {error}
                  </span>
                </div>
              </form>
            </div>
            <div className="mt-8 flow-root bg-white shadow-sm rounded-lg p-5">
              <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 overflow-visible">
                <div className="overflow-visible inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  {users?.length ? (
                    <table className="min-w-full divide-y divide-gray-200 text-sm">
                      <thead>
                        {table.getHeaderGroups().map((headerGroup, i) => (
                          <tr
                            className="text-gray-500"
                            key={`${headerGroup.id}-${i}`}
                          >
                            {headerGroup.headers.map((header, i) => (
                              <th
                                className="text-left"
                                key={`${header.id}-${i}`}
                              >
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {table?.getRowModel()?.rows?.map((row, i) => {
                          return (
                            <tr className="h-11" key={`${row.id}-${i}`}>
                              {row.getVisibleCells().map((cell, i) => {
                                return (
                                  <td
                                    className="py-1 text-gray-500"
                                    key={`${cell.id}-${i}`}
                                  >
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div className="flex justify-center items-center h-20">
                      <span className="text-gray-500">
                        No employees to display yet
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrgUserModal
        isOpen={showAddUserModal}
        onRequestClose={() => setShowAddUserModal(false)}
        handleSuccessfulAdd={handleSuccessfulAdd}
      />
    </>
  );
};
