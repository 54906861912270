import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../../utils/api";
import { Route, Routes } from "react-router-dom";
import { ShowOrder } from "./ShowOrder";
import { OrderList } from "./OrderList";

export const Orders = () => {
  return (
    <Routes>
      <Route path='/' element={<OrderList />} />
      <Route path='/:id' element={<ShowOrder />} />
    </Routes>
  )
}