import { Navigate, BrowserRouter as Router } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Auth/Login";
import { useAuth } from "./providers/AuthContext";
import AdminLayout from "./layouts/AdminLayout";
import VendorLayout from "./layouts/VendorLayout";
import { VendorProvider } from "./providers/VendorContext";
import { useNotification } from "./providers/NotificationContext";
import { useEffect } from "react";
export default function HappyAdmin() {
  const { user, logout } = useAuth();
  const { showError } = useNotification();

  let redirectUrl = "/login"

  if (user && user.admin) {
    redirectUrl = '/admin/dashboard'
  }

  if (user && user.org_users.length > 0) {
    redirectUrl = `/vendor/organizations/${user.org_users[0].organization_id}`
  }

  useEffect(() => {
    if (user && user.org_users.length === 0 && !user.admin) {
      logout();
      showError("You are not a part of any organizations.");
    }
  }
  , [user, logout, showError]);


  if (user) {
    return (
      <Router>
        <Routes>
          <Route path="/admin/*" element={<AdminLayout />} />
          <Route
            path="/vendor/organizations/:org_id/*"
            element={
              <VendorProvider>
                <VendorLayout />
              </VendorProvider>
            }
          />
          <Route path="*" element={<Navigate to={redirectUrl} replace />} />
        </Routes>
      </Router>
    );
  }
  return (
    <Router>
      <Routes>
        <Route element={<Login />} path="/login" />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </Router>
  );
}
