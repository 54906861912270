import Select, { MultiValue } from "react-select";
import makeAnimated from "react-select/animated";
import { Field, FieldInputProps, Form, Formik, useFormikContext } from "formik";
import { makeApiRequest } from "../../../utils/api";
import {
  SelectInput,
  TextAreaInput,
  TextInput,
} from "../../../components/reusable/Form";
import { Button } from "../../../components/reusable/Button";
import { useVendor } from "../../../providers/VendorContext";
import { useNotification } from "../../../providers/NotificationContext";
import { ProductCategory } from "../../../types/Product";
import {
  productCategoryIconOptions,
  renderIcon,
} from "../../../utils/products";
import { IoFastFoodOutline } from "react-icons/io5";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
const animatedComponents = makeAnimated();

export const ProductCategoryForm = ({
  onSuccessfulCreate,
  editingProductCategory,
}: {
  onSuccessfulCreate?: () => void;
  editingProductCategory: ProductCategory | null;
}) => {
  const { currentOrganization } = useVendor();
  const { showError } = useNotification();
  const initialValues = editingProductCategory
    ? {
        name: editingProductCategory.name,
        icon: editingProductCategory.icon,
      }
    : {
        name: "",
        icon: "",
      };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(data) => {
        const payload = {
          ...data,
          organization_id: currentOrganization?.id,
        };

        const apiPath = editingProductCategory
          ? `/vendor/product_categories/${editingProductCategory.id}`
          : "/vendor/product_categories";
        const apiMethod = editingProductCategory ? "PUT" : "POST";
        makeApiRequest({
          path: apiPath,
          method: apiMethod,
          params: {
            organization_id: currentOrganization?.id,
            product_category: payload,
          },
        })
          .then((res) => {
            if (res.status === 201 || res.status === 200) {
              onSuccessfulCreate && onSuccessfulCreate();
            }
          })
          .catch((err) => {
            const msg =
              err.response?.data?.message ||
              "Something went wrong. Please try again later.";
            showError(msg);
          });
        // LEFT OFF HERE< SUBMIT PAYLOAD TO BACKEND
      }}
    >
      <Form>
        <Field name="name">
          {({ field }: { field: FieldInputProps<never> }) => (
            <TextInput
              label="Category Name"
              {...field}
              placeholder="Category Name"
            />
          )}
        </Field>
        <IconSelect />

        <Button
          size="lg"
          type="submit"
          variant="success"
          className="mt-6 w-full"
        >
          Save
        </Button>
      </Form>
    </Formik>
  );
};

export const IconSelect = () => {
  const { setFieldValue } = useFormikContext();
  return (
    <>
      <label htmlFor="icon" className="block text-gray-500 py-2">
        Select Category Icon
      </label>
      <Field name="icon">
        {({ field }: { field: FieldInputProps<never> }) => (
          <Menu>
            <MenuButton
              className={
                "h-10 rounded border border-gray-300 shadow-sm px-3 w-52 flex items-center justify-between"
              }
            >
              <span className="text-2xl">
                {field.value ? renderIcon(field.value) : ""}
              </span>

              <ChevronDownIcon className="h-4 w-4" />
            </MenuButton>
            <MenuItems
              className="shadow-md border border-gray-300 rounded bg-white w-52 max-h-64 overflow-auto"
              anchor="bottom"
            >
              {productCategoryIconOptions.map((icon) => (
                <MenuItem key={icon.value}>
                  <span
                    role="button"
                    onClick={() => setFieldValue("icon", icon.value)}
                    className="text-2xl flex justify-center p-3 border-b border-gray-300 cursor-pointer hover:bg-gray-100"
                  >
                    {icon.display}
                  </span>
                </MenuItem>
              ))}
            </MenuItems>
          </Menu>
        )}
      </Field>
    </>
  );
};
