import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../../utils/api";
import { Link } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { Fragment, ReactNode, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { format } from "date-fns";
import { Organization } from "../../../types/Organization";
import { Modal } from "../../../components/reusable/Modal";
import { CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { Button } from "../../../components/reusable/Button";
import { useNotification } from "../../../providers/NotificationContext";
import { formatCentsToDollars } from "../../../utils/currency";
import { TextAreaInput, TextInput } from "../../../components/reusable/Form";
import { AccountTransfer } from "../../../types/AccountTransfer";
import { sum } from "lodash";

export const Transfers = () => {
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [orgId, setOrgId] = useState<string>("");
  const [transferId, setTransferId] = useState<string>("");

  const [error, setError] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<{
    id: string;
    orgId: string;
  }>();

  const columnHelper = createColumnHelper<AccountTransfer>();

  const { data: orgData } = useQuery({
    queryKey: ["organizations"],
    queryFn: () => makeApiRequest({ path: "/admin/organizations" }),
    retry: false,
    enabled: true,
  });

  const organizations = orgData?.data;

  const { data, refetch } = useQuery({
    queryKey: ["account_transfers", searchQuery],
    queryFn: () =>
      makeApiRequest({
        path: "/admin/account_transfers",
        params: searchQuery
          ? {
              search: {
                organization_id: orgId,
                id: transferId,
              },
            }
          : undefined,
      }),
    enabled: true,
    retry: false,
  });

  const transfers = data?.data;
  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => (
        <Link className="text-lightPurple hover:brightness-125" to="">
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor("organization", {
      header: "Organization",
      cell: (info) => {
        const organization = info.getValue();
        return (
          <Link className="text-lightPurple hover:brightness-125" to="">
            {organization?.name}
          </Link>
        );
      },
    }),

    columnHelper.accessor("amount_cents", {
      header: "Transfer Amount",
      cell: (info) => {
        const amount = info.getValue() as number;
        return formatCentsToDollars(amount);
      },
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        return (
          <div className="flex">
            <Menu as="div" className={"relative inline-block"}>
              <Menu.Button className="bg-transparent flex items-center justify-center">
                <Cog6ToothIcon className="h-6 text-white/30" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="w-40 right-0 absolute bg-dropdown border-borderAdmin border z-10 rounded-md">
                  <Menu.Item
                    as={"div"}
                    className={"px-3 py-2 hover:bg-white/10"}
                  >
                    <Link
                      className="text-white/60 block"
                      to={`/admin/organizations/${props.row.original.id}`}
                    >
                      Edit
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    as={"div"}
                    className={
                      "px-3 py-2 hover:bg-white/10 border-borderAdmin border-t"
                    }
                  >
                    <Link
                      className="text-pink/60 block"
                      to={`/admin/organizations/${props.row.original.id}`}
                    >
                      Delete
                    </Link>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: transfers,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleSubmit = () => {
    setError("");
    setSearchQuery({ id: transferId, orgId: orgId });
  };

  return (
    <>
      <div className="">
        <div className="mx-auto">
          <div className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-white/60">
                    Transfers
                  </h1>
                </div>
                <button
                  onClick={() => setShowCreateModal(true)}
                  type="button"
                  className="h-10 -translate-y-full sm:translate-y-0 flex items-center rounded-md bg-pink px-3 py-2 text-center text-sm font-semibold text-white hover:bg-purple"
                >
                  <PlusIcon className="h-5 mr-1" />
                  Create Transfer
                </button>
              </div>
              <div className="mt-4 px-6 py-8 rounded-lg border-borderAdmin border flex justify-center items-center gap-2">
                <div className="flex items-center relative gap-2">
                  <input
                    value={transferId}
                    onChange={(e) => setTransferId(e.target.value)}
                    placeholder="Transfer ID"
                    className=" focus:outline-purple focus:ring-0 text-white/60 bg-input/10 h-10 px-3 rounded-lg border-none"
                  />
                  <select
                    onChange={(e) => setOrgId(e.target.value)}
                    className=" focus:outline-purple focus:ring-0 text-white/60 bg-input/10 h-10 px-3 rounded-lg border-none"
                  >
                    <option value="">Select Organization</option>
                    {organizations?.map((org: Organization) => (
                      <option key={org.id} value={org.id}>
                        {org.name}
                      </option>
                    ))}
                  </select>
                  <button
                    className="h-10 bg-purple rounded-lg px-10 text-white"
                    onClick={handleSubmit}
                  >
                    Search
                  </button>
                  <span className="text-yellow absolute left-0 text-xs -bottom-6">
                    {error}
                  </span>
                </div>
              </div>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    {transfers && transfers.length ? (
                      <table className="min-w-full divide-y divide-borderAdmin">
                        <thead>
                          {table.getHeaderGroups().map((headerGroup) => (
                            <tr className="text-white/50" key={headerGroup.id}>
                              {headerGroup.headers.map((header) => (
                                <th className="text-left" key={header.id}>
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody className="divide-y divide-borderAdmin">
                          {table?.getRowModel()?.rows?.map((row) => {
                            return (
                              <tr className="h-11" key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                  return (
                                    <td
                                      className="py-1 text-white/30"
                                      key={cell.id}
                                    >
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <div className="flex justify-center items-center h-96">
                        <p className="text-white/30">No transfers found.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateTransferModal
        refetchTransfers={refetch}
        organizations={organizations}
        isOpen={showCreateModal}
        onRequestClose={() => setShowCreateModal(false)}
      />
    </>
  );
};

export const CreateTransferModal = ({
  organizations,
  onRequestClose,
  isOpen,
  refetchTransfers,
}: {
  organizations: Organization[];
  onRequestClose: () => void;
  isOpen: boolean;
  refetchTransfers: () => void;
}) => {
  const [orgId, setOrgId] = useState<string>("");
  const [transferAmount, setTransferAmount] = useState<number | undefined>();
  const { showSuccess, showError } = useNotification();

  const { data: balanceData } = useQuery({
    queryKey: ["happy_balance"],
    queryFn: () => makeApiRequest({ path: "/admin/happy_balance" }),
    retry: false,
    enabled: true,
  });

  const balance: Balance = balanceData?.data;
  const totalAvailableBalance = sum(balance?.available.map((b) => b.amount));

  const createTransfer = () => {
    if (!orgId || !transferAmount) {
      showError("Please fill out all fields.");
      return;
    }
    const payload = {
      organization_id: orgId,
      amount_cents: transferAmount * 100,
    };

    makeApiRequest({
      method: "POST",
      path: "/admin/account_transfers",
      params: { account_transfer: payload },
    })
      .then((res) => {
        if (res.status === 201) {
          showSuccess("Transfer created successfully.");
          setOrgId("");
          setTransferAmount;
        }
        onRequestClose();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        refetchTransfers();
      });
  };

  return (
    <Modal onRequestClose={onRequestClose} isOpen={isOpen}>
      <>
        <div className="flex justify-between items-center pt-7">
          <h3 className="font-bold text-lg">Create Transfer</h3>
          {totalAvailableBalance && (
            <span className="font-semibold">
              Available:{" "}
              <span className="text-green-500">
                {formatCentsToDollars(totalAvailableBalance)}
              </span>
            </span>
          )}
        </div>
        <div className="flex flex-col items-center gap-4 mt-5">
          <select
            className="px-3 h-10 w-full rounded border border-gray-300 text-gray-500 focus:ring-purple shadow-sm placeholder:text-gray-400 text-left"
            value={orgId}
            onChange={(e) => setOrgId(e.target.value)}
          >
            <option>Select Organization</option>
            {organizations?.map((org) => (
              <option key={org.id} value={org.id}>
                {org.name}
              </option>
            ))}
          </select>
          <div className="w-full relative">
            <CurrencyDollarIcon className="h-6 text-black/30 absolute left-3 top-5" />
            <TextInput
              name="transfer-amount"
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                if (!isNaN(value)) {
                  // Format the number to have up to 2 decimal places
                  setTransferAmount(Math.round(value * 100) / 100);
                } else {
                  setTransferAmount(undefined);
                }
              }}
              type="number"
              step={0.01}
              value={transferAmount}
              placeholder="Transfer Amount"
              className="pl-10 w-full"
            />
          </div>
          {/* <TextAreaInput
            name="transfer-notes"
            value={transferNotes}
            onChange={(e) => setTransferNotes(e.target.value)}
            placeholder="Notes"
            className="w-full"
          />
          <TextInput
            name="transfer-date"
            value={transferDate}
            onChange={(e) => setTransferDate(e.target.value)}
            type="date"
            placeholder="Transfer Date"
            className="w-full"
          />
          <select
            value={transferMethod}
            onChange={(e) => setTransferMethod(e.target.value)}
            className="px-3 h-10 w-full rounded border border-gray-300 text-gray-500 focus:ring-purple shadow-sm placeholder:text-gray-400 text-left"
          >
            <option>Select Transfer Method</option>
            <option value="check">Check</option>
            <option value="cash">Cash</option>
            <option value="stripe">Stripe</option>
            <option value="venmo">Venmo</option>
          </select> */}

          <Button onClick={createTransfer}>Create Transfer</Button>
        </div>
      </>
    </Modal>
  );
};
