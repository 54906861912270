import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import { FieldInputProps } from "formik/dist/types";
import { set } from "lodash";
import DatePicker from "react-datepicker";
import { Spinner } from "../../../components/reusable/Spinner";

export type EventFormProps = {
  name: string;
  description: string;
  start_date: Date;
  end_date: Date;
  max_tickets_per_person: number;
  min_time_between_scans: number;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zip: string;
  country: "US";
  organization_id: number;
};

export const EventForm = ({handleSubmit}: {handleSubmit: (formData: EventFormProps) => void}) => {
  return (
    <>
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          handleSubmit(values)
        }}
        initialValues={
          {
            name: "",
            description: "",
            start_date: new Date(),
            end_date: new Date(),
            max_tickets_per_person: 0,
            min_time_between_scans: 0,
            address_1: "",
            address_2: "",
            city: "",
            state: "",
            zip: "",
            country: "US",
            organization_id: 579 // square 1
          } as EventFormProps
        }
      >
        <FormContent />
      </Formik>
    </>
  );
};

export const FormContent = () => {
  const formik = useFormikContext<EventFormProps>();
  console.log(formik);

  return (
    <>
      <div className="flex flex-col gap-4 lg:max-w-xl">
        <div className="flex flex-col gap-2">
          <label className="font-bold text-white/60">Event Name</label>
          <Field name="name">
            {({ field }: { field: FieldInputProps<never> }) => {
              return (
                <input
                  {...field}
                  autoComplete="off"
                  type="text"
                  placeholder="Event Name"
                  className="rounded h-10 text-white/60 placeholder:text-white/30 bg-input/10 border-none"
                />
              );
            }}
          </Field>
        </div>
        <div className="flex flex-col gap-2">
          <label className="font-bold text-white/60">Description</label>
          <Field name="description">
            {({ field }: { field: FieldInputProps<never> }) => {
              return (
                <textarea
                  {...field}
                  autoComplete="off"
                  rows={3}
                  placeholder="Description"
                  className="rounded text-white/60 placeholder:text-white/30 bg-input/10 border-none"
                />
              );
            }}
          </Field>
        </div>
        <div className="flex gap-4">
          <div className="flex flex-col gap-2 w-1/2">
            <label className="font-bold text-white/60">Start Date</label>
            <Field name="start_date">
              {({ field }: { field: FieldInputProps<never> }) => (
                <DatePicker
                  placeholderText="Start Date"
                  selected={formik.values.start_date}
                  onChange={(date: Date | null) =>
                    formik.setFieldValue("start_date", date as Date)
                  }
                  className="focus:ring-0 focus:outline-purple w-full cursor-pointer text-white/60 bg-input/10 h-10 px-3 rounded-lg border-none"
                />
              )}
            </Field>
          </div>
          <div className="flex flex-col gap-2 w-1/2">
            <label className="font-bold text-white/60">End Date</label>
            <Field name="end_date">
              {({ field }: { field: FieldInputProps<never> }) => (
                <DatePicker
                  placeholderText="End Date"
                  selected={formik.values.end_date}
                  onChange={(date: Date | null) =>
                    formik.setFieldValue("end_date", date as Date)
                  }
                  className="focus:ring-0 focus:outline-purple w-full cursor-pointer text-white/60 bg-input/10 h-10 px-3 rounded-lg border-none"
                />
              )}
            </Field>
          </div>
        </div>

        <div className="flex gap-4">
          <div className="flex flex-col gap-2 w-1/2">
            <label className="font-bold text-white/60">
              Max Number of Tickets Allowed Per Person
            </label>
            <Field name="max_tickets_per_person">
              {({ field }: { field: FieldInputProps<never> }) => (
                <input
                  {...field}
                  autoComplete="off"
                  type="number"
                  placeholder="# of Tickets"
                  className="rounded h-10 text-white/60 placeholder:text-white/30 bg-input/10 border-none"
                />
              )}
            </Field>
          </div>
          <div className="flex flex-col gap-2 w-1/2">
            <label className="font-bold text-white/60">
              Minimum Time Between Ticket Scans {`(In Seconds)`}
            </label>
            <Field name="min_time_between_scans">
              {({ field }: { field: FieldInputProps<never> }) => (
                <input
                  {...field}
                  autoComplete="off"
                  type="number"
                  placeholder="Event Name"
                  className="rounded h-10 text-white/60 placeholder:text-white/30 bg-input/10 border-none"
                />
              )}
            </Field>
          </div>
        </div>

        <span className="text-white/60 block py-2 mt-4 font-bold text-lg">
          Address Info
        </span>

        <div className="flex flex-col gap-2">
          <label className="font-bold text-white/60">Address Line 1</label>
          <Field name="address_1">
            {({ field }: { field: FieldInputProps<never> }) => {
              return (
                <input
                  {...field}
                  autoComplete="off"
                  type="text"
                  placeholder="Address Line 1"
                  className="rounded h-10 text-white/60 placeholder:text-white/30 bg-input/10 border-none"
                />
              );
            }}
          </Field>
        </div>

        <div className="flex flex-col gap-2">
          <label className="font-bold text-white/60">
            Address Line 2 <span className="text-sm">{`(optional)`}</span>
          </label>
          <Field name="address_2">
            {({ field }: { field: FieldInputProps<never> }) => {
              return (
                <input
                  {...field}
                  autoComplete="off"
                  type="text"
                  placeholder="Address Line 2"
                  className="rounded h-10 text-white/60 placeholder:text-white/30 bg-input/10 border-none"
                />
              );
            }}
          </Field>
        </div>

        <div className="flex gap-4">
          <div className="flex flex-col gap-2 w-1/3">
            <label className="font-bold text-white/60">City</label>
            <Field name="city">
              {({ field }: { field: FieldInputProps<never> }) => (
                <input
                  {...field}
                  autoComplete="off"
                  type="text"
                  placeholder="City"
                  className="rounded h-10 text-white/60 placeholder:text-white/30 bg-input/10 border-none"
                />
              )}
            </Field>
          </div>
          <div className="flex flex-col gap-2 w-1/4">
            <label className="font-bold text-white/60">State</label>
            <Field name="state">
              {({ field }: { field: FieldInputProps<never> }) => (
                <input
                  {...field}
                  autoComplete="off"
                  type="text"
                  placeholder="State"
                  className="rounded h-10 text-white/60 placeholder:text-white/30 bg-input/10 border-none"
                />
              )}
            </Field>
          </div>

          <div className="flex flex-col gap-2 w-1/4">
            <label className="font-bold text-white/60">Zip</label>
            <Field name="zip">
              {({ field }: { field: FieldInputProps<never> }) => (
                <input
                  {...field}
                  autoComplete="off"
                  type="text"
                  placeholder="Zip"
                  className="rounded h-10 text-white/60 placeholder:text-white/30 bg-input/10 border-none"
                />
              )}
            </Field>
          </div>
        </div>
        <div className="py-4">
          <button onClick={() => formik.handleSubmit()} type="submit" className="bg-purple px-10 h-10 text-white font-bold rounded hover:brightness-125">
            {formik.isSubmitting ? <Spinner /> : `Save Event`} 
          </button>
        </div>
      </div>

    </>
  );
};
