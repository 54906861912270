export const timezones = [
  "Pacific Time (US & Canada)",
  "Mountain Time (US & Canada)",
  "Central Time (US & Canada)",
  "Eastern Time (US & Canada)",
  "America/Adak",
  "Alaska",
  "Hawaii",
  "America/Anchorage",
  "America/Metlakatla",
  "America/Nome",
  "America/Sitka",
  "America/Yakutat",
  "America/Boise",
  "Arizona",
  "America/Indiana/Knox",
  "America/Indiana/Tell_City",
  "America/Menominee",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Detroit",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "Indiana (East)",
];

type TimezoneOption = {
  value: string;
  display: string;
};

export const timezoneOptions: TimezoneOption[] = timezones.map((tz) => ({
  value: tz,
  display: tz,
}));
