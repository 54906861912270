import { Fragment, useState } from "react";
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import {
  Bars3Icon,
  BuildingOfficeIcon,
  CalendarIcon,
  ChartPieIcon,
  HomeIcon,
  TicketIcon,
  XMarkIcon,
  CurrencyDollarIcon,
  ListBulletIcon,
  UsersIcon,
  UserGroupIcon,
  MegaphoneIcon,
  BanknotesIcon,
  BuildingStorefrontIcon,
  IdentificationIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { classNames } from "../utils/styles";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Dashboard } from "../pages/vendor/Dashboard";

import { useAuth } from "../providers/AuthContext";
import { UserIcon } from "@heroicons/react/24/solid";
import { Events } from "../pages/vendor/Events";
import { Venues } from "../pages/vendor/Venues";

import { Tickets } from "../pages/vendor/Tickets";
import { Orders } from "../pages/vendor/Orders";
import { Employees } from "../pages/vendor/Employees";
import HappyNotification from "../components/reusable/Notification";
import { Payments } from "../pages/vendor/Payments";
import { useVendor } from "../providers/VendorContext";
import { Customers } from "../pages/vendor/Customers";
import { VendorReports } from "../pages/vendor/Reports";
import { PromoterCodes } from "../pages/vendor/PromoterCodes";
import { PayoutList } from "../pages/vendor/Payouts";
import { Products } from "../pages/vendor/Products";
import { SeasonPasses } from "../pages/vendor/SeasonPasses";
import { Button } from "../components/reusable/Button";

export default function VendorLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { currentOrganization } = useVendor();
  const { user, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const desktopSidebarHeight = window.innerHeight - 72;
  const navigation = [
    {
      name: "Dashboard",
      href: `/vendor/organizations/${currentOrganization?.id}/dashboard`,
      icon: HomeIcon,
      current:
        location.pathname ===
        `/vendor/organizations/${currentOrganization?.id}/dashboard`,
    },
    {
      name: "Events",
      href: `/vendor/organizations/${currentOrganization?.id}/events`,
      icon: CalendarIcon,
      current: location.pathname.includes("/events"),
    },
    {
      name: "Venues",
      href: `/vendor/organizations/${currentOrganization?.id}/venues`,
      icon: BuildingOfficeIcon,
      current: location.pathname.includes("/venues"),
    },
    {
      name: "Customers",
      href: `/vendor/organizations/${currentOrganization?.id}/customers`,
      icon: UserGroupIcon,
      current: location.pathname.includes("/customers"),
    },
    {
      name: "Employees",
      href: `/vendor/organizations/${currentOrganization?.id}/employees`,
      icon: UsersIcon,
      current: location.pathname.includes("/employees"),
    },
    {
      name: "Tickets",
      href: `/vendor/organizations/${currentOrganization?.id}/tickets`,
      icon: TicketIcon,
      current: location.pathname.includes(
        `/vendor/organizations/${currentOrganization?.id}/tickets`
      ),
    },
    {
      name: "Payments",
      href: `/vendor/organizations/${currentOrganization?.id}/payments`,
      icon: CurrencyDollarIcon,
      current: location.pathname.includes("/payments"),
    },
    {
      name: "Banking & Payouts",
      href: `/vendor/organizations/${currentOrganization?.id}/payouts`,
      icon: BanknotesIcon,
      current: location.pathname.includes("/payouts"),
    },
    {
      name: "Reports",
      href: `/vendor/organizations/${currentOrganization?.id}/reports`,
      icon: ChartPieIcon,
      current: location.pathname.includes("/reports"),
    },
    {
      name: "Orders",
      href: `/vendor/organizations/${currentOrganization?.id}/orders`,
      icon: ListBulletIcon,
      current: location.pathname.includes(
        `/vendor/organizations/${currentOrganization?.id}/orders`
      ),
    },
    {
      name: "Promoter Codes",
      href: `/vendor/organizations/${currentOrganization?.id}/promoter-codes`,
      icon: MegaphoneIcon,
      current: location.pathname.includes(
        `/vendor/organizations/${currentOrganization?.id}/promoter-codes`
      ),
    },
    {
      name: "Products",
      href: `/vendor/organizations/${currentOrganization?.id}/products`,
      icon: BuildingStorefrontIcon,
      current: location.pathname.includes(
        `/vendor/organizations/${currentOrganization?.id}/products`
      ),
    },
    {
      name: "Season Passes",
      href: `/vendor/organizations/${currentOrganization?.id}/season-passes`,
      icon: IdentificationIcon,
      current: location.pathname.includes(
        `/vendor/organizations/${currentOrganization?.id}/season-passes`
      ),
    },
  ];

  const userNavigation = [
    { name: "Admin", href: "/admin/dashboard", hidden: !user?.admin },
  ];
  return (
    <>
      <HappyNotification />
      <div className="flex flex-col bg-gray-100 min-h-screen">
        <Transition show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <TransitionChild
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </TransitionChild>

            <div className="fixed inset-0 flex">
              <TransitionChild
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <TransitionChild
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </TransitionChild>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-200 px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-colored.svg"
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <Link
                                  onClick={() => setSidebarOpen(false)}
                                  to={item.href}
                                  className={classNames(
                                    item.current
                                      ? "text-purple"
                                      : "text-gray-500 hover:text-purple/50 hover:bg-opacity-30 hover:bg-gray-200",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current
                                        ? "text-purple"
                                        : "text-gray-500 group-hover:text-purple",
                                      "h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </Dialog>
        </Transition>
        {/* Static sidebar for desktop */}
        <div
          style={{ height: `${desktopSidebarHeight}px` }}
          className="hidden lg:fixed lg:grow lg:inset-y-[4.5rem] lg:z-50 lg:flex lg:w-72 lg:flex-col"
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-100 px-6 pb-4">
            <div className="flex gap-4 h-16 shrink-0 items-center ml-2">
              <span className="font-sans text-xs ml-4 px-2 py-1 rounded-lg inline-flex bg-lightPurple/30">
                <span className="text-lightPurple">
                  {currentOrganization?.name}
                </span>
              </span>
              {/* <OrgSelection /> */}
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          onClick={() => setSidebarOpen(false)}
                          to={item.href}
                          className={classNames(
                            item.current
                              ? "text-purple"
                              : "text-gray-500 hover:text-purple/70 hover:bg-gray-200",
                            "group flex gap-x-3 rounded-md p-1 text-sm items-center"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-purple"
                                : "text-gray-500 group-hover:text-purple/50",
                              "h-5 w-5 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                {/* <li className="mt-auto">
                  <a
                    href="#"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white"
                  >
                    <Cog6ToothIcon
                      className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                      aria-hidden="true"
                    />
                    Settings
                  </a>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>

        <div>
          <div
            className="sticky top-0 z-40 flex h-[4.5rem] shrink-0 items-center gap-x-4  bg-gray-100 px-4 
          sm:gap-x-6 sm:px-6 lg:px-8"
          >
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-500 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-900/10 lg:hidden"
              aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <div className="hidden gap-4 lg:min-w-72 lg:flex items-center">
                <img
                  className="h-10 w-auto"
                  src="//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-colored.svg"
                  alt="Your Company"
                />
                <span className="text-purple font-milkshake text-3xl">
                  Happy Ticketing
                </span>
              </div>
              <div className="relative flex items-center flex-1"></div>
              {/* <SearchBar /> */}
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                {/* <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                {/* Separator */}
                {/* <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                  aria-hidden="true"
                /> */}
                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <MenuButton className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <UserIcon className="w-4 text-gray-500" />
                    {/* <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    /> */}
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="ml-4 text-sm font-semibold leading-6"
                        aria-hidden="true"
                      >
                        {user?.first_name}
                      </span>
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </MenuButton>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <MenuItems className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-gray-50 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <MenuItem key={item.name}>
                          {({ focus }) => (
                            <Link
                              to={item.href}
                              className={classNames(
                                focus ? "bg-gray-100" : "",
                                "block px-3 py-1 text-sm leading-6 text-gray-500",
                                item.hidden ? "hidden" : ""
                              )}
                            >
                              {item.name}
                            </Link>
                          )}
                        </MenuItem>
                      ))}

                      <MenuItem key="profile">
                        {({ focus }) => (
                          <Link
                            target="_blank"
                            to={`${
                              import.meta.env.VITE_CONSUMER_WEB_URL
                            }/account`}
                            className={classNames(
                              focus ? "bg-gray-100" : "",
                              "block px-3 py-1 text-sm leading-6 text-gray-500"
                            )}
                          >
                            Profile
                          </Link>
                        )}
                      </MenuItem>

                      <MenuItem>
                        {({ focus }) => (
                          <span
                            role="button"
                            onClick={logout}
                            className={classNames(
                              focus ? "bg-gray-100" : "",
                              "block px-3 py-1 text-sm leading-6 text-gray-500 cursor-pointer"
                            )}
                          >
                            Log Out
                          </span>
                        )}
                      </MenuItem>
                    </MenuItems>
                  </Transition>
                </Menu>
                <Button
                  size="sm"
                  variant="success"
                  onClick={() =>
                    navigate(
                      `/vendor/organizations/${currentOrganization?.id}/events/builder`
                    )
                  }
                >
                  Create Event
                </Button>
              </div>
            </div>
          </div>
          <main className="py-3 lg:pl-72">
            <div className="px-4 sm:px-6 lg:px-8 lg:pb-72">
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/events/*" element={<Events />} />
                {/* <Route path="/organizations/*" element={null} /> */}
                <Route path="/venues/*" element={<Venues />} />
                <Route path="/payments/*" element={<Payments />} />
                <Route path="/payouts/*" element={<PayoutList />} />
                <Route path="/employees/*" element={<Employees />} />
                <Route path="/customers/*" element={<Customers />} />
                <Route path="/tickets/*" element={<Tickets />} />
                <Route path="/orders/*" element={<Orders />} />
                <Route path="/reports/*" element={<VendorReports />} />
                <Route path="/promoter-codes/*" element={<PromoterCodes />} />
                <Route path="/products/*" element={<Products />} />
                <Route path="/season-passes/*" element={<SeasonPasses />} />
                <Route path="*" element={<Navigate to="dashboard" replace />} />
              </Routes>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
