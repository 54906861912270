import { Route, Routes } from "react-router-dom";
import { TicketList } from "./TicketList";
import { ShowTicket } from "./ShowTicket";

export const Tickets = () => {
  return (
    <Routes>
      <Route path="/" element={<TicketList />} />
      <Route path="/:id" element={<ShowTicket />} />
    </Routes>
  );
};
