import { Link, useNavigate } from "react-router-dom"
import { EventForm, EventFormProps } from "./Form"
import { ChevronLeftIcon } from "@heroicons/react/20/solid"
import { makeApiRequest } from "../../../utils/api"
import { create } from "lodash"

export const NewEvent = () => {
  const navigate = useNavigate()
  const createNewEvent = (formData: EventFormProps) => {
     makeApiRequest({
      path: "/admin/events",
      method: "POST",
      params: {event: formData}
     }).then((res) => {
        if (res.status === 201) {
          console.log(res)
          navigate(`/admin/events/${res.data.id}`)
        }
     })
     .catch((err) => {
       console.log(err)
     })
  }
  return (
    <div>
      <Link to="/admin/events" className="flex items-center gap-1 mb-6 text-purple hover:brightness-125">
        Back to Events
      </Link>
      <h1 className="text-white/60 block mb-6 font-bold text-lg">New Event</h1>
      <EventForm handleSubmit={createNewEvent} />
    </div>
  )
}