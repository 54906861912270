import { Option } from "../components/reusable/Form";
import { IoFastFoodOutline } from "react-icons/io5";
import { UserIcon } from "@heroicons/react/24/outline";
import { LuCandy } from "react-icons/lu";
import { RiDrinksLine } from "react-icons/ri";
import { IoShirtOutline } from "react-icons/io5";
import { MdOutlineSmartToy } from "react-icons/md";
import { FaFireAlt } from "react-icons/fa";
import { FaTags } from "react-icons/fa";
import { GiTicket } from "react-icons/gi";
import { FaCar } from "react-icons/fa";


export const productCategoryIconOptions: Option[] = [
  {
    value: "food-and-drinks",
    display: <IoFastFoodOutline />,
  },
  {
    value: "candy",
    display: <LuCandy />,
  },
  {
    value: "drinks",
    display: <RiDrinksLine />,
  },
  {
    value: "clothing",
    display: <IoShirtOutline />,
  },
  {
    value: "toys",
    display: <MdOutlineSmartToy />,
  },
  {
    value: "fire",
    display: <FaFireAlt />,
  },
  {
    value: "tags",
    display: <FaTags />,
  },
  {
    value: "tickets",
    display: <GiTicket />,
  },
  {
    value: "car",
    display: <FaCar />,
  },
  
];

 export const renderIcon = (icon: string) => {
  switch (icon) {
    case "food-and-drinks":
      return <IoFastFoodOutline />;
    case "candy":
      return <LuCandy />;
    case "drinks":
      return <RiDrinksLine />;
    case "clothing":
      return <IoShirtOutline />;
    case "toys":
      return <MdOutlineSmartToy />;
    case "fire":
      return <FaFireAlt />;
    case "tags":
      return <FaTags />;
    case "tickets":
      return <GiTicket />;
    case "car":
      return <FaCar />;
    default:
      return null;
  }
 }
