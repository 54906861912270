import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../../utils/api";
import { Organization } from "../../../types/Organization";
import { Link } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import DatePicker from "react-datepicker";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
  Table,
} from "@tanstack/react-table";
import { FormEvent, Fragment, ReactNode, useState } from "react";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { HappyEvent, PriceLevel, Section, Tier } from "../../../types/Event";
import { User } from "../../../types/User";
import { format } from "date-fns";
import { isEqual } from "lodash";
import { VendorTable } from "../../../components/reusable/VendorTable";
import { Resources } from "../../../types/Resources";
type SearchFormQuery = {
  id: string;
  event: string;
  from: Date | null;
  to: Date | null;
}
export const EventList = () => {
  // search values
  const [eventName, setEventName] = useState<string>("");
  const [eventId, setEventId] = useState<string>("");
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [dateFrom, setDateFrom] = useState<Date | null>(null);

  const [error, setError] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<SearchFormQuery>();

  const columnHelper = createColumnHelper<HappyEvent>();
  const { data, refetch } = useQuery({
    queryKey: ["events", searchQuery],
    queryFn: () =>
      makeApiRequest({
        path: "/admin/events",
        params: { search: { event: eventName, id: eventId, to: dateTo, from: dateFrom } },
      }),
    enabled: true,
    retry: false,
  });

  const events: HappyEvent[] = data?.data;
  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("name", {
      header: "Name",
      cell: (info) => {
        return (
          <Link className="text-lightPurple hover:brightness-125" to="">
            {info.getValue()}
          </Link>
        );
      },
    }),
    columnHelper.accessor("section_count", {
      header: "Sections",
      cell: (info) => {
        return (
          <Link className="text-lightPurple hover:brightness-125" to="">
            {info.getValue()}
          </Link>
        );
      },
    }),
    columnHelper.accessor("ticket_count", {
      header: "Tickets",
      cell: (info) => {
        return (
          <Link className="text-lightPurple hover:brightness-125" to="">
            {info.getValue()}
          </Link>
        );
      },
    }),
    columnHelper.accessor("order_count", {
      header: "Orders",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("start_date", {
      header: "Start",
      cell: (info) =>
        format(new Date(info.getValue() as string), "MM/dd/yyyy @ h:mm a"),
    }),
    columnHelper.accessor("end_date", {
      header: "End",
      cell: (info) =>
        format(new Date(info.getValue() as string), "MM/dd/yyyy @ h:mm a"),
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        return (
          <div className="flex">
            <Menu as="div" className={"relative inline-block"}>
              <MenuButton className="bg-transparent flex items-center justify-center">
                <Cog6ToothIcon className="h-6 text-white/30" />
              </MenuButton>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems className="w-40 right-0 absolute bg-dropdown border-borderAdmin border z-10 rounded-md">
                  <MenuItem
                    as={"div"}
                    className={"px-3 py-2 hover:bg-white/10"}
                  >
                    <Link
                      className="text-white/60 block"
                      to={`/admin/organizations/${props.row.original.id}`}
                    >
                      Edit
                    </Link>
                  </MenuItem>
                  <MenuItem
                    as={"div"}
                    className={"px-3 py-2 hover:bg-white/10"}
                  >
                    <Link
                      className="text-white/60 block"
                      to={`/admin/organizations/${props.row.original.id}`}
                    >
                      View Orders
                    </Link>
                  </MenuItem>
                  <MenuItem
                    as={"div"}
                    className={"px-3 py-2 hover:bg-white/10"}
                  >
                    <Link
                      className="text-white/60 block"
                      to={`/admin/organizations/${props.row.original.id}`}
                    >
                      View Reports
                    </Link>
                  </MenuItem>
                  <MenuItem
                    as={"div"}
                    className={"px-3 py-2 hover:bg-white/10"}
                  >
                    <Link
                      className="text-white/60 block"
                      to={`/admin/organizations/${props.row.original.id}`}
                    >
                      View events
                    </Link>
                  </MenuItem>
                  <MenuItem
                    as={"div"}
                    className={
                      "px-3 py-2 hover:bg-white/10 border-borderAdmin border-t"
                    }
                  >
                    <Link
                      className="text-pink/60 block"
                      to={`/admin/organizations/${props.row.original.id}`}
                    >
                      Delete
                    </Link>
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: events,
    columns,
    getCoreRowModel: getCoreRowModel(),
  }) as Table<Resources>;

  const handleSubmit = ((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!eventId && !eventName && !dateFrom && !dateTo) {
      setError("Please enter a ticket ID or event name or date from or date to.");
      return;
    }
    setError("");
    const payload: SearchFormQuery = {id: eventId, event: eventName, to: dateTo, from: dateFrom }
    if (isEqual(payload, searchQuery)) {
      refetch()
      return
    }
    setSearchQuery(payload)
  })

  const handleClearSearch = () => {
    setError("");
    setEventId("");
    setEventName("");
    setDateFrom(null);
    setDateTo(null);
    setSearchQuery(undefined);
  }

  return (
    <>
      <div>
        <div className="mx-auto">
          <div className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-white/60">
                    Events
                  </h1>
                </div>
                <Link
                  to="/admin/events/new"
                  className="h-10 -translate-y-full sm:translate-y-0 flex items-center rounded-md bg-pink px-3 py-2 text-center text-sm font-semibold text-white hover:bg-purple"
                >
                  <PlusIcon className="h-5 mr-1" />
                  Add Event
                </Link>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="mt-4 px-6 py-8 rounded-lg border-borderAdmin border flex justify-between items-center">
                  <div className="flex items-center relative justify-between">
                    <input
                      value={eventId}
                      onChange={(e) => setEventId(e.target.value)}
                      placeholder="Event ID"
                      className="w-1/4 focus:outline-purple focus:ring-0 text-white/60 bg-input/10 h-10 px-3 rounded-lg border-none"
                    />
                    {eventId || eventName || dateFrom || dateTo ? (
                      <button className="text-pink hover:brightness-125 absolute left-0 text-xs -bottom-6" onClick={handleClearSearch}>Clear Search</button>
                    ) : null}
                    <input
                      value={eventName}
                      onChange={(e) => setEventName(e.target.value)}
                      placeholder="Event Name"
                      className="w-1/4 focus:outline-purple focus:ring-0 text-white/60 ml-3 bg-input/10 h-10 px-3 rounded-lg border-none"
                    />
                    <div className="relative">
                    <label className="text-xs absolute -top-5 text-white/60 ml-3">From</label>
                      <DatePicker
                        wrapperClassName="w-full"
                        selected={dateFrom}
                        onChange={(date) => setDateFrom(date as Date)}
                        className="focus:ring-0 focus:outline-purple w-32 cursor-pointer text-white/60 ml-3 bg-input/10 h-10 px-3 rounded-lg border-none"
                      />
                    </div>
                    <div className="relative">
                      <label className="text-xs absolute -top-5 text-white/60 ml-3">To</label>
                      <DatePicker
                        wrapperClassName="w-full"
                        selected={dateTo}
                        onChange={(date) => setDateTo(date as Date)}
                        className="focus:ring-0 focus:outline-purple w-32 cursor-pointer text-white/60 ml-3 bg-input/10 h-10 px-3 rounded-lg border-none"
                      />
                    </div>
                    <button
                      type="submit"
                      className="h-10 bg-purple rounded-lg ml-3 px-10 text-white"
                    >
                      Search
                    </button>
                    <span className="text-yellow absolute left-0 text-xs -bottom-6">
                      {error}
                    </span>
                  </div>
                  
                </div>
              </form>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    {events ? (
                      <VendorTable table={table} admin={true} />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
