import { ChevronRightIcon } from "@heroicons/react/20/solid";
import {
  AcademicCapIcon,
  CalendarIcon,
  UserCircleIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "../../../utils/styles";
import { TicketIcon } from "@heroicons/react/24/outline";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { useVendor } from "../../../providers/VendorContext";
import { Modal } from "../../../components/reusable/Modal";
import { useState } from "react";
import { OneDayEventContent } from "./OneDayEventContent";
import { set } from "lodash";
import { MembershipContent } from "./MembershipContent";
import { ClassPassContent } from "./ClassPassContent";
import { MultiDayEventContent } from "./MultiDayEventContent";
import { Button } from "../../../components/reusable/Button";

type templateContentOptions =
  | "one-day-event"
  | "multi-day-event"
  | "class-pass"
  | "membership"
  | null;

export const EventTemplateSelect = ({
  isOpen,
  onRequestClose,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
}) => {
  const { currentOrganization } = useVendor();
  const navigate = useNavigate();
  const [templateContent, setTemplateContent] =
    useState<templateContentOptions>(null);
  const items = [
    {
      name: "General Admission Event with One Price",
      description: "A single day event with 1 price for all attendees.",
      examples: [
        "Music Events",
        "Sporting Events",
        "Meetups",
        "Theater Shows",
      ],
      onClick: () => setTemplateContent("one-day-event"),
      iconColor: "bg-pink",
      classNames: "hover:bg-pink/10 group relative flex items-start space-x-3 p-4",
      icon: TicketIcon,
    },
    {
      name: "Multi-Day Event",
      description: "An event that spans multiple days.",
      examples: [
        "Festivals",
        "Conferences",
        "Conventions",
        "Trade Shows",
        "Expos",
      ],
      onClick: () => setTemplateContent("multi-day-event"),
      iconColor: "bg-purple",
      classNames: "hover:bg-purple/10 group relative flex items-start space-x-3 p-4",
      icon: CalendarIcon,
    },
    {
      name: "Class Pass",
      description:
        "Host a reoccuring event on specific days of the week, for a specific period of time.",
      examples: ["Woodworking Class", "Yoga Class", "Workshops", "Meetups"],
      onClick: () => setTemplateContent("class-pass"),
      iconColor: "bg-green-400",
      classNames: "hover:bg-green-400/10 group relative flex items-start space-x-3 p-4",
      icon: AcademicCapIcon,
    },
    {
      name: "Membership Pass",
      description:
        "An event structured to give customers a specified number of entries. Think of a 10-pack of yoga classes or a short term fitness membership.",
      onClick: () => setTemplateContent("membership"),
      examples: [
        "Gym Memberships",
        "Museum Passes",
        "Transportation or Parking Passes",
      ],
      iconColor: "bg-blue",
      classNames: "hover:bg-blue/10 group relative flex items-start space-x-3 p-4",
      icon: UserCircleIcon,
    },
    {
      name: "Advanced - Create Custom Event",
      description:
        "Use the Happy Event Builder to create your Event from scratch, including all tiers, sections, and price levels.",
      onClick: () => navigate(
        `/vendor/organizations/${currentOrganization?.id}/events/builder?template=day-pass-event`
      ),
      examples: [],
      iconColor: "bg-gray-400",
      classNames: "hover:bg-gray-400/10 group relative flex items-start space-x-3 p-4",
      icon: WrenchScrewdriverIcon,
    },
  ];
  return (
    <Modal onRequestClose={onRequestClose} isOpen={isOpen}>
      {/* <p className="mb-4 text-xl">
        Choose one of the following templates to get a head start on creating
        your event. Each template will give you a basic setup for the type of
        event you're creating, you can always customize it later.
      </p> */}
      {templateContent ? (
        <>
          <Button variant="link" onClick={() => setTemplateContent(null)}>
            Back
          </Button>
          <EventTemplateContent content={templateContent} />
        </>
      ) : (
        <>
          <h2 className="text-base font-semibold leading-6 text-gray-900">
            Create your first experience
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            Get started by selecting a template or start from scratch with the{" "}
            <Link
              className="text-purple"
              to={`/vendor/organizations/${currentOrganization?.id}/events/builder`}
            >
              event builder.
            </Link>{" "}
            <b>
              Once your event is created, you can always customize it to fit
              your needs.
            </b>
          </p>
          <ul
            role="list"
            className="mt-6 divide-y divide-gray-200 border-b border-t border-gray-200"
          >
            {items.map((item, itemIdx) => (
              <li key={itemIdx}>
                <div role='button' onClick={item.onClick} className={item.classNames}>
                  <div className="flex-shrink-0">
                    <span
                      className={classNames(
                        item.iconColor,
                        "inline-flex h-10 w-10 items-center justify-center rounded-lg"
                      )}
                    >
                      <item.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1">
                    <div className="text-sm font-medium text-gray-900">
                      <span className="absolute inset-0" aria-hidden="true" />
                      {item.name}
                    </div>
                    <p className="text-sm text-gray-500">{item.description}</p>
                    {item.examples.length > 0 && (
                      <span className="text-xs font-medium text-gray-400 italic">
                        Examples: {item.examples.join(", ")}
                      </span>
                    )}
                  </div>
                  <div className="flex-shrink-0 self-center">
                    <ChevronRightIcon
                      className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
    </Modal>
  );
};

export const EventTemplateContent = ({ content }: { content: string }) => {
  switch (content) {
    case "one-day-event":
      return <OneDayEventContent />;
    case "two-day-event":
      return <MultiDayEventContent />;
    case "class-pass":
      return <ClassPassContent />;
    case "membership":
      return <MembershipContent />;
    default:
      return <div>Invalid Template</div>;
  }
};
