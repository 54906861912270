import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { makeApiRequest } from "../../../utils/api";
import { Organization } from "../../../types/Organization";
import { Button } from "../../../components/reusable/Button";
import { EyeIcon, PencilIcon } from "@heroicons/react/20/solid";
export const OrgDetail = () => {
  const { id } = useParams();
  const { data, isLoading } = useQuery({
    queryKey: ["organizations", id],
    queryFn: async () => {
      const response = await makeApiRequest({
        path: `/admin/organizations/${id}`,
      });
      return response;
    },
    enabled: !!id,
  });

  const organization: Organization = data?.data;
  console.log(organization);
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!organization) {
    return null;
  }
  return (
    <div>
      <div className="flex justify-between">
        <div>
          <h1 className="text-lightPurple font-bold text-2xl sm:text-4xl">
            {organization?.name}
          </h1>
          <div className="flex gap-4 mt-2">
            <div>
              <span className="block text-lg">
                {organization?.address_1} {organization?.address_2}
              </span>
              <span className="block text-lg">
                {organization?.city}, {organization?.state} {organization?.zip}
              </span>
              <span className="block text-lg">
                {organization?.contact_phone}
              </span>
              <span className="block text-lg">
                {organization?.contact_email}
              </span>
            </div>
            <div></div>
          </div>
        </div>
        <div className="flex gap-4">
          <Button size="sm" variant="dark">
            Edit Details <PencilIcon className="w-3 h-3" />
          </Button>
          <Link to={`/vendor/organizations/${id}`}>
            <Button size="sm" variant="purple">
              Vendor View <EyeIcon className="w-3 h-3" />
            </Button>
          </Link>
        </div>
      </div>
      <nav className="flex overflow-x-auto border-b border-white/10 py-4 w-full mt-5">
        <ul
          role="list"
          className="flex min-w-full flex-none gap-x-6 px-4 text-sm/6 font-semibold text-gray-400 sm:px-6 lg:px-8"
        >
          <li>
            <Link className="text-gray-200" to="">
              Events
            </Link>
          </li>

          <li>
            <Link className="text-gray-200" to="">
              Orders
            </Link>
          </li>

          <li>
            <Link className="text-gray-200" to="">
              Venues
            </Link>
          </li>

          <li>
            <Link className="text-gray-200" to="">
              Tickets
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};
