import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../../utils/api";
import { Organization } from "../../../types/Organization";
import { Link } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { Fragment, ReactNode, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

export const Venues = () => {
  const [venueName, setVenueName] = useState<string>("");
  const [venueId, setVenueId] = useState<string>("");
  const [selectedOrg, setSelectedOrg] = useState<Organization>();
  const [error, setError] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<{
    id: string;
    name: string;
    organization: number | undefined;
  }>();

  const columnHelper = createColumnHelper<Record<string, ReactNode>>();
  const { data: orgData } = useQuery({
    queryKey: ["organizations"],
    queryFn: () =>
      makeApiRequest({
        path: "/admin/organizations",
      }),
    retry: false,
    enabled: true,
  });
  const orgs = orgData?.data;

  const { data } = useQuery({
    queryKey: ["venues", searchQuery],
    queryFn: () =>
      makeApiRequest({
        path: "/admin/venues",
        params: { search: searchQuery },
      }),
    enabled:
      searchQuery?.name || searchQuery?.id || searchQuery?.organization
        ? true
        : false,
    retry: false,
  });

  const venues = data?.data;
  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("name", {
      header: "Name",
      cell: (info) => (
        <Link className="text-lightPurple hover:brightness-125" to="">
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor("description", {
      header: "Description",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("full_address", {
      header: "Address",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("org_count", {
      header: "Orgs",
      cell: (info) => (
        <Link className="text-lightPurple hover:brightness-125" to="">
          {info.getValue()}
        </Link>
      ),
    }),
    // columnHelper.accessor("event", {
    //   header: "Event",
    //   cell: (info) => {
    //     const event: HappyEvent | undefined = info.renderValue() as
    //       | HappyEvent
    //       | undefined;
    //     return (
    //       <Link className="text-lightPurple hover:brightness-125" to="">
    //         {event?.name}
    //       </Link>
    //     );
    //   },
    // }),

    // columnHelper.accessor("tier", {
    //   header: "Tier",
    //   cell: (info) => {
    //     const section: Tier | undefined = info.renderValue() as
    //       | Tier
    //       | undefined;
    //     return (
    //       <Link className="text-lightPurple hover:brightness-125" to="">
    //         {section?.name}
    //       </Link>
    //     );
    //   },
    // }),
    // columnHelper.accessor("section", {
    //   header: "Section",
    //   cell: (info) => {
    //     const section: Section | undefined = info.renderValue() as
    //       | Section
    //       | undefined;
    //     return (
    //       <Link className="text-lightPurple hover:brightness-125" to="">
    //         {section?.name}
    //       </Link>
    //     );
    //   },
    // }),
    // columnHelper.accessor("price_level", {
    //   header: "Price",
    //   cell: (info) => {
    //     const priceLevel: PriceLevel | undefined = info.renderValue() as
    //       | PriceLevel
    //       | undefined;
    //     if (priceLevel?.price_cents) {
    //       return `$${(priceLevel?.price_cents / 100).toFixed(2)}`;
    //     }

    //     return `Price not set.`;
    //   },
    // }),
    // columnHelper.accessor("sold_at", {
    //   header: "Sold At",
    //   cell: (info) =>
    //     format(new Date(info.getValue() as string), "MM/dd/yyyy @ h:mm a"),
    // }),
    // columnHelper.accessor("user", {
    //   header: "Sold To",
    //   cell: (info) => {
    //     const user: User | undefined = info.renderValue() as User | undefined;
    //     if (user) {
    //       return `${user.first_name} ${user.last_name}`;
    //     }
    //     return `N/A`;
    //   },
    // }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        return (
          <div className="flex">
            <Menu as="div" className={"relative inline-block"}>
              <Menu.Button className="bg-transparent flex items-center justify-center">
                <Cog6ToothIcon className="h-6 text-white/30" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="w-40 right-0 absolute bg-dropdown border-borderAdmin border z-10 rounded-md">
                  <Menu.Item
                    as={"div"}
                    className={"px-3 py-2 hover:bg-white/10"}
                  >
                    <Link
                      className="text-white/60 block"
                      to={`/admin/organizations/${props.row.original.id}`}
                    >
                      Edit
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    as={"div"}
                    className={
                      "px-3 py-2 hover:bg-white/10 border-borderAdmin border-t"
                    }
                  >
                    <Link
                      className="text-pink/60 block"
                      to={`/admin/organizations/${props.row.original.id}`}
                    >
                      Delete
                    </Link>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: venues,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleSubmit = () => {
    if (!venueId && !venueName && !selectedOrg) {
      setError("Please enter a venue ID or name.");
      return;
    }
    setError("");
    setSearchQuery({
      id: venueId,
      name: venueName,
      organization: selectedOrg?.id,
    });
  };

  return (
    <>
      <div className="">
        <div className="mx-auto">
          <div className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-white/60">
                    Venues
                  </h1>
                </div>
                <button
                  type="button"
                  className="h-10 -translate-y-full sm:translate-y-0 flex items-center rounded-md bg-pink px-3 py-2 text-center text-sm font-semibold text-white hover:bg-purple"
                >
                  <PlusIcon className="h-5 mr-1" />
                  Create a Venue
                </button>
              </div>
              <div className="mt-4 px-6 py-8 rounded-lg border-borderAdmin border flex justify-center items-center">
                <div className="flex items-center relative gap-2 justify-center">
                  <input
                    value={venueId}
                    onChange={(e) => setVenueId(e.target.value)}
                    placeholder="Venue ID"
                    className="w-24 focus:outline-purple focus:ring-0 text-white/60 bg-input/10 h-10 px-3 rounded-lg border-none"
                  />
                  <input
                    value={venueName}
                    onChange={(e) => setVenueName(e.target.value)}
                    placeholder="Venue Name"
                    className="focus:outline-purple focus:ring-0 text-white/60 bg-input/10 h-10 px-3 rounded-lg border-none"
                  />
                  <div className="sm:w-10 lg:w-40">
                    <Menu
                      as="div"
                      className="relative inline-block text-left w-full"
                    >
                      <Menu.Button className="flex justify-between items-center w-full focus:outline-purple focus:ring-0 text-white/60 bg-input/10 h-10 px-3 rounded-lg border-none overflow-hidden box-size">
                        <span>
                          {selectedOrg ? selectedOrg.name : "Organization"}
                        </span>
                        <ChevronDownIcon className="h-5 ml-1" />
                      </Menu.Button>
                      <Menu.Items className="w-40 right-0 absolute bg-dropdown border-borderAdmin border z-10 rounded-md">
                        {orgs?.map((org: Organization) => (
                          <Menu.Item>
                            <button
                              className="block px-3 py-2 text-white/60 text-left hover:bg-white/10"
                              onClick={() => setSelectedOrg(org)}
                            >
                              {org.name}
                            </button>
                          </Menu.Item>
                        ))}
                        {/* <Menu.Item>Admin</Menu.Item>
                        <Menu.Item>Somethin Else</Menu.Item> */}
                      </Menu.Items>
                    </Menu>
                  </div>
                  <button
                    className="h-10 bg-purple rounded-lg px-10 text-white"
                    onClick={handleSubmit}
                  >
                    Search
                  </button>
                  <span className="text-yellow absolute left-0 text-xs -bottom-6">
                    {error}
                  </span>
                </div>
              </div>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    {venues ? (
                      <table className="min-w-full divide-y divide-borderAdmin">
                        <thead>
                          {table.getHeaderGroups().map((headerGroup) => (
                            <tr className="text-white/50" key={headerGroup.id}>
                              {headerGroup.headers.map((header) => (
                                <th className="text-left" key={header.id}>
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody className="divide-y divide-borderAdmin">
                          {table?.getRowModel()?.rows?.map((row) => {
                            return (
                              <tr className="h-11" key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                  return (
                                    <td
                                      className="py-1 text-white/30"
                                      key={cell.id}
                                    >
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
