import { Route, Routes } from "react-router-dom"
import { PaymentList } from "./PaymentList"
import { ShowPayment } from "./ShowPayment"

export const Payments = () => {
  return (
    <Routes>
      <Route path="/" element={<PaymentList  />} />
      <Route path="/:id" element={<ShowPayment />} />
    </Routes>
  )
}