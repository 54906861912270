import React, { ProviderProps, useEffect } from "react";
import { Organization } from "../types/Organization";
import { makeApiRequest } from "../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import { useNotification } from "./NotificationContext";
import { useAuth } from "./AuthContext";
import { set } from "lodash";

interface VendorContextType {
  currentOrganization: Organization | null;
  setCurrentOrganization: React.Dispatch<
    React.SetStateAction<Organization | null>
  >;
  refetchOrganization: () => void;
}

const VendorContext = React.createContext<VendorContextType>({
  currentOrganization: null,
  refetchOrganization: () => null,
  setCurrentOrganization: () => null,
});

function VendorProvider({ children }: { children: React.ReactNode }) {
  const params = useParams<{org_id: string}>()
  const {user, logout} = useAuth()
  const navigate = useNavigate()
  const {showError} = useNotification()
  const [currentOrganization, setCurrentOrganization] =
    React.useState<Organization | null>(null);

  const refetchOrganization = React.useCallback(() => {
    if (!params.org_id || params.org_id === 'undefined') {
      return null;
    }
    console.log('refetching organization', params)
    makeApiRequest({
      path: `/vendor/organizations/${params.org_id}`,
    }).then((res) => {
      setCurrentOrganization(res.data);
    })
    .catch((err) => {
      if (err.response.status === 401) {
        showError('You are not a part of this organization. ')
        if (user && user.org_users.length > 0) {
          navigate(`/vendor/organizations/${user.org_users[0].organization_id}`)
          return
        }
        logout()
        showError('You are not a part of any organizations.')
      }
    })
  }, [params.org_id, setCurrentOrganization]);

  useEffect(() => {
    refetchOrganization()
  }, [params.org_id, refetchOrganization])

  useEffect(() => {
    if (user?.org_users.length === 0 && !user?.admin) {
      logout()
      showError('You are not a part of any organizations.')
    }
  })

  return (
    <VendorContext.Provider
      value={{
        currentOrganization,
        setCurrentOrganization,
        refetchOrganization,
      }}
      children={children}
    />
  );
}

const useVendor = () => React.useContext(VendorContext);

export { VendorProvider, useVendor };
