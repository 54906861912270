import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useVendor } from "../../../providers/VendorContext";
import { makeApiRequest } from "../../../utils/api";
import { EventTotal } from "../../../types/Report";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { Button } from "../../../components/reusable/Button";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { saveAs } from "file-saver";
import { formatCentsToReadableString } from "../../../utils/currency";

export const SalesByEvent = () => {
  const { currentOrganization } = useVendor();
  const [searchParams, setSearchParams] = useState({
    fromDate: "",
    toDate: "",
  });
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["sales-by-event", currentOrganization, searchParams],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/organizations/${currentOrganization?.id}/sales_by_event`,
        params:
          searchParams.fromDate || searchParams.toDate
            ? {
                search: {
                  from_date: searchParams.fromDate,
                  to_date: searchParams.toDate,
                },
              }
            : {},
      }),
    enabled: !!currentOrganization,
  });

  const handleSearch = () => {
    setSearchParams({ fromDate, toDate });
  };

  const clearSearch = () => {
    setSearchParams({ fromDate: "", toDate: "" });
    setFromDate("");
    setToDate("");
  };

  useEffect(() => {
  }, [searchParams, refetch]);



  const codeData: EventTotal[] = data?.data;

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "bottom" as const,
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value: unknown) {
            return "$" + value;
          },
        },
      },
    },
  };

  const labels = codeData?.map((item: EventTotal) => item.event_name);

  const chartData = {
    labels,
    datasets: [
      {
        fill: {
          target: "origin",
          above: "rgba(76, 201, 240, 0.45)", // And blue below the origin
        },
        label: "Revenue",
        data: codeData
          ? codeData?.map((item: EventTotal) => item.total_revenue_cents / 100)
          : [],
        backgroundColor: "#486BFF",
      },
    ],
  };

  const downloadSalesByEventReport = () => {
    makeApiRequest({
      path: `/vendor/organizations/${currentOrganization?.id}/sales_by_event.csv`,
      params: {
        organization_id: currentOrganization?.id,
        search: {
          from_date: searchParams.fromDate,
          to_date: searchParams.toDate
        },
      },
    }).then((res) => {
      if (res.status === 200) {
        const CSV = res.data;
        const blob = new Blob([CSV], { type: "text/csv" });
        const filename = `sales-by-event-${currentOrganization?.name}.csv`;
        saveAs(blob, filename);
      }
    });
  }


  return (
    <div className="mt-3">
      <VendorCard className="mt-3 pb-10 mb-5">
        <div className="flex items-end relative">
          {(fromDate || toDate) && (
            <span
              className="absolute text-sm text-pink top-full mt-2 z-10"
              onClick={clearSearch}
              role="button"
            >
              Clear Search
            </span>
          )}
          <div className="flex flex-col">
            <label htmlFor="fromDate" className="mr-2">
              From
            </label>
            <input
              type="date"
              id="fromDate"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              className="mr-4 rounded border-gray-400 h-10"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="toDate" className="mr-2">
              To
            </label>
            <input
              type="date"
              id="toDate"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              className="mr-4 rounded border-gray-400 h-10"
            />
          </div>

          <Button onClick={handleSearch} variant="purple">
            Search
          </Button>
        </div>
      </VendorCard>


      <>
        <VendorCard className="mt-3">
          <h2 className="text-lg font-bold pb-5">Sales by Event</h2>

          <Bar data={chartData} options={chartOptions} />
        </VendorCard>
        <VendorCard className="mt-3">
          <div className="flex">
            <Button onClick={downloadSalesByEventReport} size="sm" className="mb-2 ml-auto" >
              <ArrowDownTrayIcon className="w-5 h-5" />
              Download CSV
            </Button>
          </div>
          <EventRevenueTable eventTotals={codeData} />
        </VendorCard>
      </>
    </div>
  );
};


export const EventRevenueTable = ({
  eventTotals,
}: {
  eventTotals: EventTotal[];
}) => {
  const { currentOrganization } = useVendor();
  return (
    <table className="min-w-full divide-y divide-gray-200 text-sm">
      <thead>
        <tr className="text-left">
          <th>Event</th>
          <th>Revenue</th>
        </tr>
      </thead>

      <tbody className="divide-y divide-gray-200">
        {eventTotals?.map((item: EventTotal) => (
          <tr className="h-11 text-gray-400" key={item.event_name}>
            <td className="py-1">
              <Link
                className="text-lightPurple"
                to={`/vendor/organizations/${currentOrganization?.id}/events/${item.event_id}`}
              >
                {item.event_name}
              </Link>
            </td>
            <td className="py-1">
              {formatCentsToReadableString(item.total_revenue_cents)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};