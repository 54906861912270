import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles for the editor

const RichTextEditor = ({ placeholder = "Write something amazing...", onChange }: {
  placeholder?: string,
  onChange?: (content: string) => void
}) => {
  const [editorContent, setEditorContent] = useState('');

  // Quill modules for customizing the toolbar
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'], // Remove formatting button
    ],
  };

  // Quill formats to specify what features are supported
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'link',
  ];

  const handleContentChange = (content: string) => {
    console.log(content);
    setEditorContent(content);
    if (onChange) {
      onChange(content);
    }
  };
  return (
      <ReactQuill
        value={editorContent}
        onChange={handleContentChange}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        style={{ height: '200px' }}
      />
  );
};

export default RichTextEditor;
