import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useVendor } from "../../../../providers/VendorContext";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../../../utils/api";
import { VendorCard } from "../../../../components/reusable/VendorCard";
import { Disclosure, Transition } from "@headlessui/react";
import { classNames } from "../../../../utils/styles";
import { format } from "date-fns";

ChartJS.register(ArcElement, Tooltip, Legend);

type HourlyScan = {
  scan_type: string;
  event_id: number;
  id: number;
  created_at: string;
  scanner_name: string;
  ticket_id: number;
};

type Scan = {
  hours: string;
  total_scans: number;
  hourly_scans: HourlyScan[];
};
export const ScanReport = () => {
  const { currentOrganization } = useVendor();
  const params = useParams();
  const { data } = useQuery({
    queryKey: ["scan_report", params, currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: `/vendor/events/${params.id}/scan_report`,
        params: { organization_id: currentOrganization?.id },
      });
    },
    enabled: !!params.id && !!currentOrganization,
  });

  const scanReportData = data?.data;
  const stats = [
    {
      id: 1,
      name: "Tickets Scanned",
      value: scanReportData?.scan_in_count,
    },
    {
      id: 2,
      name: "Tickets Sold",
      value: scanReportData?.sold_ticket_count,
    },
    {
      id: 3,
      name: "Scan Percentage",
      value: `${scanReportData?.scan_percentage}%`,
    },
  ];
  const scans: Scan[] = scanReportData?.scans;
  return (
    <VendorCard className="w-full mt-5">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 py-3 sm:py-6">
        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center md:grid-cols-3">
          {stats.map((stat) => (
            <div
              key={stat.id}
              className="mx-auto flex max-w-xs flex-col gap-y-4"
            >
              <dt className="text-base leading-7 text-gray-500">{stat.name}</dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-lightPurple sm:text-5xl">
                {stat.value}
              </dd>
            </div>
          ))}
        </dl>
      </div>
      <div className="flex flex-col gap-5 mt-5">
        {scans?.map((scan: Scan) => {
          return (
            <div>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={classNames(
                        "border border-gray-200 w-full p-4",
                        open ? "rounded-tr-lg rounded-tl-lg" : "rounded-lg"
                      )}
                    >
                      <div className="flex justify-between">
                        <span className="text-lg font-bold">{scan.hours}</span>
                        <span className="flex items-center justify-center bg-blue/30 text-blue rounded-xl px-4 py-1">
                          {scan.total_scans} scans
                        </span>
                      </div>
                    </Disclosure.Button>
                    <Transition
                      as={React.Fragment}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel
                        className={`p-4 bg-gray-50 border border-gray-200 rounded-bl-lg rounded-br-lg`}
                      >
                        <table className="min-w-full divide-y divide-gray-200 mt-5">
                          <thead>
                            <tr>
                              <th>Scan Type</th>
                              <th>Scan Time</th>
                              <th>Scanner Name</th>
                              <th>Ticket ID</th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                            {scan.hourly_scans.map((hourlyScan: HourlyScan) => {
                              return (
                                <tr
                                  className="h-11 text-sm"
                                  key={hourlyScan.id}
                                >
                                  <td className="text-center capitalize">
                                    {hourlyScan.scan_type}
                                  </td>
                                  <td className="text-center">
                                    {format(
                                      new Date(hourlyScan.created_at || ""),
                                      "MM/dd/yyyy @ h:mm:ss a"
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {hourlyScan.scanner_name}
                                  </td>
                                  <td className="text-center">
                                    <Link
                                      className="text-lightPurple"
                                      to={`/vendor/organizations/${currentOrganization?.id}/tickets/${hourlyScan.ticket_id}`}
                                    >
                                      {hourlyScan.ticket_id}
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            </div>
          );
        })}
      </div>
    </VendorCard>
  );
};
